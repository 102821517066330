import React from 'react';

import './fonts.css';
import './stats-legend.css';

class Legend extends React.Component {

  constructor(props) {
    super(props);

    this.closeLegend = this.closeLegend.bind(this);
  }

  closeLegend(event) {
    // Need to do this otherwise clicking anywhere on the child popup will trigger this
    if (event.target === event.currentTarget) {
        // document.body.style.overflow = "visible";
        this.props.legendCloseHandler();
    }
  }

  render() {
    if (!this.props.open) {
        return (<></>)
    }
    return(
        <span className="legend-modal" onClick={this.closeLegend}>
          <div className="stats-legend-container">
            <div className="stats-legend-header">
              <p className="vertically-centered font size-18 bold">Legend</p>
              <span className="clickable legend" onClick={this.props.legendCloseHandler} style={{float: 'right'}}>
                  <img src="assets/close-icon-grey.svg" alt=""/>
              </span>
            </div>
            <div className="stats-legend-content">
              {this.props.children}
            </div>
            <div className="stats-legend-footer">
              <div className="stats-legend-close-button">
                <span className="clickable" style={{height: '100%', width: '100%', textDecoration: 'none'}} onClick={this.props.legendCloseHandler}>
                  <div className="vertically-centered horizontally-centered">
                      <p className="font size-14 bold">Close</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </span>
    );
  }
}

export default Legend;
