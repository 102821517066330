import React from 'react';

class Drawer extends React.Component {
    constructor(props) {
        super(props);

        this.closeDrawer = this.closeDrawer.bind(this);
        this.apply = this.apply.bind(this);
    }

    closeDrawer() {
        this.props.closeHandler();
    }

    apply() {
        this.props.applyHandler();
    }
  
    render() {
        if (!this.props.open) {
            return (<></>)
        }
        return (
            <>
                <div class="screener-modal-overlay" style={this.props.open ? {display: 'block'} : {display: 'none'}} onClick={this.closeDrawer}></div>
                <div class="screener-filters-modal" style={this.props.open ? {display: 'flex'} : {display: 'none'}}>
                    <div class="screener-modal-header">
                        <h3>Filters</h3>
                        <div class="screener-close-modal" onClick={this.closeDrawer}>
                            <img src="assets/playbook/close-major.svg" width="20" height="20" alt=""/>
                        </div>
                    </div>
                    <div class="screener-modal-body">
                        {
                            this.props.children
                        }
                    </div>
                    <div class="screener-modal-footer">
                        <div class="screener-modal-btn-group">
                            <button class="btn-action-secondary" onClick={this.closeDrawer}>Cancel</button>
                            <button class="btn-action-primary" onClick={this.closeDrawer}>Apply Filters</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
  }

  export default Drawer;