import React from 'react';
import {parseDateFromString} from './linemate-react-common/src/util.js';

import './fonts.css';
import './generic.css';
import './playbook.css';
import './buttons.css';
import './scrollable-games-banner.css';
import { getLeagueLogoPath, getTeamLogoPath } from './react-web-utils.js';

class ScrollableGamesBanner extends React.Component {
    constructor(props) {
        super(props);

        this.upcomingGamesScrolled = this.upcomingGamesScrolled.bind(this);
        this.scrollUpcomingGames = this.scrollUpcomingGames.bind(this);
        this.leftScrollButtonRef = React.createRef();
        this.rightScrollButtonRef = React.createRef();
        this.upcomingGamesRef = React.createRef();
    }

    upcomingGamesScrolled(event) {
        const leftValue = event.nativeEvent.srcElement.scrollLeft;
        const width = event.nativeEvent.srcElement.scrollWidth;
        const clientWidth = event.nativeEvent.srcElement.clientWidth;
    
        var upcomingGamesLeftScroll;
        var upcomingGamesRightScroll;
        if (leftValue > 0) {
        //   upcomingGamesLeftScroll = true;
          this.leftScrollButtonRef.current.style.display = 'block';
          this.leftScrollButtonRef.current.style.left = `${leftValue}px`;
        } else {
        //   upcomingGamesLeftScroll = false;
          this.leftScrollButtonRef.current.style.display = 'none';
        }
    
        if ((leftValue + clientWidth) === width) {
        //   upcomingGamesRightScroll = false;
          this.rightScrollButtonRef.current.style.display = 'none';
        } else {
        //   upcomingGamesRightScroll = true;
          this.rightScrollButtonRef.current.style.display = 'block';
          this.rightScrollButtonRef.current.style.left = `${clientWidth + leftValue}px`;
        }
    
        // this.forceUpdate();
    
        // this.setState({
        //   upcomingGamesLeftScroll: upcomingGamesLeftScroll,
        //   upcomingGamesRightScroll: upcomingGamesRightScroll
        // });
    }
    
    scrollUpcomingGames(event) {
        const startingScroll = parseInt(this.upcomingGamesRef.current.scrollLeft);
        // Size of the card + the margin on each size
        const scrollAmount = parseInt(event.currentTarget.dataset.scrollAmount) + 20;
        const duration = 500;

        //setTimeout(this.timedScroll.bind(null, 1, fps, startingScroll, scrollPerInterval, intervalTime), intervalTime);
        var upcomingGamesRef = this.upcomingGamesRef.current;
        var start;
        // Bootstrap our animation - it will get called right before next frame shall be rendered.
        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp;
            // Elapsed milliseconds since start of scrolling.
            var time = timestamp - start;
            // Get percent of completion in range [0, 1].
            var percent = Math.min(time / duration, 1);

            upcomingGamesRef.scroll(startingScroll + (scrollAmount * percent), 0);

            // Proceed with animation as long as we wanted it to.
            if (time < duration) {
            window.requestAnimationFrame(step);
            }
        })
    }

    render() {
        var leftScroll = false;
        var rightScroll = false;
        if (this.upcomingGamesRef.current) {
            const scrollWidth = this.upcomingGamesRef.current.scrollWidth;
            const clientWidth = this.upcomingGamesRef.current.clientWidth;
            if (scrollWidth > clientWidth) {
                rightScroll = true;
            }   
        }
        return (
            <div className="upcoming-matchups scroll" ref={this.upcomingGamesRef}  onScroll={this.upcomingGamesScrolled}>
                <div ref={this.leftScrollButtonRef} className={"clickable unselectable overflow-pointer-left"} style={leftScroll ? {display: 'block'} : {display: 'none'}} data-scroll-amount={-215} onClick={this.scrollUpcomingGames}>
                <img className="vertically-centered" src="assets/leading-icon-grey.svg" height={20} width={20} alt=""/>
                </div>
                {
                    !this.props.showAllGames ? <></> :
                    <a className={`all-matchups-card ${this.props.allGamesSelected ? "matchups-card-selected" : ""}`} href={`${this.props.activeLeague}/${this.props.pagePath}`}>
                        <div className="all-matchups-card-row">
                            <img src={getLeagueLogoPath(this.props.activeLeague)} height={24} width={24} alt=""/>
                            <p className="font size-14 weight-600 spaced">ALL GAMES</p>
                        </div>
                        <div className="all-matchups-card-row">
                            <p className="font size-14 spaced">{this.props.allGamesDateText}</p>
                        </div>
                    </a>
                }
                {
                    this.props.upcomingGames.map((game, index) => 
                        <a key={index} className={`matchups-card ${this.props.currentGame && this.props.currentGame.id === game.id && !this.props.allGamesSelected ? "matchups-card-selected" : ""}`} href={`${this.props.activeLeague}/${this.props.pagePath}/${game.id}`}>
                            <div className="matchups-card-row">
                                <div className="font size-14 weight-600 spaced">
                                    <img src={getTeamLogoPath(this.props.activeLeague, game.awayTeamData.info.code)} height={24} width={24} alt=""/> <span className="">{game.awayTeamData.info.code}</span>
                                </div>
                                <div className="font size-14 spaced">{parseDateFromString(game.timestamp).formattedTime}</div>
                            </div>
                            <div className="matchups-card-row">
                                <div className="font size-14 weight-600 spaced">
                                    <img src={getTeamLogoPath(this.props.activeLeague, game.homeTeamData.info.code)} height={24} width={24} alt=""/> <span className="">{game.homeTeamData.info.code}</span>
                                </div>
                                <div className="font size-14 spaced">{parseDateFromString(game.timestamp).formattedDayMonthShort}</div>
                            </div>
                        </a>
                    )
                }
                <div ref={this.rightScrollButtonRef} className={"clickable unselectable overflow-pointer-right"} style={rightScroll ? {display: 'block'} : {display: 'none'}} data-scroll-amount={215} onClick={this.scrollUpcomingGames}>
                    <img className="vertically-centered" src="assets/trailing-icon-grey.svg" height={20} width={20} alt=""/>
                </div>
            </div>
        )
    }
}

export default ScrollableGamesBanner;