import React from 'react';

import './Profile.scss'
import '../components/App.scss'
import GlobalContext from '../components/GlobalContext';
import Button from '../components/Button';
import HtmlHeaders from '../html-headers';
import InputSelection from '../components/InputSelection';
import { API_HOST } from '../react-web-constants';
import { getTheme, setTheme } from '../react-web-utils';
import { isDictSubsetEqual } from '../linemate-react-common/src/core-utils';
import { containsLowercase, containsNumber, containsUppercase, isAlphaNumeric, updateUserAttributes } from '../linemate-react-common/src/util';
import _ from 'lodash';
import Dialog from '../components/Dialog';
import { auth } from '../firebase.js';
import Checkbox from '../components/Checkbox';

class Profile extends React.Component {

    static contextType = GlobalContext;

    constructor(props, context) {
        // https://github.com/facebook/react/issues/13944
        // Looks like this API is being deprecated but it doesn't look like there is any official way of doing it in the new API
        super(props, context);

        if (!context.user) {
            window.location.href = `/${context.configuration.metadata.defaultLeague}`;
        }

        this.billingLinkClicked = this.billingLinkClicked.bind(this);
        this.updateInfo = this.updateInfo.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.updatePreferences = this.updatePreferences.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);

        this.changePasswordOldPasswordRef = React.createRef();
        this.changePasswordNewPasswordRef = React.createRef();
        this.changePasswordConfirmNewPasswordRef = React.createRef();
        this.deleteAccountPasswordRef = React.createRef();

        this.preferredOddsFormatOptions = {
            american: 'American',
            decimal: 'Decimal'
        }

        this.themeOptions = {
            // system: 'Match System',
            light: 'Light',
            dark: 'Dark'
        }

        this.state = {
            changePasswordDialogOpen: false,
            deleteAccountDialogOpen: false,
            email: context.user.email,
            initialUserAttributes: {
                firstName: context.userAttributes.firstName,
                lastName: context.userAttributes.lastName,
                username: context.userAttributes.username
            },
            userAttributes: {
                firstName: context.userAttributes.firstName,
                lastName: context.userAttributes.lastName,
                username: context.userAttributes.username
            },
            usernameValidation: null,
            changePassword: {
                oldPasswordFilled: false,
                newPasswordFilled: false,
                confirmNewPasswordFilled: false
            },
            changePasswordValidation: {
                oldPassword: null,
                newPassword: null,
                confirmNewPassword: null
            },
            // Checking both for backwards compatibility
            //  TODO: remove this eventually
            initialPreferences: {
                preferredOddsFormat: context.userAttributes.preferredOddsFormat || context.userAttributes.preferredLineFormat,
                theme: getTheme()
            },
            preferences: {
                preferredOddsFormat: context.userAttributes.preferredOddsFormat || context.userAttributes.preferredLineFormat,
                theme: getTheme()
            },
            deleteAccount: {
                acknowledgmentChecked: false,
                passwordFilled: false,
                error: null
            }
        }
    }

    componentDidMount() {
        document.title = 'Profile - Linemate';
        auth.onAuthStateChanged((user) => {
            if (!user) {
                window.location.href = `/${this.context.configuration.metadata.defaultLeague}`;
            }
        });
    }

    billingLinkClicked() {
        this.context.user.getIdToken().then((token) => {
            const requestInfo = {
                headers: {'Authorization': "Bearer " + token}
            };
            fetch(`${API_HOST}/api/payment/v1/portal`, requestInfo).then(data => data.json())
            .then(result => {
                if (result.code && result.code > 200) {
                    console.log("Error fetching stripe url");
                } else {
                    window.open(result.redirect, '_blank').focus();
                }
            })
            .catch(error => {
                console.log("Error fetching stripe url", error);
            });
        })
    }

    updateInfo() {
        const username = this.state.userAttributes.username;
        if (username.length < 6 || !isAlphaNumeric(username)) {
            this.setState({usernameValidation: "Username must be at least 6 characters and may only contain letters and numbers."})
            return;
        }

        fetch(`${API_HOST}/api/users/v1/checkUsernameAvailability?username=${username}`)
        .then(data => data.json())
        .then((response) => {
            if (response.available) {
                this.context.user.getIdToken().then((token) => {
                    updateUserAttributes(API_HOST, token, this.state.userAttributes)
                    .then(() => {
                        this.setState({initialUserAttributes: _.cloneDeep(this.state.userAttributes), usernameValidation: null})
                    })
                })
            } else {
                this.setState({usernameValidation: "Username is already taken."})
            }
        })
    }

    changePassword() {
        const newPassword = this.changePasswordNewPasswordRef.current.value;
        if (newPassword.length < 8 || !containsNumber(newPassword) || !containsUppercase(newPassword) || !containsLowercase(newPassword)) {
            this.setState({changePasswordValidation: {newPassword: "Password needs to include an uppercase letter, a lowercase letter and be at least 8 characters long."}})
            return;
        }
        const confirmNewPassword = this.changePasswordConfirmNewPasswordRef.current.value;
        if (newPassword !== confirmNewPassword) {
            this.setState({changePasswordValidation: {confirmNewPassword: "Confirm password does not match."}})
            return;
        }

        auth.signInWithEmailAndPassword(this.state.email, this.changePasswordOldPasswordRef.current.value)
        .then((credentials) => {
            auth.updatePassword(credentials.user, this.changePasswordNewPasswordRef.current.value)
            .then(() => {
                this.setState({changePasswordDialogOpen: false, changePassword: {oldPasswordFilled: false, newPasswordFilled: false, confirmNewPasswordFilled: false}})
            })
            .catch((error) => {
                console.log(error);
                // Re-using the confirm password space as a generic error spot at the bottom
                this.setState({changePasswordValidation: {confirmNewPassword: "Something went wrong, please try again later."}})
            })
        })
        .catch((error) => {
            var errorCode = error.code;
            console.log(errorCode);
            switch (errorCode) {
                case 'auth/wrong-password':
                    this.setState({changePasswordValidation: {oldPassword: "Incorrect password."}})
                    break;
                default:
                    // Re-using the confirm password space as a generic error spot at the bottom
                    this.setState({changePasswordValidation: {confirmNewPassword: "Something went wrong, please try again later."}})
                    break;
            }
        });
    }

    updatePreferences() {
        if (this.state.preferences.preferredOddsFormat !== this.state.initialPreferences.preferredOddsFormat) {
            this.context.user.getIdToken().then((token) => {
                // Setting both for backwards compatibility
                //  TODO: remove this eventually
                updateUserAttributes(API_HOST, token, {preferredOddsFormat: this.state.preferences.preferredOddsFormat, preferredLineFormat: this.state.preferences.preferredOddsFormat})
                .then(() => {
                    this.setState((previousState) => {return {initialPreferences: {...previousState.initialPreferences, preferredOddsFormat: this.state.preferences.preferredOddsFormat}}})
                })
            })
        }
        if (this.state.preferences.theme !== this.state.initialPreferences.theme) {
            setTheme(this.state.preferences.theme)
            this.setState((previousState) => {return {initialPreferences: {...previousState.initialPreferences, theme: this.state.preferences.theme}}})
        }
    }

    deleteAccount() {
        auth.signInWithEmailAndPassword(this.state.email, this.deleteAccountPasswordRef.current.value)
        .then((credentials) => {
            credentials.user.getIdToken().then((token) => {
                fetch(`${API_HOST}/api/users/v1/delete`, {headers: {'Authorization': "Bearer " + token}, method: 'DELETE'})
                .then(response => {
                    if (response.status == 200) {
                        this.setState({deleteAccountDialogOpen: false}, () => auth.signOut())
                    } else {
                        this.setState(this.setState({deleteAccount: {...this.state.deleteAccount, error: "Something went wrong, please try again later."}}))
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.setState(this.setState({deleteAccount: {...this.state.deleteAccount, error: "Something went wrong, please try again later."}}))
                })
            })
        })
        .catch((error) => {
            console.log(error);
            var errorCode = error.code;
            switch (errorCode) {
                case 'auth/wrong-password':
                    this.setState(this.setState({deleteAccount: {...this.state.deleteAccount, error: "Incorrect password."}}))
                    break;
                default:
                    this.setState(this.setState({deleteAccount: {...this.state.deleteAccount, error: "Something went wrong, please try again later."}}))
                    break;
            }
        })
    }

    render() {
        const profileInfoChanged = !isDictSubsetEqual(['firstName', 'lastName', 'username'], this.state.userAttributes, this.state.initialUserAttributes);
        const profileInfoFilled = this.state.userAttributes.firstName && this.state.userAttributes.lastName && this.state.userAttributes.username;
        const preferencesChanged = !isDictSubsetEqual(['preferredOddsFormat', 'theme'], this.state.preferences, this.state.initialPreferences);
        return (
            <>
                <HtmlHeaders canonicalRef="https://www.linemate.io/profile"/>
                <div className='profile-page-container'>
                    <div className='profile-page-content'>
                        <div className='profile-page-header'>
                            <p className='text-style-h-2-semibold color-fig-default'>Profile Settings</p>
                            <p className='text-style-label-normal color-fig-subtle'>Manage your Linemate profile</p>
                        </div>
                        {/* Profile info */}
                        <div className='profile-page-info'>
                            <p className='text-style-h-4-medium color-fig-default'>Profile</p>
                            {/* TODO: we also have the email in the user attributes. TBD which should be the source of truth. Probably the firebase one since its their login */}
                            {/* We also should make sure both get updated */}
                            {/* TODO: there's also the Stripe email */}
                            {/* 
                                For now going to keep email out, updating it requires to re-enter password. 
                                If there's time we'll add it the same as change password but TBD on the UX, 
                                need to talk to Johnson since it's a different flow if it belongs under the rest of the regular profile items
                                This will have to be done by requesting password, then triggering a new sign-in
                                auth
                                .signInWithEmailAndPassword(email, password)
                                .then((userCredential) => {
                                    userCredential.user.updateEmail(newEmail)
                                }) 
                                In theory you can do it without re-logging in but if it's been too long it won't work so might as well always ask
                            */}
                            {/* <div className='profile-page-info-row'>
                                <p className='text-style-label-medium color-fig-default'>Email</p>
                                <input value={this.state.email} className='text-style-body-normal' onInput={(e) => this.setState({email: e.target.value})} type="email"/>
                            </div> */}
                            <div className='profile-page-info-row'>
                                <p className='text-style-label-medium color-fig-default'>First name</p>
                                <input value={this.state.userAttributes.firstName} className='text-style-body-normal' onInput={(e) => this.setState({userAttributes: {...this.state.userAttributes, firstName: e.target.value}})} type="text"/>
                            </div>
                            <div className='profile-page-info-row'>
                                <p className='text-style-label-medium color-fig-default'>Last name</p>
                                <input value={this.state.userAttributes.lastName} className='text-style-body-normal' onInput={(e) => this.setState({userAttributes: {...this.state.userAttributes, lastName: e.target.value}})} type="text"/>
                            </div>
                            <div className='profile-page-info-row'>
                                <p className='text-style-label-medium color-fig-default'>Username</p>
                                <input value={this.state.userAttributes.username} className='text-style-body-normal' onInput={(e) => this.setState({userAttributes: {...this.state.userAttributes, username: e.target.value}})} type="text"/>
                            </div>
                            {/* A bit hacky to put it here but can't be bothered to add styling for it */}
                            {this.state.usernameValidation && <p className='text-style-caption-normal color-fig-negative' style={{textAlign: 'end'}}>{this.state.usernameValidation}</p>}
                            <div className='profie-page-info-button'>
                                <Button text="Update" typography="md" type="primary" enabled={profileInfoChanged && profileInfoFilled} onClick={this.updateInfo}/>
                            </div>
                        </div>
                        {/* Password */}
                        <div className='profile-page-password'>
                            <p className='text-style-h-4-medium color-fig-default'>Password</p>
                            <div className='profile-page-password-row'>
                                <p className='text-style-label-medium color-fig-default'>Change your Linemate account password</p>
                                <div className='profile-page-password-row-button'>
                                    <Dialog
                                        open={this.state.changePasswordDialogOpen}
                                        onOpenChange={(open) => this.setState({changePasswordDialogOpen: open, changePassword: {oldPasswordFilled: false, newPasswordFilled: false, confirmNewPasswordFilled: false}})}
                                        trigger={
                                            <Button text="Change password" typography="md" type="secondary" enabled={true} onClick={() => this.setState({changePasswordDialogOpen: true})}/>
                                        }
                                        title="Change password"
                                        content={
                                            <div className='profile-page-change-password-dialog-content'>
                                                <div className='profile-page-change-password-dialog-content-row'>
                                                    <p className='text-style-label-medium color-fig-default'>Old password</p>
                                                    <input ref={this.changePasswordOldPasswordRef} type='password' onInput={(e) => this.setState({changePassword: {...this.state.changePassword, oldPasswordFilled: e.target.value && e.target.value.trim() !== ""}})}/>
                                                    {this.state.changePasswordValidation.oldPassword && <p className='text-style-caption-normal color-fig-negative'>{this.state.changePasswordValidation.oldPassword}</p>}
                                                </div>
                                                <div className='profile-page-change-password-dialog-content-row'>
                                                    <p className='text-style-label-medium color-fig-default'>New password</p>
                                                    <input ref={this.changePasswordNewPasswordRef} type='password' onInput={(e) => this.setState({changePassword: {...this.state.changePassword, newPasswordFilled: e.target.value && e.target.value.trim() !== ""}})}/>
                                                    {this.state.changePasswordValidation.newPassword && <p className='text-style-caption-normal color-fig-negative'>{this.state.changePasswordValidation.newPassword}</p>}
                                                </div>
                                                <div className='profile-page-change-password-dialog-content-row'>
                                                    <p className='text-style-label-medium color-fig-default'>Confirm new password</p>
                                                    <input ref={this.changePasswordConfirmNewPasswordRef} type='password' onInput={(e) => this.setState({changePassword: {...this.state.changePassword, confirmNewPasswordFilled: e.target.value && e.target.value.trim() !== ""}})}/>
                                                    {this.state.changePasswordValidation.confirmNewPassword && <p className='text-style-caption-normal color-fig-negative'>{this.state.changePasswordValidation.confirmNewPassword}</p>}
                                                </div>
                                            </div>
                                        }
                                        footer={
                                            <div className='profile-page-change-password-dialog-footer'>
                                                <div>
                                                    <Button text="Cancel" typography="md" type="secondary" enabled={true} onClick={() => this.setState({changePasswordDialogOpen: false})}/>
                                                </div>
                                                <div>
                                                    <Button 
                                                        text="Change password" 
                                                        typography="md" 
                                                        type="primary" 
                                                        enabled={this.state.changePassword.confirmNewPasswordFilled && this.state.changePassword.newPasswordFilled && this.state.changePassword.oldPasswordFilled}
                                                        onClick={this.changePassword}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Preferences */}
                        <div className='profile-page-preferences'>
                            <p className='text-style-h-4-medium color-fig-default'>Display</p>
                            <div className='profile-page-preferences-row'>
                                <div className='profile-page-preferences-row-left'>
                                    <p className='text-style-label-medium color-fig-default'>Odds</p>
                                    <p className='text-style-label-normal color-fig-subtle'>Select the format you want the odds to be displayed in.</p>
                                </div>
                                <div className='profile-page-preferences-row-right'>
                                    <InputSelection type="single" options={this.preferredOddsFormatOptions} selection={this.state.preferences.preferredOddsFormat} typography="md" enabled={true} selectionHandler={(selection) => this.setState({preferences: {...this.state.preferences, preferredOddsFormat: selection}})}/>
                                </div>
                            </div>
                            <div className='profile-page-preferences-row'>
                                <div className='profile-page-preferences-row-left'>
                                    <p className='text-style-label-medium color-fig-default'>Theme</p>
                                    <p className='text-style-label-normal color-fig-subtle'>Select how Linemate is displayed.</p>
                                </div>
                                <div className='profile-page-preferences-row-right'>
                                    <InputSelection type="single" options={this.themeOptions} selection={this.state.preferences.theme} typography="md" enabled={true} selectionHandler={(selection) => this.setState({preferences: {...this.state.preferences, theme: selection}})}/>
                                </div>
                            </div>
                            <div className='profile-page-preferences-button'>
                                <Button text="Update" typography="md" type="primary" enabled={preferencesChanged} onClick={this.updatePreferences}/>
                            </div>
                        </div>
                        {/* Billing */}
                        <div className='profile-page-billing'>
                            <p className='text-style-h-4-medium color-fig-default'>Billing</p>
                            {
                                ((this.context.userAttributes.purchasePlatform && 
                                this.context.userAttributes.purchasePlatform === "web") || 
                                (this.context.userAttributes.accessLevel !== 3)) && (
                                    <div className='profile-page-billing-row'>
                                        <div className='profile-page-billing-row-left'>
                                            <p className='text-style-label-medium color-fig-default'>Keep track of your plan and option for payments</p>
                                            <p className='text-style-label-normal color-fig-subtle'>Linemate partners with Stripe for simplified billing and payment management.</p>
                                        </div>
                                        <div className='profile-page-billing-row-right'>
                                            <Button text="Manage billing" typography="md" type="secondary" enabled={true} onClick={this.billingLinkClicked}/>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.context.userAttributes.accessLevel === 3 &&
                                this.context.userAttributes.purchasePlatform && 
                                this.context.userAttributes.purchasePlatform === "ios" && (
                                    <div className='profile-page-billing-row'>
                                        <div className='profile-page-billing-row-left'>
                                            <p className='text-style-label-medium color-fig-default'>To manage your subscription please visit the Subscriptions section of your Apple account associated with this purchase</p>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.context.userAttributes.accessLevel === 3 &&
                                this.context.userAttributes.purchasePlatform && 
                                this.context.userAttributes.purchasePlatform === "android" && (
                                    <div className='profile-page-billing-row'>
                                        <div className='profile-page-billing-row-left'>
                                            <p className='text-style-label-medium color-fig-default'>To manage your subscription please visit the Subscriptions section of your Google Play Store account associated with this purchase</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        {/* Delete account */}
                        <div className='profile-page-delete-account'>
                            <p className='text-style-h-4-medium color-fig-default'>Delete Account</p>
                            <div className='profile-page-delete-account-row'>
                                <div className='profile-page-delete-account-row-left'>
                                    <p className='text-style-label-medium color-fig-default'>Delete your account and account data</p>
                                    <p className='text-style-label-normal color-fig-subtle'>If you want to permanently delete your account, you can do so on your right.</p>
                                </div>
                                <div className='profile-page-delete-account-row-right'>
                                    <Dialog
                                        open={this.state.deleteAccountDialogOpen}
                                        onOpenChange={(open) => this.setState({deleteAccountDialogOpen: open, deleteAccount: {acknowledgmentChecked: this.state.deleteAccount.acknowledgmentChecked && open, passwordFilled: this.state.deleteAccount.passwordFilled && open, error: null}})}
                                        trigger={
                                            <Button text="Delete this account" typography="md" type="destructive" enabled={true} onClick={() => this.setState({deleteAccountDialogOpen: true})}/>
                                        }
                                        title="Delete account"
                                        content={
                                            <div className='profile-page-delete-account-dialog-content'>
                                                <div>
                                                    <p className='text-style-label-normal color-fig-subtle'>Are you sure you want to delete your account? If so, please continue below.</p>
                                                    <p className='text-style-label-normal color-fig-subtle'>Keep in mind that all of your data will be permanently removed from our servers forever. This action will end your subscription for the next billing period and cannot be undone.</p>
                                                </div>
                                                <div>
                                                    <p className='text-style-label-medium color-fig-default'>Enter your password</p>
                                                    <input ref={this.deleteAccountPasswordRef} type='password' onInput={(e) => this.setState({deleteAccount: {...this.state.deleteAccount, passwordFilled: e.target.value && e.target.value.trim() !== ""}})} />
                                                    {this.state.deleteAccount.error && <p className='text-style-caption-normal color-fig-negative'>{this.state.deleteAccount.error}</p>}
                                                </div>
                                                <div>
                                                    <div className='profile-page-delete-account-dialog-content-checkbox-wrapper'>
                                                        <Checkbox checked={this.state.deleteAccount.acknowledgmentChecked} onClick={() => this.setState({deleteAccount: {...this.state.deleteAccount, acknowledgmentChecked: !this.state.deleteAccount.acknowledgmentChecked}})}/>
                                                    </div>
                                                    <p className='text-style-label-normal color-fig-default'>I acknowledge that all of the data in this account will be deleted and want to proceed.</p>
                                                </div>
                                            </div>
                                        }
                                        footer={
                                            <div className='profile-page-delete-account-dialog-footer'>
                                                <div>
                                                    <Button 
                                                        text="Delete this account" 
                                                        typography="md"
                                                        type="destructive" 
                                                        enabled={this.state.deleteAccount.acknowledgmentChecked && this.state.deleteAccount.passwordFilled}
                                                        onClick={this.deleteAccount} 
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Profile;