import React from 'react';

import './Table.scss'
import './App.scss'


/**
 * Props
 * - columns: [string]
 * - columnStyling: fn(columnIndex, columnName)
 * - rowCount: int
 * - dataExtractor: fn(rowIndex, columIndex, columName)
 */
class Table extends React.Component {

  render() {
    return (
        <div className='table-component-wrapper'>
            <table className={`table-component ${this.props.className || ""}`}>
                <thead>
                    <tr>
                    {
                        this.props.columns.map((column, columnIndex) => 
                            <th key={column} className='text-style-caption-medium' style={this.props.columnStyling ? this.props.columnStyling(columnIndex, column) || {} : {}}>
                                {column}
                            </th>
                        )
                    }
                    </tr>
                </thead>
                <tbody>
                {
                    [...Array(this.props.rowCount).keys()].map((rowIndex) =>
                        // Tehnically using index as key is discouraged but not sure what else to use here
                        <tr key={rowIndex}>
                            {
                                this.props.columns.map((column, columnIndex) => 
                                    <td key={`${rowIndex}-${column}`} className='text-style-label-tabular-medium' style={this.props.columnStyling ? this.props.columnStyling(columnIndex, column) || {} : {}}>
                                        {
                                            this.props.dataExtractor(rowIndex, columnIndex, column)
                                        }
                                    </td>
                                )
                            }
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    );
  }
}

export default Table;