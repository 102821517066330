import React from "react";

import './playbook.css';

/**
 * fullWidthThreshold => pixel value under which the picker will take up the full width of the parent
 * fullWidth => if set to true, fullWidthThreshold is ignored and it's always using up 100% of available width
 */
class Picker extends React.Component {
    constructor(props) {
        super(props);

        this.optionSelected = this.optionSelected.bind(this);
        this.windowResized = this.windowResized.bind(this);

        this.state = {
            // We used to keep track of the selection within the component but that didn't allow us to manually set which one we want to be selected
            // selection: Object.keys(this.props.options)[0],
            // These aren't actually used it's just to get the page to re-render on resize
            width: window.innerWidth
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.windowResized);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResized);
    }

    optionSelected(event) {
        // this.setState({selection: event.currentTarget.dataset.selection});
        this.props.selectionHandlerFn(event);
    }

    windowResized() {
        if (this.props.fullWidth) {
            return;
        }
        const width = window.innerWidth;
        // Only re-render when crossing the threshold
        if ((this.state.width >= this.props.fullWidthThreshold && width < this.props.fullWidthThreshold) || 
            (this.state.width < this.props.fullWidthThreshold && width >= this.props.fullWidthThreshold)) {
            this.setState({ width: window.innerWidth });
        }
    }

    render() {
        const elementCount = Object.keys(this.props.options).length;
        const optionWidth = 125;
        var pickerStyling = {
            verticalAlign: 'top',
            position: 'relative'
        };
        if (this.state.width < this.props.fullWidthThreshold || this.props.fullWidth) {
            pickerStyling['width'] = '100%';
        } else {
            pickerStyling['width'] = `${elementCount * optionWidth}px`;
        }
        return (
            // The vertical alignment and position may have to be changed depending on the situation by wrapping the picker in another div wherever it's being used
            <div className="picker playbook-picker" style={pickerStyling}>
                {
                    Object.keys(this.props.options).map((key) =>
                        // % width for the picker option is better than the flat value we have determined due to the padding
                        <div key={key} className={this.props.selection === key ? "picker-active" : "picker-inactive"} style={{width: `${100/elementCount}%`}}>
                            <span className="clickable" style={{height: '100%', width: '100%', textDecoration: 'none'}} 
                                    data-selection={key} onClick={this.optionSelected}>
                            <div className="vertically-centered horizontally-centered">
                                <p className={this.props.selection === key ? "font size-14 bold color-fig-default" : "font size-14 color-fig-subtle"}>{this.props.options[key]}</p>
                            </div>
                            </span>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Picker;