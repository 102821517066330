import React from 'react';
import './fonts.css';
import './horizontal-comparison-bar.css';


class HorizontalComparisonBar extends React.Component {
  render() {
    const leftValueRaw = this.props.leftValue;
    const rightValueRaw = this.props.rightValue;
    const leftValue = Number(leftValueRaw);
    const rightValue = Number(rightValueRaw);
    const leftPercentage = (leftValue / (leftValue + rightValue)) * 100;
    const rightPercentage = (rightValue / (leftValue + rightValue)) * 100;
    const leftColorHex = this.props.leftColorHex;
    const rightColorHex = this.props.rightColorHex;

    const showPercentage = this.props.showPercentage || false;
    var leftText;
    var rightText;
    if (showPercentage) {
      leftText = leftPercentage.toFixed(1) + '%';
      rightText = rightPercentage.toFixed(1) + '%';
    } else {
      leftText = leftValueRaw;
      rightText = rightValueRaw;
    }

    const leftStyling = {
      height: '8px',
      width: (leftPercentage - 1) + '%',
      display: 'inline',
      float: 'left',
      position: 'relative',
      background: leftColorHex,
      borderRadius: '8px',
      marginRight: '1%'
    }
    const rightStyling = {
      height: '8px',
      width: (rightPercentage - 1) + '%',
      display: 'inline',
      float: 'left',
      position: 'relative',
      background: rightColorHex,
      borderRadius: '8px',
      marginLeft: '1%'
    }

    return(
      <div style={{height: '100%', width: '100%', paddingTop: '16px'}}>
        <div className='horizontal-comparison-bar-text-wrapper' style={{height: '50%', width: '100%'}}>
          <div style={{height: '100%', width: '33%', display: 'inline', float: 'left', position: 'relative'}}><p className="vertically-centered font size-18" style={{textAlign: 'left'}}>{leftText}</p></div>
          <div style={{height: '100%', width: '33%', display: 'inline', float: 'left', position: 'relative'}}><p className="vertically-centered font size-12" style={{textAlign: 'center'}}>{this.props.text}</p></div>
          <div style={{height: '100%', width: '33%', display: 'inline', float: 'left', position: 'relative'}}><p className="vertically-centered font size-18" style={{textAlign: 'right'}}>{rightText}</p></div>
        </div>
        <div className="odd-graph">
          <div style={leftStyling}></div>
          <div style={rightStyling}></div>
        </div>
      </div>
    );
  }
}

export default HorizontalComparisonBar;
