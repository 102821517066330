import React from "react";

import './tag.css';

// TODO: if we want them to be clickable and the link/function handler
class Tag extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var style = {
            background: this.props.color
        };
        if (this.props.borderColor) {
            style['border'] = `1px solid ${this.props.borderColor}`;
        }
        return (
            <span class="tag-container" style={style}>
                <p class="font size-14 spaced" style={{color: this.props.textColor}}>{this.props.text}</p>
            </span>
        )
    }
}

export default Tag;