import { HOME_AWAY_SPLIT, SCORE_PATHS, TIMEFRAME_FILTER, BETTING_LEADERBOARD_TIMEFRAMES,
         BET_TYPE_FILTER, MIN_ODDS_FILTER, OVER_UNDER_FILTER, PLAYER_BET_TYPE_ID_TO_NAME,
         PLAYER_BET_TYPES_REVERSE_MAPPING, GAME_TOTAL_MARKET_NAME, SPREAD_MARKET_NAME,
         SPREAD_MARKET_ID, GAME_TOTAL_MARKET_ID, getPlayerPropInfo, getSituationalProps, TEAM_BETS, DEFAULT_MARKET_TEXT, TEAM_BET_TYPE_REVERSE_MAPPING, ALL_BETS_MARKET_NAME, GAMES_FILTER, ALL_GAMES_FILTER_OPTION, 
         SCREENER_ONLY_PROPS,
         OUTCOME_TYPE_COVER_YES,
         OUTCOME_TYPE_OVER_UNDER,
         GAME_BET_TYPE,
         TEAM_BET_TYPE,
         WITH_FILTER,
         WITHOUT_FILTER,
         OPPONENT_FILTER,
         NARRATIVES} from "./constants";
import { buildQueryParams, decimalPrecision, getDictionaryValue, isDictEmpty, getBookEntryBasedOnPrecedence,
         getPreferredFormat, getFormattedLineValue, getFormattedOddValue, orderListByField, getPlayerName, getMultiFieldValue, getPlayerId, capitalizeFirstLetter, containsNumber, isSoccer, 
         getLineOutcomeFromCard, getFilterQueryParameter,
         getPreferredOdds,
         getMultiFieldValueWithPrecision} from "./util";

export function getBaseWorkstationQueryParams(currentSeason, opponent, filters) {
    var queryParams = {};
    if (filters.split.selectedValue !== HOME_AWAY_SPLIT) {
        queryParams['split'] = filters.split.options[filters.split.selectedValue];
    }
    const timeframe = filters[TIMEFRAME_FILTER].selectedValue;
    const normalizedTimeframe = timeframe.toLowerCase();
    const withFilter = !(WITH_FILTER in filters) ? null : getFilterQueryParameter(filters[WITH_FILTER])
    const withoutFilter = !(WITHOUT_FILTER in filters) ? null : getFilterQueryParameter(filters[WITHOUT_FILTER])
    if (normalizedTimeframe === "season") {
        // Leave default params to api
    } else if (normalizedTimeframe === "matchup" || normalizedTimeframe === "head to head") {
        queryParams['opponent'] = opponent;
        queryParams['timeframe'] = `RANGE_${currentSeason - 2}_${currentSeason}`;
    } else if (normalizedTimeframe.startsWith("last")) {
        queryParams['timeframe'] = filters[TIMEFRAME_FILTER].options[timeframe];
    }

    // If we don't have an opponent specified but we have on in the filters, we'll use that
    if (!opponent && OPPONENT_FILTER in filters) {
        const opponentFilterSelection = getFilterQueryParameter(filters[OPPONENT_FILTER])
        if (opponentFilterSelection.toLowerCase() !== 'all') {
            queryParams['opponent'] = filters[OPPONENT_FILTER].options[filters[OPPONENT_FILTER].selectedValue];
        }
    }
    
    if (withFilter && withFilter.toLowerCase() !== 'all') {
        queryParams['withPlayerIds'] = withFilter
    } else if (withoutFilter && withoutFilter.toLowerCase() !== 'none') {
        queryParams['withoutPlayerIds'] = withoutFilter
    }

    return queryParams;
}

export function getTeamWorkstationGames(host, league, currentSeason, team, opponent, filters) {
    var queryParams = getBaseWorkstationQueryParams(currentSeason, opponent, filters);
    queryParams.teamCode = team;
    return fetch(`${host}/api/${league}/v1/games/filteredForTeam?${buildQueryParams(queryParams)}`);
}

export function getPlayerWorkstationGames(host, league, currentSeason, opponent, filters, playerSRGUID) {
    var queryParams = getBaseWorkstationQueryParams(currentSeason, opponent, filters);
    queryParams.SRGUID = playerSRGUID;
    return fetch(`${host}/api/${league}/v1/games/filteredForPlayer?${buildQueryParams(queryParams)}`);
}

export function getGameLineValue(league, type, game, betType) {
    if (type === "team") {
        const betId = TEAM_BET_TYPE_REVERSE_MAPPING[league][betType];
        return TEAM_BETS[league][betId].gameValueFunction(game);
    } else {
        const propID = PLAYER_BET_TYPES_REVERSE_MAPPING[league][betType];
        const statFields = getPlayerPropInfo[league](propID).fields;
        var value = 0;
        statFields.map((field) => {
            value += getDictionaryValue(game, `cumulativeStats.${field}`);
        })
        return value;
    }
}

export function getGameLineValueById(league, type, game, betType) {
    if (type === "team") {
        return TEAM_BETS[league][betType].gameValueFunction(game);
    } else {
        const statFields = getPlayerPropInfo[league](betType).fields;
        var value = 0;
        statFields.map((field) => {
            value += getDictionaryValue(game, `cumulativeStats.${field}`);
        })
        return value;
    }
}

export function buildTrendsData(league, type, games, betType, lineValue, marketId) {
    var hits = 0;
    var push = 0;
    var total = 0;
    var homeTotal = 0;
    var awayTotal = 0;
    var homeGames = 0;
    var awayGames = 0;
    games.forEach((game) => {
        const gameValue = typeof marketId === "undefined" ? getGameLineValue(league, type, game, betType) : getGameLineValueById(league, type, game, marketId);
        total += gameValue;
        if (game.isHome) {
            homeGames++;
            homeTotal += gameValue;
        } else {
            awayGames++;
            awayTotal += gameValue;
        }
        // It's less than idea but since right now spread is the only bet type we have that requires it to be 'under' for the cover to hit
        // If we implement more bet types that work in a similar way we should look into a better solution here
        if (gameValue === lineValue) {
            push++;
        } else if (typeof marketId === "undefined" && 
                    ((gameValue > lineValue && betType !== SPREAD_MARKET_NAME) || 
                    (betType === SPREAD_MARKET_NAME && gameValue < lineValue))) {
            hits++;
        } else if (typeof marketId !== "undefined" && 
                    ((gameValue > lineValue && marketId !== SPREAD_MARKET_ID) || 
                    (marketId === SPREAD_MARKET_ID && gameValue < lineValue))) {
            hits++;
        }
    });

    var result = {
        hits: hits,
        push: push
    }
    if (games.length > 0) {
        result['resultAverage'] = decimalPrecision(total / games.length, 1);
        result['hitPercentage'] = decimalPrecision(hits/games.length * 100, 1);
        result['missPercentage'] = decimalPrecision((games.length - hits - push)/games.length * 100, 1);
    } else {
        result['resultAverage'] = 0;
        result['hitPercentage'] = 0;
        result['missPercentage'] = 0;
    }
    if (homeGames > 0) {
        result['resultHomeAverage'] = decimalPrecision(homeTotal / homeGames, 1);
    } else {
        result['resultHomeAverage'] = 0;
    }
    if (awayGames > 0) {
        result['resultAwayAverage'] = decimalPrecision(awayTotal / awayGames, 1);
    } else {
        result['resultAwayAverage'] = 0;
    }
    
    return result;
}

// Once we add dynamic markets this could be used as a game-level lines method and the spread one could be used as a team-level
//    Both will likely have to support EVENT and OVER_UNDER types along with the presence or lack of line value
export function addTotalLines(game, inputLines, timeframe, userAttributes, groupedData) {
    if (!game.markets || isDictEmpty(game.markets) || !game.markets[GAME_TOTAL_MARKET_ID]) {
        return;
    }
    const book = getBookEntryBasedOnPrecedence(game.markets[GAME_TOTAL_MARKET_ID].books);
    if (isDictEmpty(book)) {
        return;
    }
    const gameID = game.id;
    const homeTeamData = game.homeTeamData
    const awayTeamData = game.awayTeamData;
    const lineFormat = getPreferredFormat(userAttributes);
    const pregameHitRecords = game.markets[GAME_TOTAL_MARKET_ID].pregameHitRecords;
    var entries = {};
    ['over', 'under'].forEach((outcome) => {
        if (book[outcome] && (book[outcome].current || book[outcome].opening)) {
            // Seeing the current/opening to each other in case we are missing one. A case where both are missing should have already been checked above
            book[outcome].current = book[outcome].current || book[outcome].opening;
            book[outcome].opening = book[outcome].opening || book[outcome].current;
            const outcomeEntry = {
                isCombined: true,
                gameID: gameID,
                team: homeTeamData.info.code,
                opponent: awayTeamData.info.code,
                homeTeam: homeTeamData.info.code,
                awayTeam: awayTeamData.info.code,
                "bet type": GAME_TOTAL_MARKET_NAME
            }

            // A bit of a hack because of a difference in how the number is populated at the source with a whole number in a floating point field 
            // being displayed with a decimal point with a value of 0 and how javascript treats it without the decimal places
            // This way we add a decimal place of 0 if it's an integer value to match how it's being populated at the source
            const hitRecordKey = getLineHitRecordKey(book[outcome].current.value);
            const hitRecord = pregameHitRecords[hitRecordKey][timeframe].all;

            // Current section
            outcomeEntry.line = book[outcome].current.value;
            outcomeEntry.formattedLine = `${outcome.charAt(0)}${book[outcome].current.value}`;
            outcomeEntry.odds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].current.odds));
            outcomeEntry[`${outcome}Odds`] = outcomeEntry.odds;
            outcomeEntry.games = hitRecord.games;
            outcomeEntry.pushes = hitRecord.pushes;
            // Because we only calculate and save the hit rate of the over we need to calculate the opposite for the under
            if (outcome === 'over') {
                outcomeEntry.hits = hitRecord.hits;
                outcomeEntry.misses = hitRecord.misses;
            } else {
                outcomeEntry.hits = hitRecord.misses;
                outcomeEntry.misses = hitRecord.hits;
            }
            if (outcomeEntry.games === 0) {
                outcomeEntry.hitPercentage = 0;
            } else {
                outcomeEntry.hitPercentage = (outcomeEntry.hits / outcomeEntry.games) * 100;
            }
            outcomeEntry.hitRate = `${outcomeEntry.hits}/${outcomeEntry.games}`;

            // Opening section
            outcomeEntry.openingFormattedLine = `${outcome.charAt(0)}${book[outcome].opening.value}`;
            outcomeEntry.openingOdds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].opening.odds));

            // Line movement section
            const lineDifference = book[outcome].current.value - book[outcome].opening.value
            outcomeEntry.lineMovement = `${lineDifference > 0 ? "+" : ""}${lineDifference}`;

            entries[outcome] = outcomeEntry;
        }
    })
    // Copying each outcome's odds to the other one so that both are present if we select one of them
    if (entries.over && entries.under) {
        entries.over.underOdds = entries.under.underOdds;
        entries.under.overOdds = entries.over.overOdds;
    }

    groupedData[gameID][homeTeamData.info.code][GAME_TOTAL_MARKET_NAME] = {};
    groupedData[gameID][awayTeamData.info.code][GAME_TOTAL_MARKET_NAME] = {};
    Object.keys(entries).forEach((outcome) => {
        inputLines.push(entries[outcome]);
        groupedData[gameID][homeTeamData.info.code][GAME_TOTAL_MARKET_NAME][outcome] = entries[outcome];
        groupedData[gameID][awayTeamData.info.code][GAME_TOTAL_MARKET_NAME][outcome] = entries[outcome];
    });
    // inputLines.push(outcomeEntry);
}
  
export function addSpreadLines(teamData, opposingTeamData, inputLines, gameID, timeframe, userAttributes, groupedData) {
    if (!teamData.markets || isDictEmpty(teamData.markets) || !teamData.markets[SPREAD_MARKET_ID]) {
        return;
    }
    const book = getBookEntryBasedOnPrecedence(teamData.markets[SPREAD_MARKET_ID].books);
    if (isDictEmpty(book)) {
        return;
    }
    const lineFormat = getPreferredFormat(userAttributes);
    const pregameHitRecords = teamData.markets[SPREAD_MARKET_ID].pregameHitRecords;
    const outcome = 'cover';
    if (book[outcome] && (book[outcome].current || book[outcome].opening)) {
        // Seeing the current/opening to each other in case we are missing one. A case where both are missing should have already been checked above
        book[outcome].current = book[outcome].current || book[outcome].opening;
        book[outcome].opening = book[outcome].opening || book[outcome].current;
        const spreadCoverEntry = {
            gameID: gameID,
            team: teamData.info.code,
            opponent: opposingTeamData.info.code,
            "bet type": SPREAD_MARKET_NAME
        }

        // A bit of a hack because of a difference in how the number is populated at the source with a whole number in a floating point field 
        // being displayed with a decimal point with a value of 0 and how javascript treats it without the decimal places
        // This way we add a decimal place of 0 if it's an integer value to match how it's being populated at the source
        const hitRecordKey = getLineHitRecordKey(book[outcome].current.value);
        const hitRecord = pregameHitRecords[hitRecordKey][timeframe].all;

        // Current section
        spreadCoverEntry.line = book[outcome].current.value;
        spreadCoverEntry.formattedLine = `${book[outcome].current.value > 0 ? "+" : ""}${book[outcome].current.value}`;
        spreadCoverEntry.odds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].current.odds));
        // Copying it into overOdds to simplify the use of the entry later on
        spreadCoverEntry.overOdds = spreadCoverEntry.odds;
        spreadCoverEntry.underOdds = null;
        spreadCoverEntry.hits = hitRecord.hits;
        spreadCoverEntry.misses = hitRecord.misses;
        spreadCoverEntry.pushes = hitRecord.pushes;
        spreadCoverEntry.games = hitRecord.games;
        spreadCoverEntry.hitPercentage = hitRecord.hitRate;
        spreadCoverEntry.hitRate = `${hitRecord.hits}/${hitRecord.games}`;

        // Opening section
        spreadCoverEntry.openingFormattedLine = `${book[outcome].opening.value > 0 ? "+" : ""}${book[outcome].opening.value}`;
        spreadCoverEntry.openingOdds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].opening.odds));

        // Line movement section
        const lineDifference = book[outcome].current.value - book[outcome].opening.value
        spreadCoverEntry.lineMovement = `${lineDifference > 0 ? "+" : ""}${lineDifference}`;

        inputLines.push(spreadCoverEntry);
        groupedData[gameID][teamData.info.code][SPREAD_MARKET_NAME] = {
            over: spreadCoverEntry
        };
    }
}
  
export function buildTeamLeaderboard(games, filters, userAttributes, league) {
    var groupedData = {};
    var result = [];
    const timeframeFilter = BETTING_LEADERBOARD_TIMEFRAMES[filters[TIMEFRAME_FILTER].selectedValue];
    const betTypeFilter = filters[BET_TYPE_FILTER].selectedValue;
    const selectedGames = filters[GAMES_FILTER]?.selectedValues || filters[GAMES_FILTER]?.selectedValue;
    // Build a dictionary swapping the key and value of the filter options which will allow us to use the 'in' operator to check for presence rather than iterating a list
    const gamesFilter = Object.fromEntries(selectedGames.map(x => [filters[GAMES_FILTER].options[x], x]))
    for (const game of games) {
        if (!(ALL_GAMES_FILTER_OPTION in gamesFilter) && !(game.id in gamesFilter)) {
            continue;
        }
        const homeTeamData = game.homeTeamData;
        const awayTeamData = game.awayTeamData;

        // This will actually crash the values on every iteration of the timeframe but that's fine because we only need it for the odds right now and 
        groupedData[game.id] = {}
        groupedData[game.id][homeTeamData.info.code] = {};
        groupedData[game.id][awayTeamData.info.code] = {};
        
        addTeamBets(game, result, timeframeFilter, betTypeFilter, userAttributes, groupedData, league)
    }
    orderListByField(result, 'hitPercentage', 'desc');
    return {
        leaderboard: result,
        groups: groupedData
    };
}

// Relying on presence of over/under/cover outcomes for all cases
// Currently supported cases: 
// - over/under for game bets (ex: game total)
// - over/under for team bets (ex: team total)
// - cover + line for team bets (ex: spread)
// - cover without line (ex: moneyline)
// TODO: see if anything can be refactored to reduce duplication between game and team markets
export function addTeamBets(game, inputLines, timeframe, betType, userAttributes, groupedData, league) {
    const teamBets = TEAM_BETS[league];
    const betId = betType === ALL_BETS_MARKET_NAME ? ALL_BETS_MARKET_NAME : TEAM_BET_TYPE_REVERSE_MAPPING[league][betType];
    if ("markets" in game && game.markets && !isDictEmpty(game.markets)) {
        for (const market in game.markets) {
            if (betId != ALL_BETS_MARKET_NAME && betId !== market) {
                continue;
            }
            // TODO: test this
            if (!(market in teamBets) || !("books" in game.markets[market]) || isDictEmpty(game.markets[market].books)) {
                continue;
            }
            const book = getBookEntryBasedOnPrecedence(game.markets[market].books);
            if (isDictEmpty(book)) {
                continue;
            }
            const marketDisplayName = teamBets[market].displayName;
            const entries = {};
            const pregameHitRecords = game.markets[market].pregameHitRecords;
            ["over", "under"].forEach((outcome) => {
                if (outcome in book && book[outcome] && (book[outcome].current || book[outcome].opening)) {
                    entries[outcome] = buildTeamOverUnderEntry(outcome, book, timeframe, pregameHitRecords, true, game, teamBets[market].displayName, userAttributes, true);
                }
            })
            // Copying each outcome's odds to the other one so that both are present if we select one of them
            if (entries.over && entries.under) {
                entries.over.underOdds = entries.under.underOdds;
                entries.under.overOdds = entries.over.overOdds;
            }
            var outcome = "cover";
            if (outcome in book && book[outcome] && (book[outcome].current || book[outcome].opening)) {
                entries[outcome] = buildTeamCoverEntry(book, timeframe, pregameHitRecords, game, teamBets[market].displayName, userAttributes, true, true);
            }
            // Setting on both home and away since it's a shared game-level market that applies to both
            groupedData[game.id][game.homeTeamData.info.code][marketDisplayName] = {};
            groupedData[game.id][game.awayTeamData.info.code][marketDisplayName] = {};
            Object.keys(entries).forEach((outcome) => {
                inputLines.push(entries[outcome]);
                groupedData[game.id][game.homeTeamData.info.code][marketDisplayName][outcome] = entries[outcome];
                groupedData[game.id][game.awayTeamData.info.code][marketDisplayName][outcome] = entries[outcome];
            });
        }
    }

    for (const teamSplit of ["homeTeamData", "awayTeamData"]) {
        if (!(teamSplit in game) || !game[teamSplit] || isDictEmpty(game[teamSplit]) || 
            !("markets" in game[teamSplit]) || !game[teamSplit].markets || isDictEmpty(game[teamSplit].markets)) {
            continue;
        }
        for (const market in game[teamSplit].markets) {
            if (betId != ALL_BETS_MARKET_NAME && betId !== market) {
                continue;
            }
            // TODO: test this
            if (!(market in teamBets) || !("books" in game[teamSplit].markets[market]) || isDictEmpty(game[teamSplit].markets[market].books)) {
                continue;
            }
            const book = getBookEntryBasedOnPrecedence(game[teamSplit].markets[market].books);
            if (isDictEmpty(book)) {
                continue;
            }
            const marketDisplayName = teamBets[market].displayName;
            const entries = {};
            const pregameHitRecords = game[teamSplit].markets[market].pregameHitRecords;
            ["over", "under"].forEach((outcome) => {
                if (outcome in book && book[outcome] && (book[outcome].current || book[outcome].opening)) {
                    entries[outcome] = buildTeamOverUnderEntry(outcome, book, timeframe, pregameHitRecords, false, game, teamBets[market].displayName, userAttributes, teamSplit === "homeTeamData");
                }
            })
            // Copying each outcome's odds to the other one so that both are present if we select one of them
            if (entries.over && entries.under) {
                entries.over.underOdds = entries.under.underOdds;
                entries.under.overOdds = entries.over.overOdds;
            }
            var outcome = "cover";
            if (outcome in book && book[outcome] && (book[outcome].current || book[outcome].opening)) {
                entries[outcome] = buildTeamCoverEntry(book, timeframe, pregameHitRecords, game, teamBets[market].displayName, userAttributes, teamSplit === "homeTeamData", false);
            }
            groupedData[game.id][game[teamSplit].info.code][marketDisplayName] = {}
            Object.keys(entries).forEach((outcome) => {
                inputLines.push(entries[outcome]);
                groupedData[game.id][game[teamSplit].info.code][marketDisplayName][outcome] = entries[outcome];
            });
        }
    }
    
}

export function buildTeamOverUnderEntry(outcome, book, timeframe, pregameHitRecords, isCombined, game, betTypeDisplayName, userAttributes, isHome) {
    const lineFormat = getPreferredFormat(userAttributes);
    const homeTeamData = game.homeTeamData;
    const awayTeamData = game.awayTeamData
    book[outcome].current = book[outcome].current || book[outcome].opening;
    book[outcome].opening = book[outcome].opening || book[outcome].current;

    const outcomeEntry = {
        isCombined: isCombined,
        game: game,
        gameID: game.id,
        home: isHome,
        team: isHome ? homeTeamData.info.code : awayTeamData.info.code,
        opponent: isHome ? awayTeamData.info.code : homeTeamData.info.code,
        homeTeam: homeTeamData.info.code,
        awayTeam: awayTeamData.info.code
    }

    outcomeEntry[BET_TYPE_FILTER] = betTypeDisplayName;

    // A bit of a hack because of a difference in how the number is populated at the source with a whole number in a floating point field 
    // being displayed with a decimal point with a value of 0 and how javascript treats it without the decimal places
    // This way we add a decimal place of 0 if it's an integer value to match how it's being populated at the source

    const hitRecordKey = getLineHitRecordKey(book[outcome].current.value);
    // Not significantly better since it will show "incorrect" data but at least won't crash
    var hitRecord = {
        hits: 0,
        misses: 0,
        games: 0,
        pushes: 0
    }
    if (hitRecordKey in pregameHitRecords) {
        hitRecord = pregameHitRecords[hitRecordKey][timeframe].all;
    }

    outcomeEntry.line = book[outcome].current.value;
    outcomeEntry.formattedLine = `${outcome.charAt(0)}${book[outcome].current.value}`;
    outcomeEntry.odds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].current.odds));
    outcomeEntry[`${outcome}Odds`] = outcomeEntry.odds;
    outcomeEntry.description = `${capitalizeFirstLetter(outcome)} ${outcomeEntry.line} ${betTypeDisplayName} | ${outcomeEntry.odds}`;
    outcomeEntry.games = hitRecord.games;
    outcomeEntry.pushes = hitRecord.pushes;

    // Because we only calculate and save the hit rate of the over we need to calculate the opposite for the under
    // The cover ones will be covered elsewhere
    if (outcome === 'over') {
        outcomeEntry.hits = hitRecord.hits;
        outcomeEntry.misses = hitRecord.misses;
    } else if (outcome === 'under') {
        outcomeEntry.hits = hitRecord.misses;
        outcomeEntry.misses = hitRecord.hits;
    }
    if (outcomeEntry.games === 0) {
        outcomeEntry.hitPercentage = 0;
    } else {
        outcomeEntry.hitPercentage = (outcomeEntry.hits / outcomeEntry.games) * 100;
    }
    outcomeEntry.hitRate = `${outcomeEntry.hits}/${outcomeEntry.games}`;

    // Opening section
    outcomeEntry.openingFormattedLine = `${outcome.charAt(0)}${book[outcome].opening.value}`;
    outcomeEntry.openingOdds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].opening.odds));

    // Line movement section
    const lineDifference = book[outcome].current.value - book[outcome].opening.value
    outcomeEntry.lineMovement = `${lineDifference > 0 ? "+" : ""}${lineDifference}`;

    return outcomeEntry;
}

// TODO: maybe do a try catch here with this whole value shit just to be safe
export function buildTeamCoverEntry(book, timeframe, pregameHitRecords, game, betTypeDisplayName, userAttributes, isHome, isCombined) {
    const outcome = 'cover';
    const lineFormat = getPreferredFormat(userAttributes);
    const homeTeamData = game.homeTeamData;
    const awayTeamData = game.awayTeamData
    const teamData = isHome ? homeTeamData : awayTeamData;
    const opposingTeamData = isHome ? awayTeamData : homeTeamData;
    book[outcome].current = book[outcome].current || book[outcome].opening;
    book[outcome].opening = book[outcome].opening || book[outcome].current;
    const coverEntry = {
        game: game,
        isCombined: isCombined,
        gameID: game.id,
        home: isHome,
        team: teamData.info.code,
        opponent: opposingTeamData.info.code,
        homeTeam: homeTeamData.info.code,
        awayTeam: awayTeamData.info.code
    }
    coverEntry[BET_TYPE_FILTER] = betTypeDisplayName

    const hasValue = ("value" in book[outcome].current && book[outcome].current.value != null && book[outcome].current.value != 0);

    // A bit of a hack because of a difference in how the number is populated at the source with a whole number in a floating point field 
    // being displayed with a decimal point with a value of 0 and how javascript treats it without the decimal places
    // This way we add a decimal place of 0 if it's an integer value to match how it's being populated at the source
    const hitRecordKey = hasValue ? getLineHitRecordKey(book[outcome].current.value) : outcome;
    const hitRecord = pregameHitRecords[hitRecordKey][timeframe].all;

    // Current section
    coverEntry.odds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].current.odds));
    // Copying it into overOdds to simplify the use of the entry later on
    coverEntry.overOdds = coverEntry.odds;
    coverEntry.underOdds = null;
    coverEntry.hits = hitRecord.hits;
    coverEntry.misses = hitRecord.misses;
    coverEntry.pushes = hitRecord.pushes;
    coverEntry.games = hitRecord.games;
    coverEntry.hitPercentage = hitRecord.hitRate;
    coverEntry.hitRate = `${hitRecord.hits}/${hitRecord.games}`;

    // Opening section
    coverEntry.openingOdds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, book[outcome].opening.odds));

    if (hasValue) {
        // Current section
        coverEntry.line = book[outcome].current.value;
        coverEntry.formattedLine = `${book[outcome].current.value > 0 ? "+" : ""}${book[outcome].current.value}`;
        // Opening section
        coverEntry.openingFormattedLine = `${book[outcome].opening.value > 0 ? "+" : ""}${book[outcome].opening.value}`;
        // Line movement section
        const lineDifference = book[outcome].current.value - book[outcome].opening.value
        coverEntry.lineMovement = `${lineDifference > 0 ? "+" : ""}${lineDifference}`;
        // User friendly text
        coverEntry.description = `${coverEntry.formattedLine} ${betTypeDisplayName} | ${coverEntry.odds}`
    } else {
        // Current section
        coverEntry.line = 0;
        coverEntry.formattedLine = DEFAULT_MARKET_TEXT;
        // Opening section
        coverEntry.openingFormattedLine = DEFAULT_MARKET_TEXT;
        // Line movement section
        coverEntry.lineMovement = DEFAULT_MARKET_TEXT;
        // User friendly text
        coverEntry.description = `${betTypeDisplayName} | ${coverEntry.odds}`
    }

    return coverEntry;
}
  
// TODO: if there's a number in the prop don't show the line, and maybe the O/U
// Will need to check for the prop type once we have the EVENT ones
export function addPlayerProps(playerPregameRecord, game, teamSplit, opposingTeamSplit, isHome, inputProps, gameID, fieldMapping, betTypeFilter, minimumOdds, lineType, playerBetTypeMappings, playerBetTypeReverseMappings, teamPropRankings, userAttributes, groupedData, league) {
    const teamData = game[teamSplit];
    const opposingTeamData = game[opposingTeamSplit];
    Object.keys(playerPregameRecord.props).map((prop) => {
        const propName = playerBetTypeMappings[prop];
        // Make sure we only add the props we know about. This is mostly for the app to make sure it doesn't display props from the back-end that aren't configured
        if (propName) {
            groupedData[gameID][getPlayerId(playerPregameRecord.info)][propName] = {};
            if (playerBetTypeReverseMappings[betTypeFilter] === prop || betTypeFilter.toUpperCase().startsWith("ALL")) {
                if (playerPregameRecord.props[prop].type === "OVER_UNDER" || playerPregameRecord.props[prop].type === "EVENT_OVER"){
                    const book = getBookEntryBasedOnPrecedence(playerPregameRecord.props[prop].books);
                    if (!isDictEmpty(book)) {
                        var currentLineValue = !isDictEmpty(book.over) ? book.over.current.value : (!isDictEmpty(book.under) ? book.under.current.value : null);
                        currentLineValue = getLineHitRecordKey(currentLineValue)
                        if (currentLineValue != null && Number(currentLineValue) !== 0 && 
                            !isDictEmpty(playerPregameRecord.props[prop].pregameHitRecords) &&
                            `${currentLineValue}` in playerPregameRecord.props[prop].pregameHitRecords &&
                            fieldMapping in playerPregameRecord.props[prop].pregameHitRecords[`${currentLineValue}`] &&
                            !isDictEmpty(playerPregameRecord.props[prop].pregameHitRecords[`${currentLineValue}`][fieldMapping])) {
                            //TODO: fractional min odds using the lineFormat to check for fractional -- can't just use Number(minimumOdds) for fractional
                            // Re-checking the format to make sure we have the latest info
                            const lineFormat = getPreferredFormat(userAttributes);
                            const overHitRates = playerPregameRecord.props[prop].pregameHitRecords[`${currentLineValue}`][fieldMapping].all;
                            if (book.over && lineType.toLowerCase() !== "under") {
                                const overOdds = getFormattedOddValue(userAttributes, book.over.current.odds);
                                // This just sets the decimal places for decimal odds and sets the + sign in front of the american ones if applicable
                                const odds = getFormattedLineValue(lineFormat, overOdds);
                                // Temporary hack to exclude 0 value odds until we fix the data
                                const zeroOdds = (odds === "" || odds === "0/0" || parseFloat(odds) === 0);
                                if ((minimumOdds.toUpperCase().startsWith("ALL") || overOdds >= Number(minimumOdds)) && !zeroOdds) {
                                    const entry = {
                                        game: game,
                                        gameID: gameID,
                                        SRGUID: getPlayerId(playerPregameRecord.info),
                                        team: teamData.info.code,
                                        playerName: getPlayerName(playerPregameRecord),
                                        position: playerPregameRecord.info.position,
                                        line: currentLineValue,
                                        lineType: 'over',
                                        formattedLine: `o${currentLineValue}`,
                                        odds: odds,
                                        hits: overHitRates.hits,
                                        misses: overHitRates.misses,
                                        pushes: overHitRates.pushes,
                                        games: overHitRates.games,
                                        hitPercentage: overHitRates.hitRate,
                                        hitRate: `${overHitRates.hits}/${overHitRates.games}`,
                                        home: isHome,
                                        opponent: opposingTeamData.info.code,
                                        // The first time this runs for the individual games page we don't have the rankings built
                                        opponentRank: teamPropRankings[prop] ? teamPropRankings[prop][opposingTeamData.info.code] : "--",
                                        // For now will only consider soccer with all the 1-5+ props as not showing the line
                                        description: `${containsNumber(propName) && isSoccer(league) ? "" : `Over ${currentLineValue}`} ${propName} | ${odds}`
                                    }
                                    entry[BET_TYPE_FILTER] = propName;
                                    groupedData[gameID][getPlayerId(playerPregameRecord.info)][propName]['over'] = entry;
                                    inputProps.push(entry);
                                }
                            }
                            if (book.under && lineType.toLowerCase() !== "over") {
                                const underOdds = getFormattedOddValue(userAttributes, book.under.current.odds);
                                // This just sets the decimal places for decimal odds and sets the + sign in front of the american ones if applicable
                                const odds = getFormattedLineValue(lineFormat, underOdds);
                                // Temporary hack to exclude 0 value odds until we fix the data
                                const zeroOdds = (odds === "" || odds === "0/0" || parseFloat(odds) === 0);
                                if ((minimumOdds.toUpperCase().startsWith("ALL") || underOdds >= Number(minimumOdds)) && !zeroOdds) {
                                    const entry = {
                                        game: game,
                                        gameID: gameID,
                                        SRGUID: getPlayerId(playerPregameRecord.info),
                                        team: teamData.info.code,
                                        playerName: getPlayerName(playerPregameRecord),
                                        position: playerPregameRecord.info.position,
                                        "bet type": propName,
                                        line: currentLineValue,
                                        lineType: 'under',
                                        formattedLine: `u${currentLineValue}`,
                                        odds: odds,
                                        hits: overHitRates.misses,
                                        misses: overHitRates.hits,
                                        pushes: overHitRates.pushes,
                                        games: overHitRates.games,
                                        hitPercentage: overHitRates.games === 0 ? 0 : (overHitRates.misses / overHitRates.games) * 100,
                                        hitRate: `${overHitRates.misses}/${overHitRates.games}`,
                                        home: isHome,
                                        opponent: opposingTeamData.info.code,
                                        // The first time this runs for the individual games page we don't have the rankings built
                                        opponentRank: teamPropRankings[prop] ? teamPropRankings[prop][opposingTeamData.info.code] : "--",
                                        // The prop with a number shouldn't happen for under but will do this anyway
                                        // For now will only consider soccer with all the 1-5+ props as not showing the line
                                        description: `${containsNumber(propName) && isSoccer(league) ? "" : `Under ${currentLineValue}`} ${propName} | ${odds}`
                                    }
                                    entry[BET_TYPE_FILTER] = propName;
                                    groupedData[gameID][getPlayerId(playerPregameRecord.info)][propName]['under'] = entry;
                                    inputProps.push(entry);
                                }
                            }
                        }
                    }
                }
            }
        }
    })
}
  
export function buildPlayerBettingLeaderboard(games, filters, league, teamPropRankings, userAttributes) {
    var groupedData = {};
    var result = [];
    const timeframeFilter = BETTING_LEADERBOARD_TIMEFRAMES[filters[TIMEFRAME_FILTER].selectedValue];
    const selectedGames = filters[GAMES_FILTER]?.selectedValues || filters[GAMES_FILTER]?.selectedValue;
    // Build a dictionary swapping the key and value of the filter options which will allow us to use the 'in' operator to check for presence rather than iterating a list
    const gamesFilter = Object.fromEntries(selectedGames.map(x => [filters[GAMES_FILTER].options[x], x]))
    for (const game of games) {
        if (!(ALL_GAMES_FILTER_OPTION in gamesFilter) && !(game.id in gamesFilter)) {
            continue;
        }
        const homePlayers = game.homeTeamData.players || [];
        const awayPlayers = game.awayTeamData.players || [];
        // This will actually crash the values on every iteration of the timeframe but that's fine because we only need it for the odds right now and 
        groupedData[game.id] = {};
        homePlayers.map((player) => {
            groupedData[game.id][getPlayerId(player.info)] = {};
            addPlayerProps(player, game, 'homeTeamData', 'awayTeamData', true, result, game.id, timeframeFilter, filters[BET_TYPE_FILTER].selectedValue, filters[MIN_ODDS_FILTER].selectedValue, filters[OVER_UNDER_FILTER].selectedValue, PLAYER_BET_TYPE_ID_TO_NAME[league], PLAYER_BET_TYPES_REVERSE_MAPPING[league], teamPropRankings, userAttributes, groupedData, league);
        })
        awayPlayers.map((player) => {
            groupedData[game.id][getPlayerId(player.info)] = {};
            addPlayerProps(player, game, 'awayTeamData', 'homeTeamData', false, result, game.id, timeframeFilter, filters[BET_TYPE_FILTER].selectedValue, filters[MIN_ODDS_FILTER].selectedValue, filters[OVER_UNDER_FILTER].selectedValue, PLAYER_BET_TYPE_ID_TO_NAME[league], PLAYER_BET_TYPES_REVERSE_MAPPING[league], teamPropRankings, userAttributes, groupedData, league);
        })
    }
    orderListByField(result, 'hitPercentage', 'desc');
    return {
        leaderboard: result,
        groups: groupedData
    };
}

export function determineOpposingFieldSelection(league, selectedPlayer, opposingTeamData) {
    if (league !== "mlb" || isDictEmpty(selectedPlayer)) {
        return {fieldSelection: "team", opposingPlayerId: null};
    }
    if (selectedPlayer.info.position === "P" || selectedPlayer.info.position === "SP" || selectedPlayer.info.position === "RP" || !opposingTeamData.startingPitcher) {
        return {fieldSelection: "team", opposingPlayerId: null};
    } else {
        return {fieldSelection: "player", opposingPlayerId: opposingTeamData.startingPitcher.SRGUID};
    }
}

// TODO: We really should extract this as an offline exercise and get it from the API
//  We should also look into ranking each stat only once. 
//  There are opposing supporting stats which are the same across multiple props and right now we re-rank each stat for each prop when we could re-use the rank
// TODO: this probably belongs more in general util than in betting-utils
export function buildSupportingStatsRankings(league, teams, situations) {
  var result = {};

  Object.keys(situations).map((situation) => {
      var situationalResult = {};
      const props = getSituationalProps[league](situation);
      Object.keys(props).map((prop) => {
          var propsResult = {};
          const supportingStats = props[prop].opposingSupportingStats;
          Object.keys(supportingStats).map((stat) => {
              const value = supportingStats[stat];
              const fields = value.teamFields;
              var teamRankMapping = buildTeamRankMapping(teams, fields, value.ranked, value.sortingOrder)
              propsResult[stat] = teamRankMapping;
          });
          situationalResult[prop] = propsResult;
      })
      result[situation] = situationalResult;
  })
  return result;
}

// TODO: We really should extract this as an offline exercise and get it from the API
//  We should also look into ranking each stat only once. 
//  There are opposing supporting stats which are the same across multiple props and right now we re-rank each stat for each prop when we could re-use the rank
export function buildPositionalSupportingStatsRankings(league, teams) {
    var result = {};

    const positionSet = new Set()
    teams.forEach((team) => Object.keys(team?.positionalStats || {}).forEach(position => positionSet.add(position)))

    const props = getSituationalProps[league](null);
    Object.keys(props).map((prop) => {
        var propsResult = {};
        const supportingStats = props[prop].opposingPositionalSupportingStats || props[prop].opposingSupportingStats;
        Object.keys(supportingStats).map((stat) => {
            const value = supportingStats[stat];
            if ('positionalTeamFields' in value) {
                propsResult[stat] = {};
                positionSet.forEach((position) => {
                    const fields = value.positionalTeamFields && value.positionalTeamFields(position);
                    var teamRankMapping = buildTeamRankMapping(teams, fields, value.ranked, value.sortingOrder)
                    propsResult[stat][position] = teamRankMapping;
                })
            }
        });
        // Only add the ranking entry if we have a positional team field
        if (!isDictEmpty(propsResult)) {
            result[prop] = propsResult;
        }
    })
    return result;
}

export function buildTeamRankMapping(teams, fields, ranked, sortingOrder) {
    // Creating a local copy of the teams list
    var localTeams = teams.map(a => ({...a}));
    // stat -> teamRankMapping
    // teamRankMapping: team -> rank
    var teamRankMapping = {};
    if (ranked) {
        // Need to iterate to build the value since we have props with multiple fields
        localTeams.map((team) => {
            // Adding a new field to track the final value based on which we're going to sort
            // Precision of 3 to try and avoid some of the rounding causing multiple teams to be ranked the same and giving a different ranking than the back-end
            // The value of 3 is arbitrarily picked
            team.statValue = getMultiFieldValueWithPrecision(team, fields, 3);
        })
        orderListByField(localTeams, 'statValue', sortingOrder);
        localTeams.map((team, index) => {
            teamRankMapping[team.code] = index + 1;
        })
    } else {
        // For ease of use we will keep the original value if it's not to be ranked and add it as-is
        localTeams.map((team) => {
            // Adding a new field to track the final value based on which we're going to sort
            // The precision of 3 probably not necessary here but will leave it unless it causes any problems
            teamRankMapping[team.code] = getMultiFieldValueWithPrecision(team, fields, 3);
        })
    }
    return teamRankMapping
}

// Type = player|team
// Market should be the market object that we get from the API
export function getMarketDescription(league, card) {
    const type = card.type;
    const market = card.market;
    const bookName = card.book;
    const outcomeName = card.outcome;
    if (!(bookName in market.books) || !(outcomeName in market.books[bookName])) {
        return "";
    }
    const lineOutcome = getLineOutcomeFromCard(card);
    if (type === "team") {
        const teamBet = TEAM_BETS[league][market.name];
        if (isDictEmpty(teamBet)) {
            return "";
        }
        if (outcomeName === "over" || outcomeName === "under") {
            return `${capitalizeFirstLetter(outcomeName)} ${lineOutcome.value} ${teamBet.displayName}`
        }
        if (outcomeName === "cover") {
            const hasValue = ("value" in lineOutcome && lineOutcome.value != null && lineOutcome.value != 0);
            if (hasValue) {
                return `${lineOutcome.value > 0 ? "+" : ""}${lineOutcome.value} ${teamBet.displayName}`
            } else {
                return teamBet.displayName;
            }
        }
        // Not going to cover draw for now
        return "";
    } else if (type === "player") {
        const propName = PLAYER_BET_TYPE_ID_TO_NAME[league][market.name];
        if (!propName) {
            return "";
        }
        // We only support over/under props for players, should always have a value
        const line = lineOutcome.value;
        return `${containsNumber(propName) && isSoccer(league) ? "" : `${capitalizeFirstLetter(outcomeName)} ${line}`} ${propName}`
    } else {
        return "";
    }
}

// Formats the line for checking the hit record
// A bit of a hack because of a difference in how the number is populated at the source with a whole number in a floating point field 
// being displayed with a decimal point with a value of 0 and how javascript treats it without the decimal places
// This way we add a decimal place of 0 if it's an integer value to match how it's being populated at the source
export function getLineHitRecordKey(line) {
    if (line == null || line == undefined) {
        return line;
    }
    return Number.isInteger(line) ? `${line}.0` : line;
}

export function supportedParlayLeg(league, leg) {
    return supportedMarketFilter(league, leg)
}

// Works for both straight and parlay
export function supportedNarrativesFilter(trend) {
    return trend.narratives && trend.narratives.length > 0 && trend.narratives.every(narrative => narrative in NARRATIVES)
}

// This is also being used for parlay legs which should have a similar enough structure to be covered
export function supportedMarketFilter(league, trend) {
    return trend.market && isMarketSupported(league, trend.type, trend.market.name);
}

export function isMarketSupported(league, type, market) {
    if (type === "player") {
        return market in PLAYER_BET_TYPE_ID_TO_NAME[league]
    } else if (type === "team") {
        return market in TEAM_BETS[league]
    }
    return false;
}

// Used for straight trends (also originally called discovery cards)
export function supportedTrendFilter(league, trend) {
    return supportedNarrativesFilter(trend) && supportedMarketFilter(league, trend)
}

// Used for parlay trends
export function supportedParlayFilter(league, parlay) {
    // Checks to make sure all narratives are supported
    // Checks to make sure all legs are individually supported in terms of markets
    // Checks to make sure that the parlay legs are all within one team until we add support for cross-team
    return supportedNarrativesFilter(parlay) && parlay.legs && parlay.legs.length > 0 && parlay.legs.every(x => supportedParlayLeg(league, x)) && new Set(parlay.legs.map(x => x.team.code)).size === 1
}

export function getOutcomeValueFromBook(book, outcome) {
    // Assumption is if there's an outcome entry, at the very least the 'current' should be populated, hence skipping check for presence of 'current'
    if (isDictEmpty(book) || !(outcome in book) || !book[outcome] || !('value' in book[outcome].current) || !book[outcome].current.value) {
        return 0;
    } else {
        return book[outcome].current.value;
    }
}

// To be used with the game object where the bet has to be looked up
export function getBetTypeLineValue(league, type, game, betType, teamCode, playerId) {
    var teamPath = "";
    if (game.awayTeamData.info.code === teamCode) {
        teamPath = "awayTeamData";
    } else {
        teamPath = "homeTeamData";
    }
    if (type === "team") {
        const betId = TEAM_BET_TYPE_REVERSE_MAPPING[league][betType];
        if (!(betId in TEAM_BETS[league])) {
            return 0;
        }
        const betDetails = TEAM_BETS[league][betId]
        if (betDetails.outcomeType === OUTCOME_TYPE_COVER_YES) {
            return 0.5;
        }
        const outcome = betDetails.outcomeType === OUTCOME_TYPE_OVER_UNDER ? 'over' : 'cover';
        if (betDetails.type === GAME_BET_TYPE && 'markets' in game && game.markets && betId in game.markets) {
            const book = getBookEntryBasedOnPrecedence(game.markets[betId].books);
            return getOutcomeValueFromBook(book, outcome);
        } else if (betDetails.type === TEAM_BET_TYPE && 'markets' in game[teamPath] && game[teamPath].markets && betId in game[teamPath].markets) {
            const book = getBookEntryBasedOnPrecedence(game[teamPath].markets[betId].books);
            return getOutcomeValueFromBook(book, outcome);
        } else {
            return 0;
        }
    } else if (type === "player") {
        const propName = betType;
        if (!propName || propName == null || propName === "") {
            return 0;
        }
        const playerRecord = game[teamPath].players.find(x => getPlayerId(x.info) === playerId);
        if (!playerRecord) {
            return 0;
        }
        const playerBetTypeReverseMappings = Object.fromEntries(Object.entries(PLAYER_BET_TYPES_REVERSE_MAPPING[league]).filter(([key]) => !SCREENER_ONLY_PROPS[league].includes(key)));
        const propID = playerBetTypeReverseMappings[propName];
        const playerProp = playerRecord.props[propID];
        if (playerProp) {
            const book = getBookEntryBasedOnPrecedence(playerProp.books);
            if (isDictEmpty(book)) {
                return 0;
            } else {
                return book.over.current.value;
            }
        } else {
            return 0;
        }
    }
}

// Meant to be used with the markets from active game info where everything is present and flattened 
//  but in theory can be used with any implementation where the markets are available
export function getMarketLineValue(league, type, markets, betType) {
    if (!markets) {
        return 0;
    }
    if (type === "team") {
        const betId = TEAM_BET_TYPE_REVERSE_MAPPING[league][betType];
        if (!(betId in TEAM_BETS[league])) {
            return 0;
        }
        const betDetails = TEAM_BETS[league][betId]
        if (betDetails.outcomeType === OUTCOME_TYPE_COVER_YES) {
            return 0.5;
        }
        const outcome = betDetails.outcomeType === OUTCOME_TYPE_OVER_UNDER ? 'over' : 'cover';
        if (betId in markets) {
            const book = getBookEntryBasedOnPrecedence(markets[betId].books);
            return getOutcomeValueFromBook(book, outcome);
        } else {
            return 0;
        }
    } else if (type === "player") {
        const propName = betType;
        if (!propName || propName == null || propName === "") {
            return 0;
        }
        const playerBetTypeReverseMappings = Object.fromEntries(Object.entries(PLAYER_BET_TYPES_REVERSE_MAPPING[league]).filter(([key]) => !SCREENER_ONLY_PROPS[league].includes(key)));
        const propID = playerBetTypeReverseMappings[propName];
        const playerProp = markets[propID];
        if (playerProp) {
            const book = getBookEntryBasedOnPrecedence(playerProp.books);
            if (isDictEmpty(book)) {
                return 0;
            } else {
                return book.over.current.value;
            }
        } else {
            return 0;
        }
    }
}

export function getCardKey(card) {
    if (!card) {
        return null;
    }
    if ('id' in card && card.id) {
        return card.id;
    }
    // NOTE: not sure if the 'alternate' field needs to be in the key
    //  Presumably the 'line' should be enough regardless of if it's an alt
    return `${card.gameId}-${card.type}-${card.player != null ? getPlayerId(card.player) : ""}-${card.team.code}-${card.market.name}-${card.book}-${card.line}-${card.alternate}-${card.outcome}-${card.combined}-${card.hasCombinedEquivalent}-${card.narratives.join('-')}`
}

export function getMarketNameFromId(league, type, marketId) {
    if (type === "team") {
        return TEAM_BETS[league][marketId].displayName
    }
    if (type === "player") {
        return PLAYER_BET_TYPE_ID_TO_NAME[league][marketId]
    }
    return ""
}

export function getMarketIdFromName(league, type, marketName) {
    if (type === "team") {
        return TEAM_BET_TYPE_REVERSE_MAPPING[league][marketName]
    }
    if (type === "player") {
        return PLAYER_BET_TYPES_REVERSE_MAPPING[league][marketName]
    }
    return ""
}

function setMarketDataLine(userAttributes, lineKey, marketData, outcomeName, outcome) {
    if (!(`${lineKey}` in marketData.lines)) {
        marketData.lines[`${lineKey}`] = {
            cover: null,
            over: null,
            under: null
        }
    }
    marketData.lines[`${lineKey}`][outcomeName] = getPreferredOdds(userAttributes, outcome.odds, true)
}

export function buildMarketData(league, userAttributes, type, markets, marketId) {
    var marketOutcomeType = OUTCOME_TYPE_OVER_UNDER;
    if (type === "team" && marketId in TEAM_BETS[league]) {
        const market = TEAM_BETS[league][marketId]; 
        marketOutcomeType = market.outcomeType;
    }

    const marketData = {
        marketOutcomeType: marketOutcomeType,
        mainLine: 0,
        lines: {}
    }
    
    if (!(marketId in markets)) {
        return marketData
    }

    const bookOutcomes = getBookEntryBasedOnPrecedence(markets[marketId].books);
    if (type === "team" && marketOutcomeType === OUTCOME_TYPE_COVER_YES) {
        marketData.mainLine = 0.5;
        marketData.lines = {
            '0.5': {
                cover: getPreferredOdds(userAttributes, bookOutcomes?.cover?.current?.odds, true),
                over: null,
                under: null
            }
        }
    }

    const outcomesToCheck = ['cover', 'over', 'under']
    for (const outcome of outcomesToCheck) {
        if (!(outcome in bookOutcomes)) {
            console.log("No outcome for " + outcome)
            continue;
        }
        const mainLine = bookOutcomes[outcome]?.current?.value;
        if (!mainLine) {
            continue;
        }
        marketData.mainLine = mainLine

        setMarketDataLine(userAttributes, mainLine, marketData, outcome, bookOutcomes[outcome].current)
        if (!isDictEmpty(bookOutcomes[outcome].alternates)) {
            Object.keys(bookOutcomes[outcome].alternates).forEach((line) => setMarketDataLine(userAttributes, line, marketData, outcome, bookOutcomes[outcome].alternates[line]))
        }
    }
    
    return marketData;
}