import React from 'react';
import './fonts.css';
import './generic.css';
import './404.css';
import HtmlHeaders from './html-headers';

class Support extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
        <>
          <HtmlHeaders canonicalRef={`https://www.linemate.io/support`}/>
            <p class="font size-18 grey full-width align-center display-block" style={{marginTop: '10%'}}>For assistance with your account or any inquiries about our services, please contact us by email at support@linemate.io</p>
        </>
    );
  }
}

export default Support;
