import React from 'react';

import './Login.scss'
import '../components/App.scss'
import GlobalContext from '../components/GlobalContext';
import Button from '../components/Button';
import HtmlHeaders from '../html-headers';
import { auth } from '../firebase.js';

class Login extends React.Component {

    static contextType = GlobalContext;

    constructor(props, context) {
        // https://github.com/facebook/react/issues/13944
        // Looks like this API is being deprecated but it doesn't look like there is any official way of doing it in the new API
        super(props, context);

        const searchParams = new URLSearchParams(window.location.search);

        this.defaultOrigin = `/${context.configuration.metadata.defaultLeague}`
        this.origin = this.defaultOrigin;
        if (searchParams.has("origin")) {
            this.origin = searchParams.get("origin");
        }

        if (context.user) {
            this.redirectUser(context.user);    
        }

        this.loginEmailRef = React.createRef();
        this.loginPasswordRef = React.createRef();

        this.inputOnKeyUp = this.inputOnKeyUp.bind(this);
        this.login = this.login.bind(this);

        this.state = {
            formFilled: false,
            loginErrorMessage: null,
            loginInProgress: false
        }
    }

    componentDidMount() {
        document.title = 'Login - Linemate';
    }

    redirectUser(user) {
        // if (this.checkout !== "") {
        //     user.getIdToken().then((token) => { 
        //         const requestInfo = {
        //             headers: {
        //                 'Authorization': "Bearer " + token
        //             }
        //         };
        //         fetch(`${API_HOST}/api/payment/v1/checkout?id=${this.checkout}&origin=${this.origin}`, requestInfo).then(data => {return data.json();})
        //         .then(result => {
        //             window.location.href = result.redirect;
        //         })
        //         .catch(error => {
        //             console.log("Error getting checkout session: " + error);
        //         });
        //     });
        // } else {
            if (!this.origin.startsWith('/')) {
                window.open(this.origin, '_blank')
                window.location.href = this.defaultOrigin;
            } else {
                window.location.href = this.origin;
            }
        // }
    }

    inputOnKeyUp(event) {
        event.preventDefault();
        const email = this.loginEmailRef.current.value || "";
        const password = this.loginPasswordRef.current.value || "";
        if (email !== null && email !== "" && password !== null && password !== "") {
            if (event.keyCode === 13) {
                this.setState({loginErrorMessage: null}, this.login())
            } else {
                this.setState({formFilled: true})
            }
        } else {
            this.setState({formFilled: false})
        }
    }

    login() {
        const email = this.loginEmailRef.current.value;
        const password = this.loginPasswordRef.current.value;
        this.setState({loginInProgress: true}, () => {
            auth.signInWithEmailAndPassword(email, password)
            .then((userCredentials) => {
                this.redirectUser(userCredentials.user);
            })
            .catch((error) => {
                var errorCode = error.code;
                console.log(errorCode);
                switch (errorCode) {
                    case 'auth/user-not-found':
                    case 'auth/wrong-password':
                    case 'auth/invalid-email':
                        this.setState({loginInProgress: false, loginErrorMessage: "That email and password combination is incorrect."})
                        break;
                    default:
                        this.setState({loginInProgress: false, loginErrorMessage: "Something went wrong, please try again later."})
                        break;
                }
            });
        })
    }

    render() {
        return (
            <>
                <HtmlHeaders canonicalRef="https://www.linemate.io/login"/>
                <div className='login-page-container'>
                    <a href='/'>
                        <img src='assets/linemate-logo.svg' alt="linemate-logo"/>
                    </a>
                    <p className='text-style-h-1-bold login-page-title'>Log in to Linemate</p>
                    <div className='login-page-no-account-signup'>
                        <p className='text-style-body-normal'>Don't have an account?</p>
                        <a className='text-style-body-normal' href={`/signup?origin=${this.origin}`}>Sign up</a>
                    </div>
                    <div className='login-page-form'>
                        {
                            this.state.loginErrorMessage && (
                                <p className='text-style-caption-normal login-page-error'>{this.state.loginErrorMessage}</p>
                            )
                        }
                        <p className='text-style-label-medium login-page-email'>Email</p>
                        <input ref={this.loginEmailRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} type="email"/>
                        <p className='text-style-label-medium login-page-password'>Password</p>
                        <input ref={this.loginPasswordRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} type="password"/>
                        <a className='text-style-label-normal' href='/password-reset'>Forgot password?</a>
                        <div className='login-page-login-button-wrapper'>
                            <Button text="Log in" typography="lg" type="primary" enabled={this.state.formFilled && !this.state.loginInProgress} onClick={() => this.inputOnKeyUp({preventDefault: () => {}, keyCode: 13})}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Login;