import React from 'react';
import {validateEmail, isAlphaNumeric, containsNumber, containsUppercase, containsLowercase, stringPresent, isFieldEmpty} from './linemate-react-common/src/util.js';
import {DEFAULT_ORIGIN_REDIRECT} from './linemate-react-common/src/constants.js';
import {auth} from './firebase.js';

import './fonts.css';
import './generic.css';
import './authentication-page.css';
import HtmlHeaders from './html-headers.js';

class PasswordResetPage extends React.Component {
  constructor(props) {
    super(props);

    this.emailRef = React.createRef();
    this.invalidEmailMessageRef = React.createRef();
    this.submitButtonEnabledRef = React.createRef();
    this.submitButtonDisabledRef = React.createRef();
    this.resetButtonRef = React.createRef();
    this.instructionsMessageRef = React.createRef();
    this.completionMessageRef = React.createRef();
    this.genericErrorMessageRef = React.createRef();

    this.redirectUser = this.redirectUser.bind(this);
    this.submitReset = this.submitReset.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
    this.resetButtonClicked = this.resetButtonClicked.bind(this);
  }

  componentDidMount() {
    document.title = 'Password Reset - Linemate';
    // Used to remove the pseudo element :before which pushes the content down in most pages to allow for the navbar
    // In this case we don't use the navbar so we don't want it
    document.body.classList.remove('push');

    if (this.props.currentUser) {
        this.redirectUser();    
    }

    // TODO: remove this like in authentication page and use the props
    // // If they are already logged in they have no business in the authentication page, redirect to another page
    auth.onAuthStateChanged(user => {
        if (user) {
            this.redirectUser();
        }
    });
  }

  componentDidUpdate() {
    if (this.props.user) {
        this.redirectUser();
    }
  }

  redirectUser() {
    window.location.href = DEFAULT_ORIGIN_REDIRECT;
  }

  submitReset() {
    const email = this.emailRef.current.value || "";
    this.genericErrorMessageRef.current.style.display = 'none';
    if (!validateEmail(email) || isFieldEmpty(email)) {
        this.invalidEmailMessageRef.current.style.display = 'block';
    } else {
        this.invalidEmailMessageRef.current.style.display = 'none';
        // Send reset email
        auth.sendPasswordResetEmail(email)
        .then(() => {
            this.resetButtonRef.current.style.display = 'block';
            this.submitButtonEnabledRef.current.style.display = 'none';
            this.submitButtonDisabledRef.current.style.display = 'none';

            this.instructionsMessageRef.current.style.display = 'none';
            this.completionMessageRef.current.style.display = 'block';

            this.emailRef.current.style.display = 'none';
        })
        .catch((error) => {
            this.genericErrorMessageRef.current.style.display = 'block';
        });
    }
  }

  handleInputKeyUp(event) {
    event.preventDefault();
    const email = this.emailRef.current.value || "";
    if (stringPresent(email)) {
        this.submitButtonEnabledRef.current.style.display = 'block';
        this.submitButtonDisabledRef.current.style.display = 'none';
        if (event.keyCode === 13) {
            this.submitButtonEnabledRef.current.click();
        }
    } else {
        this.submitButtonEnabledRef.current.style.display = 'none';
        this.submitButtonDisabledRef.current.style.display = 'block';
    }
  }

  resetButtonClicked() {
    this.resetButtonRef.current.style.display = 'none';
    this.submitButtonEnabledRef.current.style.display = 'none';
    this.submitButtonDisabledRef.current.style.display = 'block';

    this.instructionsMessageRef.current.style.display = 'block';
    this.completionMessageRef.current.style.display = 'none';

    this.emailRef.current.style.display = 'block';
    this.emailRef.current.value = "";
  }

  render() {
    return(
        <>
            <HtmlHeaders canonicalRef="https://www.linemate.io/password-reset"/>
            <img class="authentication-hero-image absolute" src="assets/authentication/hero-big.jpg" alt=""/>
            <div class="authentication-hero">
                <a href="https://linemate.io">
                    <img src="assets/logo-white-nobg.svg" alt=""/>
                </a>
                <div class="authentication-full-header">
                    <p class="font white size-64 weight-800 spaced-negative-low" style={{marginTop: "20%"}} >Start your research with Linemate.</p>
                </div>
                <div class="authentication-mobile-header">
                    <p class="font white size-30 weight-700 spaced-negative-low" style={{marginTop: "20%"}} >Start your research with Linemate.</p>
                </div>
            </div>
            <div class="authentication-content">
                <p class="font size-36 weight-700 dark full-width align-center" style={{marginBottom: '24px', display: 'flex'}}>Reset your password</p>
                <p ref={this.instructionsMessageRef} class="font size-16 dark full-width">Enter the email address associated with your account and we'll send you a link to reset your password.</p>
                <p ref={this.completionMessageRef} class="font size-16 dark full-width" style={{display: 'none'}}>You will receive a password reset email soon. Follow the link in the email to reset your password.</p>
                <div class="authentication-content-login" >
                    <input onKeyUp={this.handleInputKeyUp} ref={this.emailRef} type="email" class="generic-input" placeholder="Email address"/>
                    <p ref={this.invalidEmailMessageRef} class="font size-14 red" style={{display: 'none'}}>Invalid email.</p>
                    <p ref={this.genericErrorMessageRef} class="font size-14 red" style={{display: 'none'}}>Something went wrong while sending the password reset instructions, please try again later.</p>
                    <button ref={this.submitButtonEnabledRef} type="button" class="generic-button" onClick={this.submitReset} style={{display: 'none', marginTop: '32px'}}>
                        <p class="font white size-14">Send reset instructions</p>
                    </button>
                    <button ref={this.submitButtonDisabledRef} type="button" disabled class="generic-button generic-button-disabled" style={{marginTop: '32px'}}>
                        <p class="font white size-14">Send reset instructions</p>
                    </button>
                    <button ref={this.resetButtonRef} type="button" class="generic-button-white" onClick={this.resetButtonClicked} style={{display: 'none', marginTop: '32px'}}>
                        <p class="font dark size-14">Resend reset instructions</p>
                    </button>
                    <a href="/login" class="font size-16 green full-width align-center display-block" style={{marginTop: '20px'}}>Return to log in</a>
                </div>
            </div>
        </>
    );
  }
}

export default PasswordResetPage;
