export const API_HOST = process.env.REACT_APP_API_HOST;
export const LOGOS_ASSETS_BASE = 'assets/logos';

export const IOS_DOWNLOAD_URL = "https://apps.apple.com/us/app/linemate/id1635246793";
export const ANDROID_DOWNLOAD_URL = "https://play.google.com/store/apps/details?id=com.linemate";

export const DOWNLOAD_URLS = {
    ios: {
        default: IOS_DOWNLOAD_URL,
        'twitter-default': "https://apps.apple.com/app/apple-store/id1635246793?pt=125237472&ct=twitter-default&mt=8"
    },
    android: {
        default: ANDROID_DOWNLOAD_URL,
        'twitter-default': "https://play.google.com/store/apps/details?id=com.linemate&referrer=utm_source%3Dtwitter-default"
    }
}
export const RESPONSIVE_BAR_THEME = {
    "grid": {
        "line": {
            "stroke": "var(--color-border-default)",
            "strokeWidth": 1
        }
    },
    "axis": {
        "ticks": {
            "line": {
                "strokeWidth": 0
            },
            "text": {
                "fontSize": 12,
                "fontFamily": "Inter",
                "fill": "var(--color-fig-subtle)"
            }
        }
    },
    "legends": {
        "text": {
            "fontSize": 12,
            "fontFamily": "Inter",
            "fill": "var(--color-fig-default)"
        }
    },
    "tooltip": {
        "wrapper": {},
        "container": {
            "background": "var(--color-surface-default)",
            "color": "var(--color-fig-subtle)",
            "fontSize": 12,
            "fontFamily": "Inter"
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    }
}
