import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import GlobalContext from './GlobalContext';

import './HitRateChart.scss'
import './App.scss'
import { RESPONSIVE_BAR_THEME } from '../react-web-constants';

/**
 * Props
 * - graphData: []
 * - graphLegendKeys: []
 * - line: number
 */
class HitRateChart extends React.Component {

    static contextType = GlobalContext;

    render() {
        return (
            <div className='hit-rate-chart-wrapper'>
                <ResponsiveBar
                    theme={RESPONSIVE_BAR_THEME}
                    data={this.props.graphData}
                    keys={this.props.graphLegendKeys}
                    indexBy="key"
                    margin={{ top: 60, right: 37.5, bottom: 25, left: 50 }}
                    padding={0.35}
                    innerPadding={10}
                    groupMode="grouped"
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    valueFormat={{ format: '', enabled: false }}
                    colors={({ id, data }) => String(data[`color`])}
                    defs={[]}
                    fill={[]}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    enableLabel={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'top-right',
                            direction: 'row',
                            justify: false,
                            translateX: 30,
                            translateY: -50,
                            itemsSpacing: 4,
                            itemWidth: 100,
                            itemHeight: 32,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 12,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    markers={[
                        {
                            axis: 'y',
                            value: this.props.line,
                            lineStyle: { stroke: 'var(--color-opposite-border-default)', strokeWidth: 2 }
                        },
                    ]}
                    motionConfig={{ mass: 1, tension: 170, friction: 26, clamp: false, precision: 0.01, velocity: 0 }}
                />
            </div>
        );
    }
}

export default HitRateChart;