import React from 'react';

import './dropdown.css';
import './fonts.css';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.rankColumnRef = React.createRef();
    this.dropdownContainerRef = React.createRef();
    this.multiSelectionButtonsRef = React.createRef();
    this.props.clickableElementAddition(this.multiSelectionButtonsRef);
    this.clickableElements = {};
    for (const option of this.props.options) {
      this.clickableElements[option] = React.createRef();
      this.props.clickableElementAddition(this.clickableElements[option]);
    }
    const type = this.props.type || "singleSelection";
    if (type === "multipleSelection") {
      this.clickableElements["ALL"] = React.createRef();
      this.clickableElements["CLEAR"] = React.createRef();
      this.clickableElements["APPLY"] = React.createRef();
      this.props.clickableElementAddition(this.clickableElements["ALL"]);
      this.props.clickableElementAddition(this.clickableElements["CLEAR"]);
      this.props.clickableElementAddition(this.clickableElements["APPLY"]);
      this.checkedItems = this.props.currentSelections || [];
      if (this.checkedItems.indexOf("ALL") !== -1) {
        this.checkedItems = ["ALL"].concat(this.props.options);
      }
    }

    
    this.handleGeneralClick = this.handleGeneralClick.bind(this);
    this.saveCheckboxSelection = this.saveCheckboxSelection.bind(this);
    this.clearCheckboxSelection = this.clearCheckboxSelection.bind(this);
    this.applyCheckboxSelection = this.applyCheckboxSelection.bind(this);
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleGeneralClick);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleGeneralClick);
  }

  // Necessary for dropdowns where the data is dynamic, meaning the constructor won't be sufficient if the options are provided after it's been created
  componentDidUpdate() {
    // !! TODO: need to review adding new clickable elements, it might increase the list of clickable elements way too much !!
    for (const option of this.props.options) {
      // Uncommenting the condition below messes up with certain dropdowns which are not originally visible such as the player dropdown for player screener
      // if (!option in this.clickableElements) {
        this.clickableElements[option] = React.createRef();
        this.props.clickableElementAddition(this.clickableElements[option]); 
      // }
    }
  }

  handleGeneralClick(event) {
    const type = this.props.type || "singleSelection";
    if (type === "multipleSelection" && !this.dropdownContainerRef.current.contains(event.target)) {
      this.checkedItems = this.props.currentSelections || [];
      if (this.checkedItems.indexOf("ALL") !== -1) {
        this.checkedItems = ["ALL"].concat(this.props.options);
      }
      this.forceUpdate();
    }
  }

  clearCheckboxSelection() {
    this.checkedItems = [];
    this.forceUpdate();
  }

  saveCheckboxSelection(event) {
    // console.log("Checkbox selection");
    // console.log(`Value: ${event.currentTarget.value}`);
    // console.log(`Checked: ${event.currentTarget.checked}`);
    if (event.currentTarget.checked) {
      if (event.currentTarget.value === "ALL") {
        this.checkedItems = ["ALL"].concat(this.props.options);
      } else {
        this.checkedItems.push(event.currentTarget.value);
        if (this.checkedItems.length === this.props.options.length) {
          // All items have been manually checked -- also select the "ALL" option
          this.checkedItems.push("ALL");
        }
      }
    } else {
      if (event.currentTarget.value === "ALL") {
        this.checkedItems = [];
      } else {
        this.checkedItems = this.checkedItems.filter(item => item !== event.currentTarget.value && item !== "ALL");
      }
    }
    // Need to re-render since we changed the selection -- alternatively could just set it in the state instead
    this.forceUpdate();
  }

  applyCheckboxSelection(event) {
    // console.log("Sending the following items up to parent: ", this.checkedItems);
    this.props.onClickHandler(event, this.checkedItems);
  }

  render() {
    var containerStyling = Object.assign({}, this.props.customContainerStyling || {});
    const elementStyling = Object.assign({}, this.props.customElementStyling || {});
    const type = this.props.type || "singleSelection";
    if (!this.props.enabled) {
      containerStyling['display'] = 'none';
    }
    if (type === "singleSelection") {
      return(
        <div class="dropdown-container" style={containerStyling}>
          <ul class="no-styling dropdown-list">
          {
            this.props.options.map((key,index) =>
              <li key={key}>
                <span class=
                  {
                    this.props.currentSelection === key ?
                    "dropdown-list-element selected-dropdown-list-element clickable" :
                    "dropdown-list-element clickable"
                  }
                  ref={this.clickableElements[key]}
                  data-name={this.props.name}
                  data-selection={key}
                  data-selection-index={index}
                  onClick={this.props.onClickHandler}
                  style={elementStyling}>
                  <p class={this.props.textStylingClasses + (this.props.currentSelection === key ? " font white" : " font dark")}>{key}</p>
                </span>
              </li>
            )
          }
          </ul>
        </div>
      );
    } else if (type === "multipleSelection") {
      return(
        <div ref={this.dropdownContainerRef} class="dropdown-container" style={Object.assign({maxHeight: 'none', height: '250px', overflowY: 'hidden'}, containerStyling)}>
          <div class="no-styling dropdown-list" style={{height: '186px', overflowY: 'scroll'}}>
              <span class="dropdown-list-element clickable"
                ref={this.clickableElements["ALL"]}
                data-name={this.props.name}
                data-selection={"ALL"}
                data-selection-index={-1}
                // onClick={this.props.onClickHandler}
                style={elementStyling}>
                <input type="checkbox" class="checkbox" value="ALL" style={{padding: '9px'}} 
                      onClick={this.saveCheckboxSelection} checked={this.checkedItems.indexOf("ALL") !== -1}/>
                <p class={this.props.textStylingClasses + " font dark"}>ALL</p>
              </span>
          {
            this.props.options.map((key,index) =>
                <span key={key} class="dropdown-list-element"
                  ref={this.clickableElements[key]}
                  data-name={this.props.name}
                  data-selection={key}
                  data-selection-index={index}
                  // onClick={this.props.onClickHandler}
                  style={elementStyling}>
                  <input type="checkbox" class="checkbox" value={key} style={{padding: '9px'}} 
                          onClick={this.saveCheckboxSelection} checked={this.checkedItems.indexOf(key) !== -1}/>
                  <p class={this.props.textStylingClasses + (this.props.currentSelection === key ? " font white" : " font dark")}>{key}</p>
                </span>
            )
          }
          </div>
          <div ref={this.multiSelectionButtonsRef} class="dropdown-list-element" style={Object.assign(containerStyling, {position: 'relative', borderTop: '2px solid #D1D5DB', height: '64px', paddingBottom: '12px', paddingTop: '12px', height: '64px', left: '0', top: '0'})}>
            <p class={this.props.textStylingClasses + " font weight-600 green clickable unselectable"} onClick={this.clearCheckboxSelection} 
                style={{width: '50%', textAlign: 'center', marginTop: '9px', marginLeft: '-5px', display: 'inline-block'}}>
                  Clear
            </p>
            <button onClick={this.applyCheckboxSelection} data-name={this.props.name} data-selection={this.checkedItems} type="button" 
                    class="generic-button" style={{width: '50%', height: '36px', display: 'inline-block', marginTop: '0'}}>
              <p class="font white size-14" style={{width: '100%', textAlign: 'center', display: 'block'}}>Apply</p>
            </button>
          </div>
        </div>
      );
    }
 
  }
}

export default Dropdown;
