import React from 'react';

import './fonts.css';
import './stats-legend.css';

class StatsLegend extends React.Component {
  render() {
    return(
      <div class="stats-legend-container">
        <div class="stats-legend-header">
          <p class="vertically-centered font size-18 dark bold">Legend</p>
          <span class="clickable legend" onClick={this.props.legendCloseHandler} style={{float: 'right'}}>
            <img src="assets/close-icon-grey.svg" alt=""/>
          </span>
        </div>
        <div class="stats-legend-content">
          <p class="font size-12 spaced dark">{this.props.category.toUpperCase()} STATS</p><br></br>
          {
            Object.keys(this.props.legendItems).map((key, index) => (
              <>
              <span key={key}><p class="font size-14 dark spaced bold">{key}:</p><p class="font size-14 grey"> {this.props.legendItems[key]}</p></span>
              </>
            ))
          }
          <div style={{height: '0', clear: 'both'}}></div>
        </div>
        <div class="stats-legend-footer">
          <div class="stats-legend-close-button">
            <span class="clickable" style={{height: '100%', width: '100%', display: 'inline-block', color: 'inherit', textDecoration: 'none'}} onClick={this.props.legendCloseHandler}>
              <div class="vertically-centered horizontally-centered">
                <p class="font size-14 dark bold">Close</p>
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsLegend;
