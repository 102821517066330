import React from 'react';
import {parseDateFromString, isDictEmpty, getTeamCityDisplayName} from './linemate-react-common/src/util.js';

import './fonts.css';
import './generic.css';
import './playbook.css';
import './buttons.css';
import './game-preview-banner.css';

import { getLeagueLogoPath, getTeamLogoPath } from './react-web-utils.js';



class GamePreviewBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="current-matchup">
                {
                    this.props.type === "individual" ? 
                    <>
                    {
                        isDictEmpty(this.props.game) ? <></> :
                        <>
                            <div className="away-team">
                                <div className="away-city">
                                    <span className="full-text text-xl-semi">{getTeamCityDisplayName(this.props.activeLeague, this.props.game.awayTeamData.info)}</span>
                                    <span className="short-text text-base-upper">{this.props.game.awayTeamData.info.code}</span>
                                </div>
                                <img src={getTeamLogoPath(this.props.activeLeague, this.props.game.awayTeamData.info.code)} height={80} width={80} alt={`${this.props.game.awayTeamData.info.name} ${this.props.game.awayTeamData.info.name}`}/>
                            </div>
                            <div className="match-details">
                                <div className="match-date text-sm-upper">{`${parseDateFromString(this.props.game.timestamp).formattedDayMonthShort}, ${parseDateFromString(this.props.game.timestamp).year}`}</div>
                                <div className="match-start-time text-sm-upper">{`${parseDateFromString(this.props.game.timestamp).formattedTime}`}</div>
                                <div className="match-venue">
                                    <span className="full-text text-sm">{`${this.props.game.venue.name}, ${this.props.game.venue.city}`}</span>
                                    <span className="short-text text-xs">{this.props.game.venue.name} <br/> {this.props.game.venue.city}</span>
                                </div>
                            </div>
                            <div className="home-team">
                                <img src={getTeamLogoPath(this.props.activeLeague, this.props.game.homeTeamData.info.code)} height={80} width={80} alt={`${this.props.game.homeTeamData.info.name} ${this.props.game.homeTeamData.info.name}`}/>
                                <div className="home-city text-xl-semi">
                                    <span className="full-text text-xl-semi">{getTeamCityDisplayName(this.props.activeLeague, this.props.game.homeTeamData.info)}</span>
                                    <span className="short-text text-base-upper">{this.props.game.homeTeamData.info.code}</span>
                                </div>
                            </div>
                        </>
                    }
                    </>
                    :
                    // All games
                    <div className="game-preview-banner-all-games">
                        <img src={getLeagueLogoPath(this.props.activeLeague)} alt={this.props.activeLeague}/>
                        <div>
                            <p className="font size-30 weight-600">All Games</p>
                            <p className="font size-20">{this.props.allGamesDateText}</p>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default GamePreviewBanner;