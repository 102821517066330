import React from 'react';
import { DEFAULT_ACTIVE_LEAGUE, DEFAULT_ORIGIN_REDIRECT } from './linemate-react-common/src/constants.js';
import './fonts.css';
import './generic.css';
import './404.css';

class NotFound extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
        <>
            <img src="assets/404.svg" class="not-found-image" alt=""/>
            <p class="font size-44 weight-700 dark spaced-negative-low full-width align-center display-block" style={{marginTop: '48px'}}>Page not found</p>
            <p class="font size-18 grey full-width align-center display-block" style={{marginTop: '24px'}}>The page you are looking for doesn't exist.</p>
            <p class="full-width align-center display-block not-found-links" style={{marginTop: '28px'}}>
                <a href={DEFAULT_ORIGIN_REDIRECT} class="font size-16 green">Go to Gameday</a>&emsp;&emsp;
                <a href={`/${DEFAULT_ACTIVE_LEAGUE}/playbook`} class="font size-16 green">Go to Playbook</a>&emsp;&emsp;
                <a href="mailto:support@linemate.io" class="font size-16 green">Contact Us<img src="assets/contact-icon-green.svg" height={24} width={24} style={{paddingBottom: '4px'}} alt=""/></a>
            </p>
        </>
    );
  }
}

export default NotFound;
