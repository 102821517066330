import React from 'react';
import './fonts.css';
import './generic.css';
import './no-results-frame.css';


class InformationContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
        <>
            <div class="no-results-container">
                <img class="no-results-icon" src={this.props.imageSource} height={64} width={64} alt=""/>
                <p class="no-results-text font size-16 color-placeholder">{this.props.text || ""}</p>
            </div>
        </>
    );
  }
}

export default InformationContainer;
