import React from 'react';

import './Home.scss'
import '../components/App.scss'
import { base64Encode, buildQueryParams, buildTeamsMap, hasPremiumAccess, isSoccer, isWeeklyCompetition, parseDate } from '../linemate-react-common/src/util';
import { getAllTeams, getGamedaySchedule } from '../linemate-react-common/src/gameday-utils';
import { API_HOST } from '../react-web-constants';

import MatchupCard from '../components/MatchupCard';
import GlobalContext from '../components/GlobalContext';
import { DISCOVERY_LEADERS, SCHEDULE_FORMAT_WEEKLY, SUPPORTED_LEAGUES_METADATA } from '../linemate-react-common/src/constants';
import AffiliatesList from '../components/AffiliatesList';

// Adding the raw svg here instead of an asset is a workaround to be able to use css variables since we have more than one color in the svg that needs changing
// Typically when we have a single color to change on the svg we set it to black and then apply a filter but that doesn't work here
// It's a bit messy but allows for more customization and that way we don't need to use png/jpg with 2 variations, one for dark and one for light
//  which also doesn't work well if switching theme on the spot
const FIND_YOUR_BET_SHORTCUTS = [
  {
    icon: (
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.5003 22.666C25.5003 24.8752 21.3216 26.666 16.167 26.666C11.0123 26.666 6.83366 24.8752 6.83366 22.666C6.83366 20.4569 11.0123 18.666 16.167 18.666C21.3216 18.666 25.5003 20.4569 25.5003 22.666Z" fill="var(--color-icon-1)"/>
        <path d="M5.50049 24.0026V22.9359C5.50049 22.1804 5.69493 21.4859 6.08382 20.8526C6.47271 20.2193 6.98938 19.7359 7.63382 19.4026C9.0116 18.7137 10.4116 18.197 11.8338 17.8526C13.256 17.5082 14.7005 17.3359 16.1672 17.3359C17.6338 17.3359 19.0783 17.5082 20.5005 17.8526C21.9227 18.197 23.3227 18.7137 24.7005 19.4026C25.3449 19.7359 25.8616 20.2193 26.2505 20.8526C26.6394 21.4859 26.8338 22.1804 26.8338 22.9359V24.0026C26.8338 24.7359 26.5727 25.3637 26.0505 25.8859C25.5283 26.4082 24.9005 26.6693 24.1672 26.6693H8.16716C7.43382 26.6693 6.80604 26.4082 6.28382 25.8859C5.7616 25.3637 5.50049 24.7359 5.50049 24.0026ZM8.16716 24.0026H24.1672V22.9359C24.1672 22.6915 24.106 22.4693 23.9838 22.2693C23.8616 22.0693 23.7005 21.9137 23.5005 21.8026C22.3005 21.2026 21.0894 20.7526 19.8672 20.4526C18.6449 20.1526 17.4116 20.0026 16.1672 20.0026C14.9227 20.0026 13.6894 20.1526 12.4672 20.4526C11.2449 20.7526 10.0338 21.2026 8.83382 21.8026C8.63382 21.9137 8.47271 22.0693 8.35049 22.2693C8.22827 22.4693 8.16716 22.6915 8.16716 22.9359V24.0026Z" fill="var(--color-icon-1)"/>
        <path d="M16.1668 16.0026C14.7002 16.0026 13.4446 15.4804 12.4002 14.4359C11.3557 13.3915 10.8335 12.1359 10.8335 10.6693C10.8335 9.2026 11.3557 7.94705 12.4002 6.9026C13.4446 5.85816 14.7002 5.33594 16.1668 5.33594C17.6335 5.33594 18.8891 5.85816 19.9335 6.9026C20.9779 7.94705 21.5002 9.2026 21.5002 10.6693C21.5002 12.1359 20.9779 13.3915 19.9335 14.4359C18.8891 15.4804 17.6335 16.0026 16.1668 16.0026ZM16.1668 13.3359C16.9002 13.3359 17.5279 13.0748 18.0502 12.5526C18.5724 12.0304 18.8335 11.4026 18.8335 10.6693C18.8335 9.93594 18.5724 9.30816 18.0502 8.78594C17.5279 8.26372 16.9002 8.0026 16.1668 8.0026C15.4335 8.0026 14.8057 8.26372 14.2835 8.78594C13.7613 9.30816 13.5002 9.93594 13.5002 10.6693C13.5002 11.4026 13.7613 12.0304 14.2835 12.5526C14.8057 13.0748 15.4335 13.3359 16.1668 13.3359Z" fill="var(--color-icon-2)"/>
      </svg>
    ),
    title: 'Player Trends',
    href: (league) => `/${league}/trends?tabs=${encodeURIComponent(JSON.stringify(['player']))}`
  },
  {
    icon: (
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2383_13132)">
          <path d="M1.50033 24C1.12255 24 0.805881 23.8722 0.550326 23.6167C0.29477 23.3611 0.166992 23.0444 0.166992 22.6667V21.9C0.166992 20.9444 0.655881 20.1667 1.63366 19.5667C2.61144 18.9667 3.90033 18.6667 5.50033 18.6667C5.78921 18.6667 6.06699 18.6722 6.33366 18.6833C6.60033 18.6944 6.85588 18.7222 7.10033 18.7667C6.78922 19.2333 6.55588 19.7222 6.40033 20.2333C6.24477 20.7444 6.16699 21.2778 6.16699 21.8333V24H1.50033ZM26.167 24V21.8333C26.167 21.2556 26.0948 20.7111 25.9503 20.2C25.8059 19.6889 25.5892 19.2111 25.3003 18.7667C25.5448 18.7222 25.7948 18.6944 26.0503 18.6833C26.3059 18.6722 26.567 18.6667 26.8337 18.6667C28.4337 18.6667 29.7225 18.9611 30.7003 19.55C31.6781 20.1389 32.167 20.9222 32.167 21.9V22.6667C32.167 23.0444 32.0392 23.3611 31.7837 23.6167C31.5281 23.8722 31.2114 24 30.8337 24H26.167ZM5.50033 17.3333C4.76699 17.3333 4.13921 17.0722 3.61699 16.55C3.09477 16.0278 2.83366 15.4 2.83366 14.6667C2.83366 13.9111 3.09477 13.2778 3.61699 12.7667C4.13921 12.2556 4.76699 12 5.50033 12C6.25588 12 6.88921 12.2556 7.40033 12.7667C7.91144 13.2778 8.16699 13.9111 8.16699 14.6667C8.16699 15.4 7.91144 16.0278 7.40033 16.55C6.88921 17.0722 6.25588 17.3333 5.50033 17.3333ZM26.8337 17.3333C26.1003 17.3333 25.4725 17.0722 24.9503 16.55C24.4281 16.0278 24.167 15.4 24.167 14.6667C24.167 13.9111 24.4281 13.2778 24.9503 12.7667C25.4725 12.2556 26.1003 12 26.8337 12C27.5892 12 28.2225 12.2556 28.7337 12.7667C29.2448 13.2778 29.5003 13.9111 29.5003 14.6667C29.5003 15.4 29.2448 16.0278 28.7337 16.55C28.2225 17.0722 27.5892 17.3333 26.8337 17.3333Z" fill="var(--color-icon-1)"/>
          <path d="M9.50033 24C9.12255 24 8.80588 23.8722 8.55033 23.6167C8.29477 23.3611 8.16699 23.0444 8.16699 22.6667V21.8333C8.16699 21.1222 8.36144 20.4722 8.75033 19.8833C9.13921 19.2944 9.68921 18.7778 10.4003 18.3333C11.1114 17.8889 11.9614 17.5556 12.9503 17.3333C13.9392 17.1111 15.0114 17 16.167 17C17.3448 17 18.4281 17.1111 19.417 17.3333C20.4059 17.5556 21.2559 17.8889 21.967 18.3333C22.6781 18.7778 23.2225 19.2944 23.6003 19.8833C23.9781 20.4722 24.167 21.1222 24.167 21.8333V22.6667C24.167 23.0444 24.0392 23.3611 23.7837 23.6167C23.5281 23.8722 23.2114 24 22.8337 24H9.50033ZM11.0003 21.3333H21.367C21.1448 20.8889 20.5281 20.5 19.517 20.1667C18.5059 19.8333 17.3892 19.6667 16.167 19.6667C14.9448 19.6667 13.8281 19.8333 12.817 20.1667C11.8059 20.5 11.2003 20.8889 11.0003 21.3333ZM16.167 16C15.0559 16 14.1114 15.6111 13.3337 14.8333C12.5559 14.0556 12.167 13.1111 12.167 12C12.167 10.8667 12.5559 9.91667 13.3337 9.15C14.1114 8.38333 15.0559 8 16.167 8C17.3003 8 18.2503 8.38333 19.017 9.15C19.7837 9.91667 20.167 10.8667 20.167 12C20.167 13.1111 19.7837 14.0556 19.017 14.8333C18.2503 15.6111 17.3003 16 16.167 16ZM16.167 13.3333C16.5448 13.3333 16.8614 13.2056 17.117 12.95C17.3725 12.6944 17.5003 12.3778 17.5003 12C17.5003 11.6222 17.3725 11.3056 17.117 11.05C16.8614 10.7944 16.5448 10.6667 16.167 10.6667C15.7892 10.6667 15.4725 10.7944 15.217 11.05C14.9614 11.3056 14.8337 11.6222 14.8337 12C14.8337 12.3778 14.9614 12.6944 15.217 12.95C15.4725 13.2056 15.7892 13.3333 16.167 13.3333Z" fill="var(--color-icon-2)"/>
        </g>
        <defs>
          <clipPath id="clip0_2383_13132">
            <rect width="32" height="32" fill="white" transform="translate(0.166992)"/>
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Team Trends',
    href: (league) => `/${league}/trends?tabs=${encodeURIComponent(JSON.stringify(['team']))}`
  },
  {
    icon: (
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2383_13135)">
          <path d="M10.8341 16.0026C9.36748 16.0026 8.11193 15.4804 7.06748 14.4359C6.02304 13.3915 5.50081 12.1359 5.50081 10.6693C5.50081 9.2026 6.02304 7.94705 7.06748 6.9026C8.11193 5.85816 9.36748 5.33594 10.8341 5.33594C12.3008 5.33594 13.5564 5.85816 14.6008 6.9026C15.6453 7.94705 16.1675 9.2026 16.1675 10.6693C16.1675 12.1359 15.6453 13.3915 14.6008 14.4359C13.5564 15.4804 12.3008 16.0026 10.8341 16.0026ZM0.16748 24.0026V22.9359C0.16748 22.1804 0.361925 21.4859 0.750814 20.8526C1.1397 20.2193 1.65637 19.7359 2.30081 19.4026C3.67859 18.7137 5.07859 18.197 6.50081 17.8526C7.92304 17.5082 9.36748 17.3359 10.8341 17.3359C12.3008 17.3359 13.7453 17.5082 15.1675 17.8526C16.5897 18.197 17.9897 18.7137 19.3675 19.4026C20.0119 19.7359 20.5286 20.2193 20.9175 20.8526C21.3064 21.4859 21.5008 22.1804 21.5008 22.9359V24.0026C21.5008 24.7359 21.2397 25.3637 20.7175 25.8859C20.1953 26.4082 19.5675 26.6693 18.8341 26.6693H2.83415C2.10081 26.6693 1.47304 26.4082 0.950814 25.8859C0.428592 25.3637 0.16748 24.7359 0.16748 24.0026ZM10.8341 13.3359C11.5675 13.3359 12.1953 13.0748 12.7175 12.5526C13.2397 12.0304 13.5008 11.4026 13.5008 10.6693C13.5008 9.93594 13.2397 9.30816 12.7175 8.78594C12.1953 8.26372 11.5675 8.0026 10.8341 8.0026C10.1008 8.0026 9.47304 8.26372 8.95081 8.78594C8.42859 9.30816 8.16748 9.93594 8.16748 10.6693C8.16748 11.4026 8.42859 12.0304 8.95081 12.5526C9.47304 13.0748 10.1008 13.3359 10.8341 13.3359ZM2.83415 24.0026H18.8341V22.9359C18.8341 22.6915 18.773 22.4693 18.6508 22.2693C18.5286 22.0693 18.3675 21.9137 18.1675 21.8026C16.9675 21.2026 15.7564 20.7526 14.5341 20.4526C13.3119 20.1526 12.0786 20.0026 10.8341 20.0026C9.5897 20.0026 8.35637 20.1526 7.13415 20.4526C5.91192 20.7526 4.70081 21.2026 3.50081 21.8026C3.30081 21.9137 3.1397 22.0693 3.01748 22.2693C2.89526 22.4693 2.83415 22.6915 2.83415 22.9359V24.0026Z" fill="var(--color-icon-2)"/>
          <path d="M16.834 15.9376C17.4784 15.2265 17.9729 14.4154 18.3173 13.5043C18.6618 12.5932 18.834 11.6487 18.834 10.671C18.834 9.69319 18.6618 8.74874 18.3173 7.83763C17.9729 6.92652 17.4784 6.11541 16.834 5.4043C18.1673 5.58207 19.2784 6.17096 20.1673 7.17096C21.0562 8.17096 21.5007 9.33763 21.5007 10.671C21.5007 12.0043 21.0562 13.171 20.1673 14.171C19.2784 15.171 18.1673 15.7599 16.834 15.9376ZM23.434 26.671C23.6784 26.271 23.8618 25.8432 23.984 25.3876C24.1062 24.9321 24.1673 24.471 24.1673 24.0043V22.671C24.1673 21.871 23.9895 21.1099 23.634 20.3876C23.2784 19.6654 22.8118 19.0265 22.234 18.471C23.3673 18.871 24.4173 19.3876 25.384 20.021C26.3507 20.6543 26.834 21.5376 26.834 22.671V24.0043C26.834 24.7376 26.5729 25.3654 26.0507 25.8876C25.5284 26.4099 24.9007 26.671 24.1673 26.671H23.434ZM26.834 14.671H25.5007C25.1229 14.671 24.8062 14.5432 24.5507 14.2876C24.2951 14.0321 24.1673 13.7154 24.1673 13.3376C24.1673 12.9599 24.2951 12.6432 24.5507 12.3876C24.8062 12.1321 25.1229 12.0043 25.5007 12.0043H26.834V10.671C26.834 10.2932 26.9618 9.97652 27.2173 9.72096C27.4729 9.46541 27.7895 9.33763 28.1673 9.33763C28.5451 9.33763 28.8618 9.46541 29.1173 9.72096C29.3729 9.97652 29.5007 10.2932 29.5007 10.671V12.0043H30.834C31.2118 12.0043 31.5284 12.1321 31.784 12.3876C32.0395 12.6432 32.1673 12.9599 32.1673 13.3376C32.1673 13.7154 32.0395 14.0321 31.784 14.2876C31.5284 14.5432 31.2118 14.671 30.834 14.671H29.5007V16.0043C29.5007 16.3821 29.3729 16.6987 29.1173 16.9543C28.8618 17.2099 28.5451 17.3376 28.1673 17.3376C27.7895 17.3376 27.4729 17.2099 27.2173 16.9543C26.9618 16.6987 26.834 16.3821 26.834 16.0043V14.671Z" fill="var(--color-icon-1)"/>
        </g>
        <defs>
          <clipPath id="clip0_2383_13135">
            <rect width="32" height="32" fill="white" transform="translate(0.166992)"/>
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Parlay Trends',
    href: (league) => `/${league}/trends?tabs=${encodeURIComponent(JSON.stringify(['parlay']))}`
  },
  {
    icon: (
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8338 28.001V22.6677C14.8338 21.4233 14.6449 20.501 14.2671 19.901C13.8893 19.301 13.3893 18.7122 12.7671 18.1344L14.6671 16.2344C14.9338 16.4788 15.1893 16.7399 15.4338 17.0177C15.6782 17.2955 15.9226 17.5899 16.1671 17.901C16.4782 17.4788 16.7949 17.1066 17.1171 16.7844C17.4393 16.4622 17.7671 16.1455 18.1004 15.8344C18.9449 15.0566 19.7115 14.1566 20.4004 13.1344C21.0893 12.1122 21.456 10.3233 21.5004 7.76771L20.3338 8.93437C20.0893 9.17882 19.7838 9.30104 19.4171 9.30104C19.0504 9.30104 18.7338 9.17882 18.4671 8.93437C18.2004 8.66771 18.0671 8.35104 18.0671 7.98437C18.0671 7.61771 18.2004 7.30104 18.4671 7.03437L21.9004 3.60104C22.0338 3.46771 22.1782 3.37326 22.3338 3.31771C22.4893 3.26215 22.656 3.23438 22.8338 3.23438C23.0115 3.23438 23.1782 3.26215 23.3338 3.31771C23.4893 3.37326 23.6338 3.46771 23.7671 3.60104L27.2338 7.06771C27.4782 7.31215 27.606 7.61771 27.6171 7.98437C27.6282 8.35104 27.5004 8.66771 27.2338 8.93437C26.9893 9.17882 26.6782 9.30104 26.3004 9.30104C25.9226 9.30104 25.6115 9.17882 25.3671 8.93437L24.1671 7.76771C24.1226 10.9455 23.6338 13.2066 22.7004 14.551C21.7671 15.8955 20.8338 16.9899 19.9004 17.8344C19.1893 18.4788 18.6115 19.1066 18.1671 19.7177C17.7226 20.3288 17.5004 21.3122 17.5004 22.6677V28.001C17.5004 28.3788 17.3726 28.6955 17.1171 28.951C16.8615 29.2066 16.5449 29.3344 16.1671 29.3344C15.7893 29.3344 15.4726 29.2066 15.2171 28.951C14.9615 28.6955 14.8338 28.3788 14.8338 28.001Z" fill="var(--color-icon-1)"/>
        <path d="M8.4334 10.901C8.34451 10.4566 8.2834 9.96771 8.25007 9.43437C8.21673 8.90104 8.18895 8.34549 8.16673 7.76771L6.96673 8.96771C6.72229 9.21215 6.41673 9.33437 6.05007 9.33437C5.6834 9.33437 5.36673 9.20104 5.10007 8.93437C4.85562 8.68993 4.7334 8.37882 4.7334 8.00104C4.7334 7.62326 4.85562 7.31215 5.10007 7.06771L8.56673 3.60104C8.70007 3.46771 8.84451 3.37326 9.00007 3.31771C9.15562 3.26215 9.32229 3.23438 9.50007 3.23438C9.67784 3.23438 9.84451 3.26215 10.0001 3.31771C10.1556 3.37326 10.3001 3.46771 10.4334 3.60104L13.9001 7.06771C14.1667 7.33437 14.2945 7.64549 14.2834 8.00104C14.2723 8.3566 14.1334 8.66771 13.8667 8.93437C13.6001 9.17882 13.289 9.30104 12.9334 9.30104C12.5778 9.30104 12.2667 9.17882 12.0001 8.93437L10.8334 7.80104C10.8334 8.26771 10.8556 8.7066 10.9001 9.11771C10.9445 9.52882 10.989 9.91215 11.0334 10.2677L8.4334 10.901Z" fill="var(--color-icon-2)"/>
        <path d="M11.3006 16.7678C10.8562 16.3012 10.4284 15.7567 10.0173 15.1345C9.60618 14.5123 9.24507 13.7456 8.93396 12.8345L11.5006 12.2012C11.7228 12.8012 11.9784 13.3123 12.2673 13.7345C12.5562 14.1567 12.8673 14.5345 13.2006 14.8678L11.3006 16.7678Z" fill="var(--color-icon-2)"/>
      </svg>
    ),
    title: 'Alternate Lines',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({title: 'Alternate Lines', subtitle: null, endpoint: 'discovery/cards?cardGroup=ALTERNATES', filtersToExclude: []}))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player', 'team']))}`
    }
  },
  {
    icon: (
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1672 17.9987C16.0116 17.9987 16.7227 17.7098 17.3005 17.132C17.8783 16.5543 18.1672 15.8431 18.1672 14.9987C18.1672 14.1543 17.8783 13.4431 17.3005 12.8654C16.7227 12.2876 16.0116 11.9987 15.1672 11.9987C14.3227 11.9987 13.6116 12.2876 13.0338 12.8654C12.456 13.4431 12.1672 14.1543 12.1672 14.9987C12.1672 15.8431 12.456 16.5543 13.0338 17.132C13.6116 17.7098 14.3227 17.9987 15.1672 17.9987ZM15.1672 20.6654C13.5894 20.6654 12.2505 20.1154 11.1505 19.0154C10.0505 17.9154 9.50049 16.5765 9.50049 14.9987C9.50049 13.4209 10.0505 12.082 11.1505 10.982C12.2505 9.88203 13.5894 9.33203 15.1672 9.33203C16.7449 9.33203 18.0838 9.88203 19.1838 10.982C20.2838 12.082 20.8338 13.4209 20.8338 14.9987C20.8338 15.5098 20.7616 16.0154 20.6172 16.5154C20.4727 17.0154 20.256 17.4876 19.9672 17.932L22.5672 20.532C22.8338 20.7987 22.9616 21.1098 22.9505 21.4654C22.9394 21.8209 22.8005 22.132 22.5338 22.3987C22.2672 22.6431 21.956 22.7709 21.6005 22.782C21.2449 22.7931 20.9338 22.6654 20.6672 22.3987L18.1005 19.832C17.656 20.1209 17.1838 20.332 16.6838 20.4654C16.1838 20.5987 15.6783 20.6654 15.1672 20.6654Z" fill="var(--color-icon-2)"/>
        <path d="M21.5008 27.998H25.5008C26.2341 27.998 26.8619 27.7369 27.3841 27.2147C27.9064 26.6925 28.1675 26.0647 28.1675 25.3314V21.3314C28.1675 20.9536 28.0397 20.6369 27.7841 20.3814C27.5286 20.1258 27.2119 19.998 26.8341 19.998C26.4564 19.998 26.1397 20.1258 25.8841 20.3814C25.6286 20.6369 25.5008 20.9536 25.5008 21.3314V25.3314H21.5008C21.123 25.3314 20.8064 25.4592 20.5508 25.7147C20.2953 25.9703 20.1675 26.2869 20.1675 26.6647C20.1675 27.0425 20.2953 27.3592 20.5508 27.6147C20.8064 27.8703 21.123 27.998 21.5008 27.998Z" fill="var(--color-icon-1)"/>
        <path d="M4.16748 6.66471V10.6647C4.16748 11.0425 4.29526 11.3592 4.55081 11.6147C4.80637 11.8703 5.12304 11.998 5.50081 11.998C5.87859 11.998 6.19526 11.8703 6.45081 11.6147C6.70637 11.3592 6.83415 11.0425 6.83415 10.6647V6.66471H10.8341C11.2119 6.66471 11.5286 6.53694 11.7841 6.28138C12.0397 6.02582 12.1675 5.70916 12.1675 5.33138C12.1675 4.9536 12.0397 4.63694 11.7841 4.38138C11.5286 4.12582 11.2119 3.99805 10.8341 3.99805H6.83415C6.10081 3.99805 5.47304 4.25916 4.95081 4.78138C4.42859 5.3036 4.16748 5.93138 4.16748 6.66471Z" fill="var(--color-icon-1)"/>
        <path d="M25.5008 6.66471V10.6647C25.5008 11.0425 25.6286 11.3592 25.8841 11.6147C26.1397 11.8703 26.4564 11.998 26.8341 11.998C27.2119 11.998 27.5286 11.8703 27.7841 11.6147C28.0397 11.3592 28.1675 11.0425 28.1675 10.6647V6.66471C28.1675 5.93138 27.9064 5.3036 27.3841 4.78138C26.8619 4.25916 26.2341 3.99805 25.5008 3.99805H21.5008C21.123 3.99805 20.8064 4.12582 20.5508 4.38138C20.2953 4.63694 20.1675 4.9536 20.1675 5.33138C20.1675 5.70916 20.2953 6.02582 20.5508 6.28138C20.8064 6.53694 21.123 6.66471 21.5008 6.66471H25.5008Z" fill="var(--color-icon-1)"/>
        <path d="M6.83415 27.998C6.10081 27.998 5.47304 27.7369 4.95081 27.2147C4.42859 26.6925 4.16748 26.0647 4.16748 25.3314V21.3314C4.16748 20.9536 4.29526 20.6369 4.55081 20.3814C4.80637 20.1258 5.12304 19.998 5.50081 19.998C5.87859 19.998 6.19526 20.1258 6.45081 20.3814C6.70637 20.6369 6.83415 20.9536 6.83415 21.3314V25.3314H10.8341C11.2119 25.3314 11.5286 25.4592 11.7841 25.7147C12.0397 25.9703 12.1675 26.2869 12.1675 26.6647C12.1675 27.0425 12.0397 27.3592 11.7841 27.6147C11.5286 27.8703 11.2119 27.998 10.8341 27.998H6.83415Z" fill="var(--color-icon-1)"/>
      </svg>
    ),
    title: 'Prop Tool',
    href: (league) => `/${league}/props`
  }
]

const HOTTEST_TRENDS = [
  {
    title: '100% Streak in',
    subtitle: 'Recent Games',
    icon: 'assets/bolt-outline.svg',
    color: 'blue',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: '100% Streak', 
        subtitle: 'Recent Games', 
        endpoint: 'discovery/cards?cardGroup=PERFECT_HIT_RATE_RECENT_FORM', 
        parlayEndpoint: 'discovery/parlays?trendGroup=PERFECT_HIT_RATE_RECENT_FORM',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}`
    }
  },
  {
    title: '100% Streak',
    subtitle: 'Versus Opponent',
    icon: 'assets/shield-outline.svg',
    color: 'violet',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: '100% Streak', 
        subtitle: 'Versus Opponent', 
        endpoint: 'discovery/cards?cardGroup=PERFECT_HIT_RATE_MATCHUP', 
        parlayEndpoint: 'discovery/parlays?trendGroup=PERFECT_HIT_RATE_MATCHUP',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}`
    }
  },
  {
    title: '100% Streak in',
    subtitle: 'Alternate Lines',
    icon: 'assets/alternate.svg',
    color: 'teal',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: '100% Streak', 
        subtitle: 'Alternate Lines', 
        endpoint: 'discovery/cards?cardGroup=PERFECT_HIT_RATE_ALTERNATES',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player', 'team']))}`
    }
  },
  {
    title: 'Positional',
    subtitle: 'Defense Rank',
    icon: 'assets/rank.svg',
    color: 'iris',
    render: (league) => ['nfl', 'ncaaf', 'nba', 'wnba', 'nhl'].includes(league),
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Positional', 
        subtitle: 'Defense Rank', 
        endpoint: 'discovery/cards?cardGroup=OPPONENT_POSITIONAL_RANK',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player']))}`
    }
  },
  {
    title: 'Streaks In The',
    subtitle: '1st Quarter',
    icon: 'assets/first-quarter.svg',
    color: 'cyan',
    render: (league) => ['nfl', 'ncaaf', 'nba', 'wnba'].includes(league),
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Streak', 
        subtitle: '1st Quarter', 
        endpoint: 'discovery/cards?cardGroup=QUARTERS_AND_HALVES',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player', 'team']))}`
    }
  },
  {
    title: '100% Streak in',
    subtitle: 'Home/Away Games',
    icon: 'assets/map-outline.svg',
    color: 'purple',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: '100% Streak', 
        subtitle: 'Home/Away Games', 
        endpoint: 'discovery/cards?cardGroup=PERFECT_HIT_RATE_SPLIT',
        parlayEndpoint: 'discovery/parlays?trendGroup=PERFECT_HIT_RATE_SPLIT',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}`
    }
  }
]

const PARLAY_TRENDS = [
  {
    title: 'Parlay',
    subtitle: '2 Leg',
    icon: 'assets/two-outline.svg',
    color: 'amber',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Parlay', 
        subtitle: '2 Leg',
        parlayEndpoint: 'discovery/parlays?legCount=2',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['parlay']))}`
    }
  },
  {
    title: 'Parlay',
    subtitle: '3 Leg',
    icon: 'assets/three-outline.svg',
    color: 'orange',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Parlay', 
        subtitle: '3 Leg',
        parlayEndpoint: 'discovery/parlays?legCount=3',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['parlay']))}`
    }
  },
  {
    title: 'Parlay Type',
    subtitle: 'Player Only',
    icon: 'assets/person-outline.svg',
    color: 'crimson',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Parlay', 
        subtitle: 'Player Only',
        parlayEndpoint: 'discovery/parlays?trendGroup=PLAYER',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['parlay']))}`
    }
  },
  {
    title: 'Parlay Type',
    subtitle: 'Team & Player',
    icon: 'assets/group-outline.svg',
    color: 'pink',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Parlay', 
        subtitle: 'Team & Player',
        parlayEndpoint: 'discovery/parlays',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['parlay']))}`
    }
  }
]

const ODDS_TRENDS = [
  {
    render: true,
    title: 'Below 2x Odds',
    subtitle: 'Safer Plays',
    icon: 'assets/safer.svg',
    color: 'green',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Below 2x Odds', 
        subtitle: 'Safer Plays',
        endpoint: 'discovery/cards?cardGroup=SAFE',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player', 'team']))}`
    }
  },
  {
    render: true,
    title: 'Above 2x Odds',
    subtitle: 'Riskier Plays',
    icon: 'assets/riskier.svg',
    color: 'red',
    href: (league) => {
      const feed = base64Encode(JSON.stringify({
        title: 'Above 2x Odds', 
        subtitle: 'Riskier Plays',
        endpoint: 'discovery/cards?cardGroup=RISKY',
        filtersToExclude: []
      }))
      return `/${league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player', 'team']))}`
    }
  },
  // Title and subtitle for key
  // Dummy fields to fill up space
  {title: 'dummy', subtitle: '1', render: false},
  {title: 'dummy', subtitle: '2', render: false}
]

class Home extends React.Component {

  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      currentGames: [],
      upcomingGames: [],
      teams: {},
      trendPreviews: {
        trends: [],
        markets: [],
        positions: []
      }
    }
  }

  // TODO: we could fetch upcoming only if there are no current
  componentDidMount() {
    document.title = 'Home - Linemate';
    Promise.all(
      getGamedaySchedule(API_HOST, this.context.league, this.context.configuration.metadata.leagues[this.context.league].recordType, false).concat(
        getAllTeams(API_HOST, this.context.league),
        fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/cards/preview?premium=${hasPremiumAccess(this.context.userAttributes)}`).then((data) => data.json())
      )
    )
    .then((result) => {
      this.setState({
        loaded: true,
        currentGames: result[0],
        upcomingGames: result[1],
        teams: buildTeamsMap(result[2]),
        trendPreviews: result[3]
      })
    });
  }

  render() {
    const date = parseDate(new Date());
    // The !this.state.loaded is just so that we default to 'today/this week' while loading
    const currentGames = this.state.currentGames.length > 0 || !this.state.loaded;
    const games = currentGames ? this.state.currentGames : this.state.upcomingGames;
    var title = "";
    if (currentGames) {
      title = SUPPORTED_LEAGUES_METADATA[this.context.league].scheduleFormat === SCHEDULE_FORMAT_WEEKLY ? "This Week's Matchups" : "Today's Matchups";
    } else {
      title = "Upcoming Matchups";
    }
    const timeframe = isWeeklyCompetition(this.context.league) && games && games.length > 0 && games[0].title ? games[0].title : date.formattedDayMonthShort;
    return (
      <div className='home-page-container'>
        {/* Placeholder to get the 3 column grid */}
        <div></div>
        <div className='home-page-content'>
          {/* Find your bet */}
          <div className='home-page-find-your-bet home-page-padding'>
            <p className='text-style-h-1-semibold color-fig-default'>Find your bet</p>
            <div className='home-page-find-your-bet-shortcuts'>
              {
                FIND_YOUR_BET_SHORTCUTS.map((shortcut) => 
                  <a key={shortcut.title} className='home-page-find-your-bet-shortcut unselectable clickable' style={{'--shortcuts': FIND_YOUR_BET_SHORTCUTS.length}} href={shortcut.href(this.context.league)}>
                    {shortcut.icon}
                    <p className='text-style-body-medium color-fig-default'>{shortcut.title}</p>
                  </a>
                )
              }
            </div>
          </div>
          {/* Matchups */}
          {
            games && games.length > 0 && (
              <div className='home-page-padding'>
                    <div className='home-page-title'>
                      <p className='text-style-h-2-semibold home-page-title-text-desktop'>{title}</p>
                      <p className='text-style-h-2-semibold home-page-title-text-mobile'>{title}</p>
                      {
                        currentGames && (
                          <>
                            <p className='text-style-h-2-medium home-page-title-date-desktop'>{timeframe}</p>
                            <p className='text-style-h-2-medium home-page-title-date-mobile'>{timeframe}</p>
                          </>
                        )
                      }
                    </div>
                <div className='home-page-matchup-wrapper'>
                {
                  games.map((game) =>
                    <MatchupCard key={game.id} game={game} teams={{home: this.state.teams[game.homeTeamData.info.code], away: this.state.teams[game.awayTeamData.info.code]}}/>
                  )
                }
                </div>
              </div>
            )
          }
          {/* Hottest Trends */}
          <div className='home-page-hottest-trends home-page-padding'>
            <p className='text-style-h-2-semibold color-fig-default'>Start with the hottest trends</p>
            <div className='home-page-hottest-trends-shortcuts'>
              {
                HOTTEST_TRENDS.map((shortcut) => {
                  if ('render' in shortcut && !shortcut.render(this.context.league)) {
                    return (<React.Fragment key={`${shortcut.title}-${shortcut.subtitle}`}></React.Fragment>)
                  }
                  return (
                    <a key={`${shortcut.title}-${shortcut.subtitle}`} className='home-page-hottest-trends-shortcut unselectable clickable' style={{'--shortcuts': HOTTEST_TRENDS.length}} href={shortcut.href(this.context.league)}>
                      <div className='home-page-hottest-trends-shortcut-illustration' style={{backgroundColor: `var(--color-${shortcut.color}-secondary)`}}>
                        <img style={{filter: `var(--color-${shortcut.color}-primary-filter)`}} src={shortcut.icon} alt={shortcut.title}/>
                      </div>
                      <p className='text-style-caption-uppercase-semibold' style={{color: `var(--color-${shortcut.color}-primary)`}}>{shortcut.title}</p>
                      <p className='text-style-body-medium color-fig-default'>{shortcut.subtitle}</p>
                    </a>
                  )
                })
              }
            </div>
          </div>
          {/* Top parlays */}
          <div className='home-page-parlay-trends home-page-padding'>
            <p className='text-style-h-2-semibold color-fig-default'>Discover the top parlays</p>
            <div className='home-page-parlay-trends-shortcuts'>
              {
                PARLAY_TRENDS.map((shortcut) => 
                  <a key={`${shortcut.title}-${shortcut.subtitle}`} className='home-page-parlay-trends-shortcut unselectable clickable' style={{'--shortcuts': PARLAY_TRENDS.length}} href={shortcut.href(this.context.league)}>
                    <div className='home-page-parlay-trends-shortcut-illustration' style={{backgroundColor: `var(--color-${shortcut.color}-secondary)`}}>
                      <img style={{filter: `var(--color-${shortcut.color}-primary-filter)`}} src={shortcut.icon} alt={shortcut.title}/>
                    </div>
                    <p className='text-style-caption-uppercase-semibold' style={{color: `var(--color-${shortcut.color}-primary)`}}>{shortcut.title}</p>
                    <p className='text-style-body-medium color-fig-default'>{shortcut.subtitle}</p>
                  </a>
                )
              }
            </div>
          </div>
          {/* Odds by preference */}
          <div className='home-page-odds-trends home-page-padding'>
            <p className='text-style-h-2-semibold color-fig-default'>Explore by odds preference</p>
            <div className='home-page-odds-trends-shortcuts'>
              {
                ODDS_TRENDS.map((shortcut) => {
                  if (!shortcut.render) {
                    return (<div key={`${shortcut.title}-${shortcut.subtitle}`} className='home-page-odds-trends-shortcut-dummy' style={{'--shortcuts': ODDS_TRENDS.length}}></div>)
                  }
                  return (
                    <a key={`${shortcut.title}-${shortcut.subtitle}`} className='home-page-odds-trends-shortcut unselectable clickable' style={{'--shortcuts': ODDS_TRENDS.length}} href={shortcut.href(this.context.league)}>
                      <div className='home-page-odds-trends-shortcut-illustration' style={{backgroundColor: `var(--color-${shortcut.color}-secondary)`}}>
                        <img style={{filter: `var(--color-${shortcut.color}-primary-filter)`}} src={shortcut.icon} alt={shortcut.title}/>
                      </div>
                      <p className='text-style-caption-uppercase-semibold' style={{color: `var(--color-${shortcut.color}-primary)`}}>{shortcut.title}</p>
                      <p className='text-style-body-medium color-fig-default'>{shortcut.subtitle}</p>
                    </a>
                  )
                })
              }
            </div>
          </div>
          {/* Leaderboard */}
          {
            <div className='home-page-leaders'>
              <div className='home-page-leaders-header'>
                <p className='text-style-h-2-semibold color-fig-default'>Leaderboard</p>
                <a className='text-style-body-normal color-fig-accent' href={`${this.context.league}/leaderboard`}>View All</a>
              </div>
              <div className='home-page-leaders-groups'>
              {
                DISCOVERY_LEADERS[this.context.league].map((group) => 
                  {
                    var leaderboardParams = {
                      tab: "player",
                      // TODO: some of the stats between web and app are named differently and screws up the preset
                      stat: group.sortingColumn,
                      order: group.sortingOrder,
                      // Timeframe filters stay the same, only adjusting position/type of stats
                      // filters: {}
                    };
                    if (group.filters.position) {
                      leaderboardParams.filters = base64Encode(JSON.stringify({position: group.filters.position.toUpperCase()})); 
                    }
                    const iconCanonical = group.icon.split("/").at(-1)
                    return (
                      <a key={`${group.title}-${group.subtitle}`} className='home-page-leaders-group' href={`${this.context.league}/leaderboard?${buildQueryParams(leaderboardParams)}`} style={{'--groups-count': DISCOVERY_LEADERS[this.context.league].length}}>
                        <div className='home-page-leaders-illustration'>
                          <img src={`assets/leader-icons/${iconCanonical}.svg`} alt={iconCanonical}/>
                        </div>
                        <p className='text-style-caption-uppercase-semibold color-fig-subtle'>{group.subtitle}</p>
                        <p className='text-style-label-medium color-fig-default'>{group.title}</p>
                      </a>
                    )
                  }
                )
              }
              </div>
            </div>
          }
        </div>
        {/* 
          Note: this has a similar problem as we had in the trends page where that column goes too far down lower than the middle column content
          Not going to bother fixing it for now since we shouldn't have that many book offers that would cause it
          However in the case where it is needed, refer to the Trends page where the height is dynamically set based on the middle column
         */}
        <div className='home-page-affiliates-sidebar-column'>
          <div>
            <AffiliatesList />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;