import React from 'react';

import './Trends.scss'
import '../components/App.scss'

import GlobalContext from '../components/GlobalContext';
import { filterCards, getBetTypeOptions, getGamesFilter, getGameTitle, getMultipleBetTypeFilter, getMultipleOverUnderFilter, getMultipleTrendsFilter, getPlayerId, hasPremiumAccess, isDictEmpty, jsonOrDefault, noOpPromise, parseDateFromString } from '../linemate-react-common/src/util';
import { API_HOST } from '../react-web-constants';
import { getCardKey, isMarketSupported } from '../linemate-react-common/src/betting-utils';
import TrendContent from '../components/TrendContent';
import Button from '../components/Button';
import { BET_TYPE_FILTER, GAMES_FILTER, OVER_UNDER_FILTER, TRENDS_FILTER } from '../linemate-react-common/src/constants';
import _ from 'lodash';
import { getCurrentGamedaySchedule } from '../linemate-react-common/src/gameday-utils';
import Dialog from '../components/Dialog';
import Chip from '../components/Chip';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Buffer } from 'buffer';
import SegmentedControl from '../components/SegmentedControl';
import { getTheme } from '../react-web-utils';
import TrendDetails from '../components/TrendDetails';


class Trends extends React.Component {

    static contextType = GlobalContext;

    constructor(props, context) {
        // https://github.com/facebook/react/issues/13944
        // Looks like this API is being deprecated but it doesn't look like there is any official way of doing it in the new API
        super(props, context)

        this.pickerToggled = this.pickerToggled.bind(this);
        this.segmentedControlSelection = this.segmentedControlSelection.bind(this);
        this.cardSelected = this.cardSelected.bind(this);
        this.parlaySelected = this.parlaySelected.bind(this);
        this.listFilterOptionSelected = this.listFilterOptionSelected.bind(this);
        this.applyFilters = this.applyFilters.bind(this);

        this.listRef = React.createRef();

        this.workstationResizeObserver = null;

        const searchParams = new URLSearchParams(window.location.search);
        this.customFeed = searchParams.has('feed') ? JSON.parse((Buffer.from(searchParams.get('feed'), 'base64')).toString('ascii')) : null;
        if (!hasPremiumAccess(context.userAttributes) && this.customFeed && this.customFeed.without) {
            // If it's a free user but has somehow gotten a link for without
            this.customFeed = null;
        }

        this.pickerOptions = {
            "player": "Player",
            "team": "Team",
            "parlay": "Parlay"
        }
        
        const tabs = searchParams.get('tabs');
        if (tabs) {
            try {
                const parsedTabs = JSON.parse(decodeURIComponent(tabs))
                this.pickerOptions = Object.fromEntries(Object.keys(this.pickerOptions).filter(x => parsedTabs.includes(x)).map(x => [x, this.pickerOptions[x]]))
            } catch (err) {
                console.log(err)
                console.log("Working with default tabs")
            }
        }
        this.tabs = Object.keys(this.pickerOptions)

        this.profile = searchParams.has('profile') && Boolean(searchParams.get('profile'))

        var listFilters = {
            player: {},
            team: {},
            parlay: {},
            trends: {}
        };

        // If it's a profile, don't include filters
        const includeFilters = (!this.customFeed || !('includeFilters' in this.customFeed) || Boolean(this.customFeed.includeFilters)) && !this.profile
        if (includeFilters) {
            Object.keys(this.pickerOptions).forEach((option) => {
                // This will get filled in once we fetch the schedule
                listFilters[option][GAMES_FILTER] = getGamesFilter({}, {});
                listFilters[option][OVER_UNDER_FILTER] = getMultipleOverUnderFilter();
                listFilters[option][TRENDS_FILTER] = getMultipleTrendsFilter();
                listFilters[option][BET_TYPE_FILTER] = getMultipleBetTypeFilter(getBetTypeOptions(context.league, option));
    
                Object.keys(listFilters[option]).forEach((filter) => {
                    if (this.customFeed && this.customFeed.filtersToExclude && this.customFeed.filtersToExclude.includes(filter)) {
                        delete listFilters[option][filter]
                    }
                })
            })
        }
        this.defaultListFilters = _.cloneDeep(listFilters)

        this.startingIndex = searchParams.get('index') || 0;

        if (this.profile) {
            this.pickerOptions = {
                'trends': 'Trends',
                'parlay': 'Parlay'
            }
        }
        const pickerSelection = Object.keys(this.pickerOptions)[0]
        this.state = {
            cards: {
                player: [],
                team: [],
                parlay: [],
                // Used for profile (along with parlay)
                trends: []
            },
            filteredCards: {
                player: [],
                team: [],
                parlay: [],
                // Used for profile (along with parlay)
                trends: []
            },
            freePreviewCards: [],
            games: [],
            pickerSelection: pickerSelection,
            cardSelectionLoading: false,
            selectedCard: null,
            selectedCardMarkets: null,
            listFiltersModalOpen: false,
            listFilters: _.cloneDeep(listFilters),
            // Dictates if we need to show the details view if set to true, otherwise show the list view
            mobileCardSelected: false,
            initialCardSelection: true
        }
    }

    componentDidMount() {
        document.title = 'Trends - Linemate';
        const userHasPremiumAccess = hasPremiumAccess(this.context.userAttributes);
        if (this.customFeed && this.profile) {
            const profileType = this.customFeed.player ? "player" : "team"
            const trendsPromise =  this.customFeed.player ? 
                fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/players/${getPlayerId(this.customFeed.player)}/cards?premium=${userHasPremiumAccess}`).then(data => jsonOrDefault(data, [])) :
                fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/teams/${this.customFeed.teamCode}/cards?premium=${userHasPremiumAccess}`).then(data => jsonOrDefault(data, []))
            var parlayPromise;
            if (!userHasPremiumAccess) {
                parlayPromise = noOpPromise([])
            } else {
                parlayPromise = this.customFeed.player ? 
                fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/players/${getPlayerId(this.customFeed.player)}/parlays`).then(data => jsonOrDefault(data, [])) :
                fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/teams/${this.customFeed.teamCode}/parlays`).then(data => jsonOrDefault(data, []))
            }
            Promise.all([trendsPromise, parlayPromise])
            .then(data => {
                const cards = data[0]
                const parlays = data[1]
                // TODO
                const games = []
                const state = {
                    cards: {
                        // Filter to make sure all legs have a market that we support and that the parlay is for a single team only (until we add support for cross-team)
                        parlay: parlays.filter(parlay => parlay.legs.every(leg => leg.market && isMarketSupported(this.context.league, leg.type, leg.market.name)) && new Set(parlay.legs.map(leg => leg.team.code)).size === 1),
                        trends: cards.filter(card => card['type'] === profileType && card.market && isMarketSupported(this.context.league, card.type, card.market.name))
                    },
                    // TODO (if we want to have them here)
                    freePreviewCards: [],
                    // freePreviewCards: freePreviewCards,
                    games: games
                }
                state.filteredCards = {
                    parlay: state.cards.parlay.slice(),
                    // trends: this.insertFreePreviewCards(state.cards.trends.slice(), freePreviewCards)
                    trends: state.cards.trends.slice()
                }
                var presetIndex = this.startingIndex || 0;
                if (presetIndex >= state.cards[this.state.pickerSelection].length) {
                    presetIndex = 0;
                }
                state.selectedCard = state.cards[this.state.pickerSelection].length > presetIndex ? state.cards[this.state.pickerSelection][presetIndex] : null
                this.setState(state, () => this.cardSelected(state.selectedCard, true, games))
                // this.setState(state, () => this.parlaySelected(state.selectedCard, true, games))
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    trends: [],
                    parlays: []
                })
            })
        } else {
            const promises = [getCurrentGamedaySchedule(API_HOST, this.context.league, this.context.configuration.metadata.leagues[this.context.league].recordType, false)];
    
            // Straight cards fetching
            if (this.customFeed && this.customFeed.endpoint) {
                // Custom feed for straight trends
                promises.push(
                    fetch(`${API_HOST}/api/${this.context.league}/v1/${this.customFeed.endpoint}${this.customFeed.endpoint.includes('?') ? "&" : "?"}premium=${userHasPremiumAccess}`)
                    .then((res) => jsonOrDefault(res, []))
                )
            } else {
                if (this.tabs && this.tabs.length === 1 && this.tabs.includes("parlay")) {
                    // If it's a parlay-only tab, no need to fetch the straight trends
                    promises.push(noOpPromise([]))
                } else {
                    // Default feed for straights
                    promises.push(
                        fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/cards?premium=${userHasPremiumAccess}`)
                        .then((res) => jsonOrDefault(res, []))
                    )
                }
            }

            if (!userHasPremiumAccess) {
                // Free users should never have parlays shown or fetched
                promises.push(noOpPromise([]))
            } else {
                // Custom feed for parlay trends
                if (this.customFeed && this.customFeed.parlayEndpoint) {
                    // Parlay trends fetching
                    promises.push(
                        fetch(`${API_HOST}/api/${this.context.league}/v1/${this.customFeed.parlayEndpoint}`)
                        .then((res) => jsonOrDefault(res, []))
                    )
                } else {
                    if (this.tabs && !this.tabs.includes("parlay")) {
                        // If it's a non-parlay-only tab, no need to fetch the parlay trends
                        promises.push(noOpPromise([]))
                    } else {
                        // Parlay trends fetching
                        promises.push(
                            fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/parlays`)
                            .then((res) => jsonOrDefault(res, []))
                        )
                    }
                }
            }
    
            // TODO: if it's on a feed that is exclusively for parlay we don't need to fetch the free preview cards
            // Free cards fetching
            if (userHasPremiumAccess) {
                promises.push(noOpPromise([]))
            } else {
                promises.push(
                    fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/cards?cardGroup=FREE_PREVIEW`)
                    .then((res) => jsonOrDefault(res, []))
                )
            }
    
            Promise.all(promises)
            .then((data) => {
                const games = data[0];
                const cards = data[1];
                const parlays = data[2];
                const freePreviewCards = data[3]
                const gameTitleToGameIdDict = Object.fromEntries(games.map(x => [getGameTitle(this.context.league, x), x.id]))
                const gameTitleToAnnotationDict = Object.fromEntries(games.map(x => {
                    const parsedDate = parseDateFromString(x.timestamp);
                    return [getGameTitle(this.context.league, x), `${parsedDate.dayOfWeekShort} ${parsedDate.formattedTime}`]
                }))
                const listFilters = this.state.listFilters;
                Object.keys(listFilters).forEach((option) => {
                    if (GAMES_FILTER in listFilters[option]) {
                        listFilters[option][GAMES_FILTER] = getGamesFilter(gameTitleToGameIdDict, gameTitleToAnnotationDict);
                    }
                })
                this.defaultListFilters = _.cloneDeep(listFilters)
                const state = {
                    cards: {
                        player: cards.filter(card => card['type'] === 'player' && card.market && isMarketSupported(this.context.league, card.type, card.market.name)),
                        team: cards.filter(card => card['type'] === 'team' && card.market && isMarketSupported(this.context.league, card.type, card.market.name)),
                        // Filter to make sure all legs have a market that we support and that the parlay is for a single team only (until we add support for cross-team)
                        parlay: parlays.filter(parlay => parlay.legs.every(leg => leg.market && isMarketSupported(this.context.league, leg.type, leg.market.name)) && new Set(parlay.legs.map(leg => leg.team.code)).size === 1)
                    },
                    freePreviewCards: freePreviewCards,
                    games: games,
                    listFilters: _.cloneDeep(listFilters)
                }
                state.filteredCards = {
                    player: this.insertFreePreviewCards(state.cards.player.slice(), freePreviewCards),
                    team: this.insertFreePreviewCards(state.cards.team.slice(), freePreviewCards),
                    parlay: state.cards.parlay.slice()
                }
                var presetIndex = this.startingIndex || 0;
                if (presetIndex >= state.cards[this.state.pickerSelection].length) {
                    presetIndex = 0;
                }
                state.selectedCard = state.cards[this.state.pickerSelection].length > presetIndex ? state.cards[this.state.pickerSelection][presetIndex] : null
                if (this.tabs.includes('player') || this.tabs.includes('team')) {
                    this.setState(state, () => this.cardSelected(state.selectedCard, true, games))
                } else if (this.tabs.includes('parlay')) {
                    this.setState(state, () => this.parlaySelected(state.selectedCard, true, games))
                }
            })
            .catch((error) => {
                console.log("Error loading cards", error)
            })
        }
        
        this.workstationResizeObserver = new ResizeObserver((entries) => {
            // console.log(entries)
            // This solution is not ideal, I would have preferred to have this done via css but couldn't figure out how to do it
            // The screen width check is so that it doesn't happen on mobile
            // This was previously in componentDidUpdate when the workstation was here, 
            //  but since it's now a separate component this is the easiest way to watch for a layout change
            if (this.listRef.current && window.screen.width >= 1024) {
                this.listRef.current.style.height = `${entries[0].contentRect.height}px`;
            }
        })
        this.workstationResizeObserver.observe(document.getElementsByClassName("trends-page-details")[0]);
    }

    componentWillUnmount() {
        if (this.workstationResizeObserver) {
            this.workstationResizeObserver.disconnect();
        }
    }

    insertFreePreviewCards(cards, freePreviewCards) {
        if (freePreviewCards.length === 0) {
            return cards;
        }
        const updatedCardsList = [];
        var freePreviewCardsIndex = 0;
        var freePreviewCardsIteration = 0;
        cards.forEach((card, index) => {
            updatedCardsList.push(card);
            // Every 5 cards insert a free preview card
            if ((index + 1) % 5 === 0) {
                // The 'iteration' key is so that we can add it as part of the key extractor of the flatlist items since we will repeat the free cards
                updatedCardsList.push(Object.assign({iteration: freePreviewCardsIteration++}, freePreviewCards[freePreviewCardsIndex]));
                freePreviewCardsIndex = (freePreviewCardsIndex + 1) % freePreviewCards.length;
            }
        })
        return updatedCardsList;
    }

    pickerToggled(event) {
        const selection = event.currentTarget.dataset.selection;
        this.setState({pickerSelection: selection})
    }

    segmentedControlSelection(selection) {
        this.setState({pickerSelection: selection})
    }

    cardSelected(card, initialLoad, games) {
        if (!initialLoad) {
            // Scroll to top of page for mobile, otherwise the user ends up at the bottom of the workstation
            window.scrollTo(0, 0);
        }
        if (card && this.state.selectedCard && getCardKey(card) === getCardKey(this.state.selectedCard)) {
            return this.setState({mobileCardSelected: !initialLoad, initialCardSelection: initialLoad});
        }
        if (!card) {
            return this.setState({mobileCardSelected: true})
        }
        // This is to make sure you can't start spam clicking cards while a card is loading which would trigger a bunch of fetches
        if (this.state.cardSelectionLoading) {
            return;
        }

        var selectedCardMarkets = {}
        var cardGame;
        if (games) {
            cardGame = games.find(x => x.id === card.gameId)
        } else {
            cardGame = this.state.games.find(x => x.id === card.gameId)
        }
        if (cardGame) {
            var teamPath = "";
            if (cardGame.awayTeamData.info.code === card.team.code) {
                teamPath = "awayTeamData";
            } else {
                teamPath = "homeTeamData";
            }

            if (card.type === "team") {
                selectedCardMarkets = Object.assign({}, cardGame.markets, cardGame[teamPath].markets);
            } else if (card.type === "player" && cardGame[teamPath].players) {
                const playerRecord = cardGame[teamPath].players.find(x => getPlayerId(x.info) === getPlayerId(card.player));
                selectedCardMarkets = playerRecord.props;
            }
        }

        this.setState({
            cardSelectionLoading: false,
            selectedCard: card,
            mobileCardSelected: !initialLoad,
            initialCardSelection: initialLoad,
            selectedCardMarkets: selectedCardMarkets
        })   
    }

    parlaySelected(parlay, initialLoad) {
        if (!initialLoad) {
            // Scroll to top of page for mobile, otherwise the user ends up at the bottom of the workstation
            window.scrollTo(0, 0);
        }
        if (parlay && this.state.selectedCard && getCardKey(parlay) === getCardKey(this.state.selectedCard)) {
            return this.setState({mobileCardSelected: !initialLoad, initialCardSelection: initialLoad});
        }
        if (!parlay) {
            return this.setState({mobileCardSelected: true})
        }
        // This is to make sure you can't start spam clicking cards while a card is loading which would trigger a bunch of fetches
        if (this.state.cardSelectionLoading) {
            return;
        }
        this.setState({
            cardSelectionLoading: false,
            mobileCardSelected: !initialLoad,
            initialCardSelection: initialLoad,
            selectedCard: parlay,
            selectedCardMarkets: {}
        });
    }

    listFilterOptionSelected(filter, value) {
        const tempState = this.state;
        const index = tempState.listFilters[tempState.pickerSelection][filter].selectedValues.indexOf(value);
        // Assumes the first entry in each filter represents the 'all' option
        const options = Array.isArray(tempState.listFilters[tempState.pickerSelection][filter].options) ?
                        tempState.listFilters[tempState.pickerSelection][filter].options : 
                        Object.keys(tempState.listFilters[tempState.pickerSelection][filter].options)
        const allOption = options[0];
        if (index === -1) {
            // Value is being checked
            if (allOption === value || 
                (tempState.listFilters[tempState.pickerSelection][filter].selectedValues.length > 0 && 
                    tempState.listFilters[tempState.pickerSelection][filter].selectedValues[0] !== allOption && 
                    (tempState.listFilters[tempState.pickerSelection][filter].selectedValues.length + 2) === options.length
                )) {
                tempState.listFilters[tempState.pickerSelection][filter].selectedValues = [allOption];
            } else {
                if (tempState.listFilters[tempState.pickerSelection][filter].selectedValues.length > 0 && 
                    tempState.listFilters[tempState.pickerSelection][filter].selectedValues[0] === allOption) {
                    // If we are checking a box that is not the 'all' option, remove the 'all' option if it's there
                    tempState.listFilters[tempState.pickerSelection][filter].selectedValues.splice(0, 1);
                }
                tempState.listFilters[tempState.pickerSelection][filter].selectedValues.push(value);
            }
        } else {
            // Value is being un-checked
            tempState.listFilters[tempState.pickerSelection][filter].selectedValues.splice(index, 1);
        }
        this.setState(tempState)
    }

    applyFilters() {
        // if (this.state.cards.length > 0) {
        //     this.listRef.current.scrollToIndex({index: 0})
        // }
        if (this.state.pickerSelection === "parlay") {
            this.setState((previousState) => {
                const tempState = previousState;
                tempState.filteredCards[tempState.pickerSelection] = filterCards(this.context.league, tempState.cards[tempState.pickerSelection], tempState.listFilters[tempState.pickerSelection], tempState.pickerSelection);
                return tempState;
            })
        } else {
            this.setState((previousState) => {
                const tempState = previousState;
                tempState.filteredCards[tempState.pickerSelection] = this.insertFreePreviewCards(filterCards(this.context.league, tempState.cards[tempState.pickerSelection], tempState.listFilters[tempState.pickerSelection], tempState.pickerSelection), tempState.freePreviewCards);
                return tempState;
            })
        }
    }

    render() {
        const title = this.customFeed ? this.customFeed.title : "Trends";
        const subtitle = this.customFeed ? this.customFeed.subtitle : null;
        const annotation = this.customFeed ? this.customFeed.annotation : null;
        const without = this.customFeed ? this.customFeed.without : null;
        var selectedCard = this.state.selectedCard;
        var cardType = "straight"
        if (selectedCard && !('type' in selectedCard)) {
            cardType = "parlay";
        }

        const showPicker = Object.keys(this.pickerOptions).length > 1;
        const haveCards = this.state.cards[this.state.pickerSelection].length > 0;

        const filtersEnabled = Object.keys(this.state.listFilters).map(x => this.state.listFilters[x]).some(x => !isDictEmpty(x)) && haveCards && this.state.pickerSelection !== "parlay"
        return (
            <div className='trends-page-container'>
                <div ref={this.listRef} className={`trends-page-list ${this.state.mobileCardSelected ? "" : "trends-page-list-mobile-active"}`}>
                    <div className='trends-page-list-title' data-annotated={Boolean(annotation)}>
                        {
                            this.customFeed && (
                                <img src='assets/arrow-back.svg' onClick={() => 'returnUrl' in this.customFeed ? window.location.href = this.customFeed.returnUrl : window.history.back()} alt="back"/>
                            )
                        }
                        <p className='text-style-h-3-semibold'>{title}</p>
                        {
                            subtitle && (
                                <p className='text-style-h-3-medium'>{subtitle}</p>
                            )
                        }
                        {
                            annotation && (
                                <p className='text-style-label-normal'>{annotation}</p>
                            )
                        }
                    </div>
                    <div className='trends-page-list-filters'>
                        {
                            showPicker && (
                                <div className='trends-page-list-filters-picker-wrapper'>
                                    <SegmentedControl fillWidth={true} options={this.pickerOptions} selection={this.state.pickerSelection} selectionHandler={this.segmentedControlSelection}/>
                                </div>
                            )
                        }
                        <div className='trends-page-list-filters-button-wrapper' style={showPicker ? {} : {width: '100%'}}>
                            <Dialog
                                open={this.state.listFiltersModalOpen}
                                // TODO: on close reset filters (except for apply)
                                onOpenChange={(open) => this.setState({listFiltersModalOpen: open})}
                                trigger={
                                    <Button text="Filters" typography="sm" type="secondary" enabled={filtersEnabled} onClick={() => this.setState({listFiltersModalOpen: true})}/>
                                }
                                title="Filters"
                                content={
                                    <div className='trends-page-list-filters-dialog-content'>
                                        {
                                            this.state.games.length > 0 && GAMES_FILTER in this.state.listFilters[this.state.pickerSelection] && (
                                                <>
                                                    <p className='text-style-label-medium'>Display Games</p>
                                                    <div className='trends-page-list-filters-dialog-content-checkbox-list-wrapper'>
                                                    {
                                                        Object.keys(this.state.listFilters[this.state.pickerSelection][GAMES_FILTER].options).map((option) => {
                                                            const checked = this.state.listFilters[this.state.pickerSelection][GAMES_FILTER].selectedValues.includes(option);
                                                            return (
                                                                <Checkbox.Root key={option} className='trends-page-list-filters-dialog-content-checkbox-list-item' checked={checked} onCheckedChange={(checked) => this.listFilterOptionSelected(GAMES_FILTER, option)}>
                                                                    <div className='trends-page-list-filters-dialog-content-checkbox-list-item-checkbox-indicator'>
                                                                        {checked && (<img src='assets/check.svg' alt="check"/>)}
                                                                    </div>
                                                                    <p className={`text-style-label-normal trends-page-list-filters-dialog-content-checkbox-list-item-text${checked ? "-selected" : ""}`}>{option}</p>
                                                                </Checkbox.Root>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            OVER_UNDER_FILTER in this.state.listFilters[this.state.pickerSelection] && this.state.pickerSelection !== "parlay" && (
                                                <>
                                                    <p className='text-style-label-medium'>Over/Under</p>
                                                    <div className='trends-page-list-filters-dialog-content-chip-wrapper'>
                                                        {
                                                            Object.keys(this.state.listFilters[this.state.pickerSelection][OVER_UNDER_FILTER].options).map((option) => 
                                                                <Chip key={option} text={option} size="sm" selected={this.state.listFilters[this.state.pickerSelection][OVER_UNDER_FILTER].selectedValues.includes(option)} onClick={() => this.listFilterOptionSelected(OVER_UNDER_FILTER, option)}/>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            BET_TYPE_FILTER in this.state.listFilters[this.state.pickerSelection] && this.state.pickerSelection !== "parlay" && (
                                                <>
                                                    <p className='text-style-label-medium'>Bet Types</p>
                                                    <div className='trends-page-list-filters-dialog-content-chip-wrapper'>
                                                                {
                                                                    Object.keys(this.state.listFilters[this.state.pickerSelection][BET_TYPE_FILTER].options).map((option) => 
                                                                        <Chip key={option} text={option} size="sm" selected={this.state.listFilters[this.state.pickerSelection][BET_TYPE_FILTER].selectedValues.includes(option)} onClick={() =>this.listFilterOptionSelected(BET_TYPE_FILTER, option)}/>
                                                                    )
                                                                }
                                                    </div>
                                                </>
                                            )
                                        }
                                        {   
                                            TRENDS_FILTER in this.state.listFilters[this.state.pickerSelection] && (
                                                <>
                                                    <p className='text-style-label-medium'>Trends</p>
                                                    <div className='trends-page-list-filters-dialog-content-chip-wrapper'>
                                                        {
                                                            Object.keys(this.state.listFilters[this.state.pickerSelection][TRENDS_FILTER].options).map((option) => 
                                                                <Chip key={option} text={option} size="sm" selected={this.state.listFilters[this.state.pickerSelection][TRENDS_FILTER].selectedValues.includes(option)} onClick={() =>this.listFilterOptionSelected(TRENDS_FILTER, option)}/>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                }
                                footer={
                                    <div className='trends-page-list-filters-dialog-footer'>
                                        <div>
                                            <Button text="Reset defaults" typography="md" type="invisible" enabled={true} onClick={() => this.setState({listFilters: this.defaultListFilters})}/>
                                        </div>
                                        <div>
                                            {/* Only enabled if all filters have at least 1 selected value */}
                                            <Button 
                                                text="Apply" 
                                                typography="md" 
                                                type="primary" 
                                                enabled={Object.keys(this.state.listFilters[this.state.pickerSelection]).map((filter) => this.state.listFilters[this.state.pickerSelection][filter].selectedValues.length).every((length) => length > 0)}
                                                onClick={() => this.setState({listFiltersModalOpen: false}, this.applyFilters)}
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className='trends-page-list-items'>
                        {
                            this.state.pickerSelection === "parlay" && !hasPremiumAccess(this.context.userAttributes) && (
                                <div className='trends-page-list-parlay-showcase'>
                                    <img src={`assets/${getTheme()}-parlay-showcase.png`} alt="parlay-showcase"/>
                                    <div className='trends-page-list-parlay-showcase-text'>
                                        <p className='text-style-h-4-semibold color-fig-default'>Unlock Parlays with Linemate+</p>
                                        <p className='text-style-tabular-normal color-fig-subtle'>The same trends-based research, but now for parlays. Every league, every day.</p>
                                    </div>
                                    <div className='trends-page-list-parlay-showcase-button'>
                                        <Button text="Get Linemate+" typography="lg" type="primary" enabled={true} onClick={() =>  window.location.href = `/pricing?origin=${window.location.pathname}`}/>
                                    </div>
                                </div>
                            )
                        }
                        {/* Show list of trends if it's not parlay (available to everyone) or for parlay if it's a premium account only */}
                        {
                            (this.state.pickerSelection !== "parlay" || (this.state.pickerSelection === "parlay" && hasPremiumAccess(this.context.userAttributes))) && (
                                this.state.filteredCards[this.state.pickerSelection].map((card) => {
                                    const item = card;
                                    const cardKey = getCardKey(item);
                                    if (this.state.pickerSelection === "parlay") {
                                        return (
                                            <div key={cardKey} 
                                                className={`${this.state.selectedCard && cardKey === getCardKey(this.state.selectedCard) ? "trends-page-list-items-item-selected" : "trends-page-list-items-item"}`}
                                                data-initialSelection={this.state.initialCardSelection}
                                                onClick={() => this.parlaySelected(card)}
                                            >
                                                <TrendContent card={card} parlay={true}/>
                                            </div>
                                        )
                                    }
                                    const userHasPremiumAccess = hasPremiumAccess(this.context.userAttributes);
                                    return (
                                        <div key={`${cardKey}-${card.freePreview && !userHasPremiumAccess ? item.iteration : ""}`} 
                                                className={`${this.state.selectedCard && cardKey === getCardKey(this.state.selectedCard) ? "trends-page-list-items-item-selected" : "trends-page-list-items-item"}`}
                                                data-initialSelection={this.state.initialCardSelection}
                                                data-freePreview={item.freePreview && !userHasPremiumAccess}
                                                onClick={() => item.freePreview && !userHasPremiumAccess ? window.location.href = '/pricing' : this.cardSelected(card, false, null)}
                                        >
                                            {
                                                item.freePreview && !userHasPremiumAccess && (
                                                    <div className='trends-page-list-items-item-free-preview'>
                                                        <div className='trends-page-list-items-item-free-preview-left'>
                                                            <p className='text-style-caption-semibold'>Combo Trend Preview</p>
                                                        </div>
                                                        <div className='trends-page-list-items-item-free-preview-right'>
                                                            <div>
                                                                <img src='assets/lock-open.svg' alt='unlocked'/>
                                                            </div>
                                                            <p className='text-style-caption-semibold'>Try Linemate+</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <TrendContent card={card}/>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                </div>
                <div className={`trends-page-details ${this.state.mobileCardSelected ? "trends-page-details-mobile-active" : ""}`}>
                    {
                        (selectedCard || this.profile) && (
                            <TrendDetails key={getCardKey(selectedCard)} trend={selectedCard} source={this.profile ? "profile" : "trends"} type={cardType} without={without} customFeed={this.customFeed}/>
                        )
                    }
                    {
                        !haveCards && !this.profile && (
                            <div className='trends-page-details-no-plays'>
                                <div>
                                    <img src='assets/plays-empty-state-outer.svg' alt="no-plays"/>
                                    <img src='assets/plays-empty-state-inner.svg' alt="no-plays"/>
                                </div>
                                <p className='text-style-h-4-medium color-fig-default'>No plays available yet</p>
                                <p className='text-style-label-normal color-fig-subtle'>Please check back later</p>
                            </div>
                        )
                    }
                </div>
                <div></div>
            </div>
        );
    }
}

export default Trends;