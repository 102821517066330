import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import './Avatar.scss';
// Re-using some of the input selection styling for the dropdown
import './InputSelection.scss';

const TYPOGRAPHY_CLASSES = {
    sm: 'text-style-caption-uppercase-semibold',
    md: 'text-style-label-semibold',
    lg: 'text-style-h-4-uppercase-semibold'
}

/**
 * Avatar will use up the entire available space of its parent container.
 * Props
 * - text: <>
 * - typography: sm|md|lg
 * - clickable: true|false
 * - menuItems: {displayText: fn()}
 */
class Avatar extends React.Component {

  render() {
    if (this.props.clickable) {
      return (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger className='avatar-trigger'>
            <div className="avatar-container avatar-container-clickable">
              <div className='avatar-container-circle'>
                <p className={TYPOGRAPHY_CLASSES[this.props.typography]}>{this.props.text}</p>
              </div>
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content className='input-selection-dropdown avatar-dropdown' sideOffset={4} align='end' alignOffset={8}>
              {
                Object.keys(this.props.menuItems).map((item) =>
                  <DropdownMenu.Item key={item} className='input-selection-dropdown-item avatar-dropdown-item' onSelect={this.props.menuItems[item]}>
                    <p className="text-style-label-normal input-selection-dropdown-item-text avatar-dropdown-item-text">{item}</p>
                  </DropdownMenu.Item>
                )
              }
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      )
    }
    return (
      <div className="avatar-container">
        <div className='avatar-container-circle'>
          <p className={TYPOGRAPHY_CLASSES[this.props.typography]}>{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export default Avatar;