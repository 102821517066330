import { initializeApp } from "firebase/app";
import { 
    getAuth, 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword, 
    signOut, 
    sendPasswordResetEmail, 
    sendSignInLinkToEmail,
    confirmPasswordReset,
    checkActionCode,
    updatePassword
} from "firebase/auth";

export const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
});

// Adapter class to allow for the use of the auth object in the old format of the firebase app rather than passing it into functions all the time
// This will allow us not to have to change all the existing code relying on the auth object during the upgrade from firebase 8.x to 10.x
class AuthAdapter {

    constructor(auth) {
        this.auth = auth;
    }

    get currentUser() {
        return this.auth.currentUser;
    }

    signInWithEmailAndPassword(email, password) {
        return signInWithEmailAndPassword(this.auth, email, password);
    }

    createUserWithEmailAndPassword(email, password) {
        return createUserWithEmailAndPassword(this.auth, email, password);
    }

    signOut() {
        return signOut(this.auth);
    }

    onAuthStateChanged(nextOrObserver, error, completed) {
        return this.auth.onAuthStateChanged(nextOrObserver, error, completed);
    }

    authStateReady() {
        return this.auth.authStateReady();
    }

    sendPasswordResetEmail(email) {
        return sendPasswordResetEmail(this.auth, email);
    }

    sendSignInLinkToEmail(email) {
        return sendSignInLinkToEmail(this.auth, email);
    }

    confirmPasswordReset(code, newPassword) {
        return confirmPasswordReset(this.auth, code, newPassword);
    }

    checkActionCode(code) {
        return checkActionCode(this.auth, code);
    }

    updatePassword(user, newPassword) {
        return updatePassword(user, newPassword);
    }

}

export const auth = new AuthAdapter(getAuth(app));
