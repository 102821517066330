import React from 'react';
import Dropdown from './dropdown.js';

/**
 * If using two separate filter components in the same view (such as a main set for desktop and then another one in a mobile drawer), make sure only one is rendered at any given time.
 * If you have more than one it will screw up the states and not properly open and/or close some of the filters
 */
class Filters extends React.Component {
    constructor(props) {
        super(props);
        const filters = Object.assign({}, this.props.filters || {});
  
        this.filterRefs = {};
        this.filterContainerStyling = {};
        this.clickableElements = [];
        // Adding the filters as clickable elements with refs
        // This could probably be optimized by only doing it once at setup
        for (var key in filters) {
            if (!(key in this.filterRefs)) {
                this.filterRefs[key] = React.createRef();
                this.props.addClickableElementHandler(this.filterRefs[key]);
                this.clickableElements.push(this.filterRefs[key]);
            }
        }
  
        this.handleGeneralClick = this.handleGeneralClick.bind(this);
        this.scrollFunction = this.scrollFunction.bind(this);
        this.generateFilterStyling = this.generateFilterStyling.bind(this);
        this.filterClicked = this.filterClicked.bind(this);
        this.intermediateSelectionHandler = this.intermediateSelectionHandler.bind(this);
        this.intermediateAddClickableElement = this.intermediateAddClickableElement.bind(this);
  
        this.state = {
            filters: filters
        };
    }
  
    componentDidMount() {
        window.addEventListener('mousedown', this.handleGeneralClick);
        window.addEventListener('scroll', this.scrollFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleGeneralClick);
        window.removeEventListener('scroll', this.scrollFunction);
    }
  
    componentDidUpdate() {
        var needsUpdate = false;
        for (var key in this.props.filters) {
            if (!(key in this.filterRefs)) {
                needsUpdate = true;
                this.filterRefs[key] = React.createRef();
                this.props.addClickableElementHandler(this.filterRefs[key]);
                this.clickableElements.push(this.filterRefs[key]);
            }
        }
        if (needsUpdate) {
            this.forceUpdate();
        }
    }

    // In the leaderboard we have two filters lists with the same source (one for desktop and one for mobile), that might be causing interference and screwing everything up
    handleGeneralClick(event) {
        // https://stackoverflow.com/a/48076624
        // TODO: need to review for mac, it seems this only works for windows
        const scrollbarClicked = (event.offsetX > event.target.clientWidth || event.offsetY > event.target.clientHeight)
        var foundTarget = false;
        for(const element of this.clickableElements) {
          if (element.current && element.current.contains(event.target)) {
            foundTarget = true;
            break;
          }
        }
        if (!foundTarget && !scrollbarClicked) {
            var filters = this.props.filters;
            Object.keys(filters).map((key) => {
                filters[key].isActive = false;
            });
            // this.setState({filters: filters});
            this.forceUpdate();
        }
    }

    scrollFunction() {
        var filters = this.state.filters;
        var shouldUpdate = false;
        Object.keys(filters).map((key) => {
            if (filters[key].isActive) {
                shouldUpdate = true;
            }
            filters[key].isActive = false;
        });
        if (shouldUpdate) {
            this.setState({filters: filters});
        }
    }
  
    generateFilterStyling() {
        for (var key in this.props.filters) {
            if (!(key in this.filterRefs) || this.filterRefs[key] === null || this.filterRefs[key].current === null) {
                continue;
            }
            this.filterContainerStyling[key] = {
                left: `${this.filterRefs[key].current.offsetLeft}px`,
                top: `${this.filterRefs[key].current.offsetTop + this.filterRefs[key].current.offsetHeight}px`,
                width: `${this.filterRefs[key].current.offsetWidth}px`
            };
        }
    }
  
    filterClicked(event) {
        const selectedFilter = event.currentTarget.dataset.selection;
        var filters = this.props.filters;
        for (let key in filters) {
            if (selectedFilter !== key) {
                filters[key].isActive = false;
            }
        }
        filters[selectedFilter].isActive = !filters[selectedFilter].isActive;
        // this.setState({filters: filters});
        this.forceUpdate();
    }
  
    intermediateSelectionHandler(event, optionalCheckedItems) {
        this.props.selectionHandler(event, optionalCheckedItems);
    }

    intermediateAddClickableElement(element) {
        this.props.addClickableElementHandler(element);
        this.clickableElements.push(element);
    }
  
    render() {
        this.generateFilterStyling();
        return (
            <>
            {
              Object.keys(this.props.filters).map((key) => (
                //   Need to keep the wrapping span in order to add the key which will make sure it gets updated properly
                //   If the key is removed or if an empty JSX is used to suround it, 
                //       the data inside won't get updated properly and cause issues when keeping the same filter between views with updated content
                <span key={`${key}-filter`} >
                    <span ref={this.filterRefs[key]} data-selection={key} onClick={this.filterClicked} class="clickable filter-button unselectable">
                        <span class="font size-14 grey">{key.toUpperCase()}:&nbsp;</span>
                        {/* span vertical-align as text-bottom and <p> child as middle to make it work on Chrome and Safari*/}
                        <span class="font size-14 dark uppercase" style={"type" in this.props.filters[key] && this.props.filters[key].type === "multipleSelection" ? {verticalAlign: 'text-bottom'} : {width: '65%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {
                                "type" in this.props.filters[key] && this.props.filters[key].type === "multipleSelection" 
                                ?
                                <p style={{width: '75px', display: 'inline-block', overflow: 'hidden', verticalAlign: 'middle', whiteSpace: 'nowrap', textOverflow: 'ellipsis', lineHeight: '19px'}}>
                                    {this.props.filters[key].selectedValues.indexOf("ALL") !== -1 ? "ALL" : this.props.filters[key].selectedValues.join(",")}
                                </p>
                                :
                                <p style={{}}>{this.props.filters[key].selectedValue}</p>
                            }
                        </span>
                        <img src="assets/dropdown-state-closed-grey.svg" height={20} width={20} alt="" style={{marginBottom: '2px'}}/>
                    </span>
                    {
                        "type" in this.props.filters[key] && this.props.filters[key].type === "multipleSelection" ?
                        <Dropdown
                            name={key}
                            enabled={this.props.filters[key].isActive}
                            currentSelections={this.props.filters[key].selectedValues}
                            options={this.props.filters[key].options}
                            customContainerStyling={this.filterContainerStyling[key]}
                            textStylingClasses="size-14 spaced"
                            // onClickHandler={this.props.selectionHandler}
                            onClickHandler={this.intermediateSelectionHandler}
                            clickableElementAddition={this.intermediateAddClickableElement} 
                            type={"multipleSelection"}/>
                        :
                        <Dropdown
                            name={key}
                            enabled={this.props.filters[key].isActive}
                            currentSelection={this.props.filters[key].selectedValue}
                            options={this.props.filters[key].options}
                            customContainerStyling={this.filterContainerStyling[key]}
                            textStylingClasses="size-14 spaced"
                            // onClickHandler={this.props.selectionHandler}
                            onClickHandler={this.intermediateSelectionHandler}
                            clickableElementAddition={this.intermediateAddClickableElement} 
                            type={"singleSelection"}/>
                    }
                </span>
              ))
            }
            </>
        )
    }
  }

  export default Filters;