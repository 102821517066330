import React from 'react';

import './Button.scss'
import './App.scss'

const TYPOGRAPHY_CLASSES = {
    sm: 'text-style-caption-medium',
    md: 'text-style-label-medium',
    lg: 'text-style-body-medium',
    xl: 'text-style-h-4-medium'
}

const TYPE_CLASSES = {
    primary: 'button-primary',
    secondary: 'button-secondary',
    invisible: 'button-invisible',
    destructive: 'button-destructive'
}

// TODO: should we change this to have children instead of accepting text and icon?
// TODO: look into optionally using an 'a' tag instead of a div if all we want to do is redirect to a link
//  logic could be something like if (this.props.onClick) use div and if (this.props.url) use a
/**
 * Button will use up the entire available space of its parent container.
 * Either text or icon should be provided. They are mutually exclusive.
 * Props
 * - text: <>
 * - icon: <icon asset path>
 * - typography: sm|md|lg|xl
 * - type: primary|secondary|invisible|destructive
 * - enabled: true|false
 * - onClick: fn()
 */
class Button extends React.Component {

  // A bit of a hack allowing us to use the button as a Radix Dialog trigger which calls the focus() function of the trigger content when dismissing the dialog via a click on the overlay
  focus() {}

  render() {
    return (
      <div className={`button-container ${TYPE_CLASSES[this.props.type]}${!this.props.enabled ? "-disabled" : ""}`} onClick={this.props.enabled ? this.props.onClick : () => {}}>
        {
          this.props.text && !this.props.icon && (<p className={TYPOGRAPHY_CLASSES[this.props.typography]}>{this.props.text}</p>)
        }
        {
          this.props.icon && !this.props.text && (<img src={this.props.icon} alt=""/>)
        }
      </div>
    );
  }
}

export default Button;