import React from 'react';

import './SegmentedControl.scss'
import './App.scss'

/**
 * Props
 * - options: {key: displayName}
 * - selection: string
 * - selectionHandler: fn(string)
 * - fillWidth: bool
 */
class SegmentedControl extends React.Component {

  render() {
    const controlsCount = Object.keys(this.props.options).length;
    return (
        <div className='segmented-control-wrapper' style={{width: this.props.fillWidth ? "100%" : "auto"}}>
          {
            Object.keys(this.props.options).map((option, index) => {
              const selectedOption = option === this.props.selection;
              const addSeparator = index > 0 && Object.keys(this.props.options).length > 2 && !selectedOption && Object.keys(this.props.options)[index - 1] !== this.props.selection;
              return (
                <div key={option} style={{width: this.props.fillWidth ? `calc(100%/${controlsCount})` : "auto", padding: this.props.fillWidth ? 0 : "0px 12px"}} className={`clickable segmented-control ${selectedOption ? "segmented-control-selection" : ""} ${addSeparator ? "segmented-control-selection-separated" : ""}`} onClick={() => this.props.selectionHandler(option)}>
                  <p className={`${selectedOption ? "text-style-label-semibold color-fig-default" : "text-style-label-medium color-fig-subtle"}`}>
                    {this.props.options[option]}
                  </p>
                </div>
              )
            })
          }
        </div>
    );
  }
}

export default SegmentedControl;