import React from 'react';

import './Signup.scss'
import '../components/App.scss'
import GlobalContext from '../components/GlobalContext';
import Button from '../components/Button';
import HtmlHeaders from '../html-headers';
import { auth } from '../firebase.js';
import RadioGroup from '../components/RadioGroup';
import { API_HOST } from '../react-web-constants';
import { containsLowercase, containsNumber, containsUppercase, isAlphaNumeric, validateEmail } from '../linemate-react-common/src/util';

class Signup extends React.Component {

    static contextType = GlobalContext;

    constructor(props, context) {
        // https://github.com/facebook/react/issues/13944
        // Looks like this API is being deprecated but it doesn't look like there is any official way of doing it in the new API
        super(props, context);

        const searchParams = new URLSearchParams(window.location.search);

        this.defaultOrigin = `/${context.configuration.metadata.defaultLeague}`
        this.origin = this.defaultOrigin;
        if (searchParams.has("origin")) {
            this.origin = searchParams.get("origin");
        }

        if (context.user) {
            this.redirectUser(context.user);    
        }

        this.oddsOptions = {
            'american': {text: 'American', annotation: '(-200)'},
            'decimal': {text: 'Decimal', annotation: '(1.5)'}
        }

        this.signupFirstNameRef = React.createRef();
        this.signupLastNameRef = React.createRef();
        this.signupUsernameRef = React.createRef();
        this.signupEmailRef = React.createRef();
        this.signupPasswordRef = React.createRef();
        this.signupConfirmPasswordRef = React.createRef();

        this.inputOnKeyUp = this.inputOnKeyUp.bind(this);
        this.signup = this.signup.bind(this);
        this.setOddsPreference = this.setOddsPreference.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validateUsername = this.validateUsername.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validateConfirmPassword = this.validateConfirmPassword.bind(this);

        this.validationFunctions = {
            'name': this.validateName,
            'username': this.validateUsername,
            'email': this.validateEmail,
            'password': this.validatePassword,
            'confirmPassword': this.validateConfirmPassword
        }

        this.state = {
            // Rotate between "credentials" and "odds-preferences"
            signupStage: "credentials",
            formFilled: false,
            signupErrorMessages: {},
            signupInProgress: false,
            oddsFormatSelection: null,
            loading: false
        }
    }

    componentDidMount() {
        document.title = 'Signup - Linemate';
    }

    redirectUser(user) {
        // if (this.checkout !== "") {
        //     user.getIdToken().then((token) => { 
        //         const requestInfo = {
        //             headers: {
        //                 'Authorization': "Bearer " + token
        //             }
        //         };
        //         fetch(`${API_HOST}/api/payment/v1/checkout?id=${this.checkout}&origin=${this.origin}`, requestInfo).then(data => {return data.json();})
        //         .then(result => {
        //             window.location.href = result.redirect;
        //         })
        //         .catch(error => {
        //             console.log("Error getting checkout session: " + error);
        //         });
        //     });
        // } else {
            if (!this.origin.startsWith('/')) {
                window.open(this.origin, '_blank')
                window.location.href = this.defaultOrigin;
            } else {
                window.location.href = this.origin;
            }
        // }
    }

    validateName(state) {
        const firstNameValue = this.signupFirstNameRef.current.value;
        const lastNameValue = this.signupLastNameRef.current.value;
        if (firstNameValue === null || firstNameValue === "" || firstNameValue === "undefined" || 
            lastNameValue === null || lastNameValue === "" || lastNameValue === "undefined") {
            state.formFilled = false;
        }
        return state;
    }

    // TODO: profanity filter
    validateUsername(state) {
        const value = this.signupUsernameRef.current.value;
        state.signupErrorMessages.username = [];
        if (value === null || value === "" || value === "undefined") {
            state.formFilled = false;
        } else {
            const errors = [];
            if (value.length < 6) {
                errors.push("Username is too short (min. 6 characters).")
            }
            if (!isAlphaNumeric(value)) {
                errors.push("Username may only contain letters and numbers.")
            }
            state.signupErrorMessages.username = errors;
        }
        return state;
    }

    validateEmail(state) {
        const value = this.signupEmailRef.current.value;
        state.signupErrorMessages.email = []
        if (value === null || value === "" || value === "undefined") {
            state.formFilled = false;
        } else if(!validateEmail(value)) {
            state.signupErrorMessages.email = ["Please enter a valid email address."]
        }
        return state;
    }

    validatePassword(state) {
        const value = this.signupPasswordRef.current.value;
        state.signupErrorMessages.password = []
        if (value === null || value === "" || value === "undefined") {
            state.formFilled = false;
        } else if (value.length < 8 || !containsNumber(value) || !containsUppercase(value) || !containsLowercase(value)) {
            state.signupErrorMessages.password = ["Password needs to include an uppercase letter, a lowercase letter and be at least 8 characters long."]
        }
        return state;
    }

    validateConfirmPassword(state) {
        const confirmPassword = this.signupConfirmPasswordRef.current.value;
        state.signupErrorMessages.confirmPassword = []
        if (confirmPassword === null || confirmPassword === "" || confirmPassword === "undefined") {
            state.formFilled = false;
        } else {
            const password = this.signupPasswordRef.current.value;
            if (password !== confirmPassword) {
                state.signupErrorMessages.confirmPassword = ["Confirm password does not match."]
            }
        }
        return state;
    }

    inputOnKeyUp(event) {
        event.preventDefault();
        const state = {
            formFilled: true,
            signupErrorMessages: {}
        }
        this.validateName(state);
        this.validateUsername(state);
        this.validateEmail(state);
        this.validatePassword(state);
        this.validateConfirmPassword(state);
        this.setState({formFilled: state.formFilled})

        if (state.formFilled && (Object.keys(state.signupErrorMessages).length === 0 || Object.keys(state.signupErrorMessages).map(x => state.signupErrorMessages[x]).every(x => x.length === 0)) && event.keyCode === 13) {
            this.signup(state);
        }
    }

    signup(state) {
        if (!state.loading, state.formFilled && (Object.keys(state.signupErrorMessages).length === 0 || Object.keys(state.signupErrorMessages).map(x => state.signupErrorMessages[x]).every(x => x.length === 0))) {
            this.setState({loading: true}, () => {
                // First check if username is available via the backend API otherwise you might create the user without checking the username availability (404 means available), (204 means not available)
                fetch(`${API_HOST}/api/users/v1/checkUsernameAvailability?username=${this.signupUsernameRef.current.value}`)
                .then(data => data.json())
                .then((response) => {
                    if (response.available) {
                        // Username isn't taken, we can continue
                        // Sign up using firebase
                        auth.createUserWithEmailAndPassword(this.signupEmailRef.current.value, this.signupPasswordRef.current.value)
                        .then((response) => {
                            // Update user details via the API
                            response.user.getIdToken().then((data) => { 
                                const postRequestInfo = {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': "Bearer " + data
                                    },
                                    body: JSON.stringify(
                                        {
                                            firstName: this.signupFirstNameRef.current.value, 
                                            lastName: this.signupLastNameRef.current.value,
                                            username: this.signupUsernameRef.current.value
                                        }
                                    )
                                };
                                fetch(`${API_HOST}/api/users/v1/setup?sendEmailVerification=false`, postRequestInfo)
                                .then((data) => {
                                    this.setState({loading: false, signupStage: "odds-preferences"})
                                })
                                .catch(error => {
                                    console.log("Error on user setup: ", error);
                                    // Re-using the confirm password since it's at the bottom
                                    this.setState({loading: false, signupErrorMessages: {confirmPassword: ['Something went wrong setting up your account. You may be able to complete the process via the profile page.']}})
                                });
                            })
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                    
                            switch (errorCode) {
                                case 'auth/email-already-in-use':
                                    this.setState({loading: false, signupErrorMessages: {email: ['Email is already in use.']}})
                                    break;
                                default:
                                    console.log(`Signup validation error: {errorCode: ${errorCode}, errorMessage: ${errorMessage}}`);
                                    // Re-using the confirm password since it's at the bottom
                                    this.setState({loading: false, signupErrorMessages: {confirmPassword: ['Something went wrong, please try again later.']}})
                                    break;
                            }
                        });
                    } else {
                        this.setState({loading: false, signupErrorMessages: {username: ['Username is already taken.']}})
                    }
                })
                .catch(error => {
                    console.log(`Error : ${error}`)
                    // Re-using the confirm password since it's at the bottom
                    this.setState({loading: false, signupErrorMessages: {confirmPassword: ['Something went wrong, please try again later.']}})
                });
            })
        }
    }

    // This can probably be extracted from building the request info to the fetch. 
    // The attributes to set passed in to the function and it would return the result of fetch to be handled by the caller
    // TODO: there's already a function for it called updateUserAttributes in util.js
    setOddsPreference() {
        this.setState({loading: true}, () => {
            this.context.user.getIdToken().then((data) => { 
                const postRequestInfo = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + data
                    },
                    body: JSON.stringify({
                        userAttributes: {
                            preferredOddsFormat: this.state.oddsFormatSelection,
                            // Temporarily set both until we remove the old one completely
                            // TODO: this should be removed eventually
                            preferredLineFormat: this.state.oddsFormatSelection
                        }
                    })
                };
                fetch(`${API_HOST}/api/users/v1/update`, postRequestInfo)
                .catch(error => {
                    // There's not much we can do here if there's a problem since it's a temporary/transitionary screen and a default value gets set on creation anyway
                    console.log("Error updating preferences: ", error);
                })
                .finally(() => {
                    this.redirectUser(this.context.user)
                })
            })
        })
    }

    buildError(errorMessages) {
        if (!errorMessages || errorMessages.length === 0) {
            return (<></>)
        }
        return (
            <>
            {
                errorMessages.map((message) => 
                    <p key={message} className='text-style-caption-normal signup-page-error'>{message}</p>
                )
            }
            </>
        )
    }

    render() {
        return (
            <>
                <HtmlHeaders canonicalRef="https://www.linemate.io/signup"/>
                {
                    <div className='signup-page-container'>
                        <a href='/'>
                            <img src='assets/linemate-logo.svg' alt="linemate-logo"/>
                        </a>
                        {
                            this.state.signupStage === "credentials" && (
                                <>
                                    <p className='text-style-h-1-bold signup-page-title'>Sign up for Linemate</p>
                                    <div className='signup-page-no-account-signup'>
                                        <p className='text-style-body-normal'>Already have an account?</p>
                                        <a className='text-style-body-normal' href={`/login?origin=${this.origin}`}>Log in</a>
                                    </div>
                                    <div className='signup-page-form'>
                                        <div className='signup-page-form-names'>
                                            <div>
                                                <p className='text-style-label-medium'>First name</p>
                                                <input ref={this.signupFirstNameRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} type="text"/>
                                            </div>
                                            <div>
                                                <p className='text-style-label-medium'>Last name</p>
                                                <input ref={this.signupLastNameRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} type="text"/>
                                            </div>
                                        </div>
                                        <p className='text-style-label-medium signup-page-input-title'>Username</p>
                                        <input ref={this.signupUsernameRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} onBlur={() => this.setState(this.validateUsername({signupErrorMessages: this.state.signupErrorMessages}))} type="text"/>
                                        {this.buildError(this.state.signupErrorMessages.username)}
                                        <p className='text-style-label-medium signup-page-email signup-page-input-title'>Email</p>
                                        <input ref={this.signupEmailRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} onBlur={() => this.setState(this.validateEmail({signupErrorMessages: this.state.signupErrorMessages}))} type="email"/>
                                        {this.buildError(this.state.signupErrorMessages.email)}
                                        <p className='text-style-label-medium signup-page-password signup-page-input-title'>Password</p>
                                        <input ref={this.signupPasswordRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} onBlur={() => this.setState(this.validatePassword({signupErrorMessages: this.state.signupErrorMessages}))} type="password"/>
                                        {this.buildError(this.state.signupErrorMessages.password)}
                                        <p className='text-style-label-medium signup-page-password signup-page-input-title'>Confirm password</p>
                                        <input ref={this.signupConfirmPasswordRef} className='text-style-body-normal' onKeyUp={this.inputOnKeyUp} onBlur={() => this.setState(this.validateConfirmPassword({signupErrorMessages: this.state.signupErrorMessages}))} type="password"/>
                                        {this.buildError(this.state.signupErrorMessages.confirmPassword)}
                                        <div className='signup-page-terms-and-privacy'>
                                            <p className='text-style-caption-normal'>By continuing, you agree to Linemate's&nbsp;</p>
                                            <a className='text-style-caption-normal' href='/terms'>Terms of Service</a>
                                            <p className='text-style-caption-normal'>&nbsp;and&nbsp;</p>
                                            <a className='text-style-caption-normal' href='/privacy'>Privacy Policy.</a>
                                        </div>
                                        <div className='signup-page-login-button-wrapper'>
                                            <Button text="Continue" typography="lg" type="primary" enabled={!this.state.loading && this.state.formFilled && !this.state.signupInProgress && (Object.keys(this.state.signupErrorMessages).length === 0 || Object.keys(this.state.signupErrorMessages).map(x => this.state.signupErrorMessages[x]).every(x => x.length === 0))} onClick={() => this.inputOnKeyUp({preventDefault: () => {}, keyCode: 13})}/>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            this.state.signupStage === "odds-preferences" && (
                                <div className='signup-page-odds-preferences-container'>
                                    <div className='signup-page-odds-preferences-header'>
                                        <p className='text-style-h-1-bold signup-page-title'>Odds format preferences</p>
                                        <p className='text-style-body-normal'>Before we continue to Linemate, please set your preference for displaying odds in either American or decimal format.</p>
                                    </div>
                                    <div className='signup-page-odds-selection'>
                                        <p className='text-style-label-medium'>Odds format</p>
                                        <RadioGroup options={this.oddsOptions} onValueChange={(value) => this.setState({oddsFormatSelection: value})}/>
                                    </div>
                                    <div className='signup-page-login-button-wrapper'>
                                        <Button text="Continue" typography="lg" type="primary" enabled={!this.state.loading && this.state.oddsFormatSelection} onClick={this.setOddsPreference}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                }
            </>
        );
    }
}

export default Signup;