import React from 'react';
import {stringPresent, isFieldEmpty, containsNumber, containsUppercase, containsLowercase, base64Encode} from './linemate-react-common/src/util.js';
import {DEFAULT_ORIGIN_REDIRECT} from './linemate-react-common/src/constants.js';
import {auth} from './firebase.js';

import './fonts.css';
import './generic.css';
import './authentication-page.css';
import HtmlHeaders from './html-headers.js';

class PasswordResetAction extends React.Component {
  constructor(props) {
    super(props);

    this.passwordRef = React.createRef();
    this.confirmPasswordRef = React.createRef();
    this.submitButtonEnabled = React.createRef();
    this.submitButtonDisabled = React.createRef();
    this.passwordValidationMessageRef = React.createRef();
    this.confirmPasswordValidationMessageRef = React.createRef();
    this.changePasswordMessageRef = React.createRef();
    this.sucessMessageRef = React.createRef();
    this.redirectButtonRef = React.createRef();
    this.invalidLinkMessageRef = React.createRef();

    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.submitPasswordChange = this.submitPasswordChange.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
    this.handleResetError = this.handleResetError.bind(this);

    this.state = {
        error: null
    }
  }

  validatePassword() {
    const value = this.passwordRef.current.value;
    if (isFieldEmpty(value) || value.length < 8 || !containsNumber(value) || !containsUppercase(value) || !containsLowercase(value)) {
        this.passwordValidationMessageRef.current.style.display = 'block';
        return false;
    } else {
        this.passwordValidationMessageRef.current.style.display = 'none';
        return true;
    }
  }

  validateConfirmPassword() {
    const password = this.passwordRef.current.value;
    const confirmPassword = this.confirmPasswordRef.current.value;
    if (password === confirmPassword) {
        this.confirmPasswordValidationMessageRef.current.style.display = 'none';
        return true;
    } else {
        this.confirmPasswordValidationMessageRef.current.style.display = 'block';
        return false;
    }
  }

    /**
     *      * <h4>Error Codes</h4>
            * <dl>
            * <dt>auth/expired-action-code</dt>
            * <dd>Thrown if the password reset code has expired.</dd>
            * <dt>auth/invalid-action-code</dt>
            * <dd>Thrown if the password reset code is invalid. This can happen if the
            *     code is malformed or has already been used.</dd>
            * <dt>auth/user-disabled</dt>
            * <dd>Thrown if the user corresponding to the given password reset code has
            *     been disabled.</dd>
            * <dt>auth/user-not-found</dt>
            * <dd>Thrown if there is no user corresponding to the password reset code. This
            *     may have happened if the user was deleted between when the code was
            *     issued and when this method was called.</dd>
            * <dt>auth/weak-password</dt>
            * <dd>Thrown if the new password is not strong enough.</dd>
            * </dl>
    */
   // Error codes between action check and reset are the same except reset also has weak password which we handle here
  handleResetError(err) {
    console.log(`Error processing reset: ${err}`)
    var errorCode = err.code;
    var errorMessage = err.message;
    switch (errorCode) {
        case 'auth/expired-action-code':
        case 'auth/invalid-action-code':
            this.invalidLinkMessageRef.current.style.display = 'block';
            break;
        case 'auth/weak-password':
            this.setState({error: `Your password needs to be at least 8 characters. It should include numbers, lowercase and uppercase letters.`})
            break;
        default:
            this.setState({error: `We were unable to complete your request, please try again later or send the following code to our team and we'll look into it ${base64Encode(JSON.stringify(err))}`})
            break;
    }
}

  submitPasswordChange(e) {
    e.preventDefault();
    this.setState({error: null}, () => {
        this.invalidLinkMessageRef.current.style.display = 'none';
        const passwordValid = this.validatePassword();
        const confirmationPasswordValid = this.validateConfirmPassword();
        if (passwordValid && confirmationPasswordValid) {
            auth.checkActionCode(this.props.code)
            .then((info) => {
                const email = info.data.email;
                auth.confirmPasswordReset(this.props.code, this.passwordRef.current.value)
                .then(() => {
                    this.redirectButtonRef.current.style.display = 'block';
                    this.sucessMessageRef.current.style.display = 'flex';
    
                    this.changePasswordMessageRef.current.style.display = 'none';
                    this.changePasswordMessageRef.current.style.display = 'none';
                    this.submitButtonEnabled.current.style.display = 'none';
                    this.submitButtonDisabled.current.style.display = 'none';
                    this.passwordRef.current.style.display = 'none';
                    this.confirmPasswordRef.current.style.display = 'none';
    
                    auth.signInWithEmailAndPassword(email, this.passwordRef.current.value);
                })
                .catch(this.handleResetError);
            })
            .catch(this.handleResetError);
        }
    })
  }

  handleInputKeyUp(event) {
    event.preventDefault();
    const password = this.passwordRef.current.value;
    const confirmPassword = this.confirmPasswordRef.current.value;
    if (stringPresent(password) && stringPresent(confirmPassword)) {
        this.submitButtonEnabled.current.style.display = 'block';
        this.submitButtonDisabled.current.style.display = 'none';
        if (event.keyCode === 13) {
            this.submitButtonEnabled.current.click();
        }
    } else {
        this.submitButtonEnabled.current.style.display = 'none';
        this.submitButtonDisabled.current.style.display = 'block';
    }
  }

  redirectUser() {
    window.location.href = DEFAULT_ORIGIN_REDIRECT;
  }

  render() {
    return(
        <>
            <HtmlHeaders canonicalRef="https://www.linemate.io/authentication/action"/>
            <img class="authentication-hero-image absolute" src="assets/authentication/hero-big.jpg" alt=""/>
            <div class="authentication-hero">
                <a href="https://linemate.io">
                    <img src="assets/logo-white-nobg.svg" alt=""/>
                </a>
                <div class="authentication-full-header">
                    <p class="font white size-64 weight-800 spaced-negative-low" style={{marginTop: "20%"}} >Start your research with Linemate.</p>
                </div>
                <div class="authentication-mobile-header">
                    <p class="font white size-30 weight-700 spaced-negative-low" style={{marginTop: "20%"}} >Start your research with Linemate.</p>
                </div>
            </div>
            <div class="authentication-content">
                <p ref={this.changePasswordMessageRef} class="font size-36 weight-700 dark full-width" style={{marginBottom: '24px', display: 'flex'}}>Change your password</p>
                <p ref={this.sucessMessageRef} class="font size-36 weight-700 dark full-width" style={{display: 'none', marginBottom: '24px'}}>You've successfully changed your password</p>
                <div class="authentication-content-login" >
                    <input onKeyUp={this.handleInputKeyUp} ref={this.passwordRef} type="password" class="generic-input" placeholder="New password"/>
                    <p ref={this.passwordValidationMessageRef} class="font size-14 red" style={{display: 'none'}}>Your password needs to be at least 8 characters. It should include numbers, lowercase and uppercase letters.</p>
                    <input onKeyUp={this.handleInputKeyUp} ref={this.confirmPasswordRef} type="password" class="generic-input" placeholder="Confirm new password"/>
                    <p ref={this.confirmPasswordValidationMessageRef} class="font size-14 red" style={{display: 'none'}}>Confirm password does not match.</p>
                    <p ref={this.invalidLinkMessageRef} class="font size-14 red" style={{display: 'none'}}>We were unable to reset your password, the link you are using is either invalid or expired.</p>
                    {
                        this.state.error && (
                            <p ref={this.fallbackErrorMessageRef} class="font size-14 red" style={{wordWrap: 'break-word'}}>{this.state.error}</p>
                        )
                    }
                    <button ref={this.submitButtonEnabled} type="button" class="generic-button" onClick={this.submitPasswordChange} style={{display: 'none', marginTop: '32px'}}>
                        <p class="font white size-14">Continue</p>
                    </button>
                    <button ref={this.submitButtonDisabled} type="button" disabled class="generic-button generic-button-disabled" style={{marginTop: '32px'}}>
                        <p class="font white size-14">Continue</p>
                    </button>
                    <button ref={this.redirectButtonRef} type="button" class="generic-button" onClick={this.redirectUser} style={{display: 'none', marginTop: '32px'}}>
                        <p class="font white size-14">Continue to Gameday</p>
                    </button>
                </div>
            </div>
        </>
    );
  }
}

export default PasswordResetAction;
