import React from 'react';

import { base64Encode, getTeamRecord, isPlaybookSupported, isWeeklyCompetition, parseDateFromString } from '../linemate-react-common/src/util';
import { getGameMarketInfo, getTeamMarketInfo } from '../linemate-react-common/src/gameday-utils';

import Button from './Button';
import GlobalContext from './GlobalContext';

import './MatchupCard.scss'
import './App.scss'
import { getTeamLogoPath, teamLogoPathOnErrorFallback } from '../react-web-utils';
import { GAMES_FILTER } from '../linemate-react-common/src/constants';

/**
 * Props
 * - game:{}                        The game object
 * - teams:{home: {}, away: {}}     An object containing the team record of both teams
 */
class MatchupCard extends React.Component {

    static contextType = GlobalContext;

    // TODO: soccer titles for result/draw/etc
    render() {
        const game = this.props.game;
        const teams = this.props.teams;
        const date = parseDateFromString(game.timestamp)

        const awayTeamMarketInfo = getTeamMarketInfo(game.awayTeamData, this.context.userAttributes || {})
        const homeTeamMarketInfo = getTeamMarketInfo(game.homeTeamData, this.context.userAttributes || {})

        const gameMarketInfo = getGameMarketInfo(game, this.context.userAttributes || {}, true);
        const feedGameTitle = `${game.awayTeamData.info.code} @ ${game.homeTeamData.info.code}`
        const feed = base64Encode(JSON.stringify({title: feedGameTitle, annotation: `${date.formattedDayMonthShort} ${date.formattedTime}`, endpoint: `/discovery/games/${game.id}/cards`, parlayEndpoint: `/discovery/games/${game.id}/parlays`, filtersToExclude: [GAMES_FILTER]}))

        var gameTitle = date.formattedTime;
        if (isWeeklyCompetition(this.context.league)) {
            gameTitle = `${date.formattedDayMonthShort} ${date.formattedTime}`
        }
        return (
            <div className='matchup-card-container'>
                <div className='matchup-card-header'>
                    <div className='matchup-card-left-column'>
                        <p className='text-style-caption-uppercase-medium'>{gameTitle}</p>
                    </div>
                    <div className='matchup-card-right-column'>
                        <div>
                            <p className='text-style-caption-medium'>Money</p>
                        </div>
                        <div>
                            <p className='text-style-caption-medium'>Spread</p>
                        </div>
                        <div>
                            <p className='text-style-caption-medium'>Total</p>
                        </div>
                    </div>
                </div>
                <div className='matchup-card-content'>
                    <div className='matchup-card-content-row'>
                        <div className='matchup-card-left-column'>
                            <img src={getTeamLogoPath(this.context.league, game.awayTeamData.info.code)} onError={({currentTarget}) => teamLogoPathOnErrorFallback(currentTarget, this.context.league)} alt={game.awayTeamData.info.code}/>
                            <div className='matchup-card-team-info-stack'>
                                <p className='text-style-body-uppercase-semibold'>{game.awayTeamData.info.code}</p>
                                {
                                    teams.away && (
                                        <p className='text-style-caption-tabular-medium'>{getTeamRecord(teams.away, this.context.league)}</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className='matchup-card-right-column'>
                            <div className='matchup-card-money-column'>
                                <p className='text-style-caption-tabular-medium'>{awayTeamMarketInfo.moneyline}</p>
                            </div>
                            <div className='matchup-card-spread-column'>
                                <p className='text-style-caption-tabular-medium'>{awayTeamMarketInfo.spread.line}</p>
                                <p className='text-style-caption-tabular-medium'>{awayTeamMarketInfo.spread.odds}</p>
                            </div>
                            <div className='matchup-card-total-column'>
                                <p className='text-style-caption-tabular-medium'>{gameMarketInfo.awayTeamData.line}</p>
                                <p className='text-style-caption-tabular-medium'>{gameMarketInfo.awayTeamData.odds}</p>
                            </div>
                        </div>
                    </div>
                    <div className='matchup-card-content-row'>
                        <div className='matchup-card-left-column'>
                            <img src={getTeamLogoPath(this.context.league, game.homeTeamData.info.code)} onError={({currentTarget}) => teamLogoPathOnErrorFallback(currentTarget, this.context.league)} alt={game.homeTeamData.info.code}/>
                            <div className='matchup-card-team-info-stack'>
                                <p className='text-style-body-uppercase-semibold'>{game.homeTeamData.info.code}</p>
                                {
                                    teams.home && (
                                        <p className='text-style-caption-tabular-medium'>{getTeamRecord(teams.home, this.context.league)}</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className='matchup-card-right-column'>
                            <div className='matchup-card-money-column'>
                                <p className='text-style-caption-tabular-medium'>{homeTeamMarketInfo.moneyline}</p>
                            </div>
                            <div className='matchup-card-spread-column'>
                                <p className='text-style-caption-tabular-medium'>{homeTeamMarketInfo.spread.line}</p>
                                <p className='text-style-caption-tabular-medium'>{homeTeamMarketInfo.spread.odds}</p>
                            </div>
                            <div className='matchup-card-total-column'>
                                <p className='text-style-caption-tabular-medium'>{gameMarketInfo.homeTeamData.line}</p>
                                <p className='text-style-caption-tabular-medium'>{gameMarketInfo.homeTeamData.odds}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='matchup-card-actions'>
                    <div className='matchup-card-actions-button' style={!isPlaybookSupported(this.context.league) ? {width: '100%'} : {}}>
                        <Button text="Go to Trends" typography="sm" type="secondary" enabled={true} onClick={() => window.location.href = `${this.context.league}/trends?feed=${feed}`}/>
                    </div>
                    {
                        isPlaybookSupported(this.context.league) && (
                            <div className='matchup-card-actions-button'>
                                <Button text="Go to Summary" typography="sm" type="secondary" enabled={true} onClick={() => window.location.href = `${this.context.league}/summary/${game.id}`}/>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default MatchupCard;