import React from 'react';

import './Footer.scss'
import './App.scss'

class Footer extends React.Component {

  render() {
    return (
      <div className='footer-container'>
        {/* Info and socials */}
        <div className='footer-information'>
            <div>
              <a className="footer-wordmark" href="/">
                  <img src="assets/logomark.svg" alt="Linemate"/>
              </a>
              <p className='footer-copyright text-style-caption-normal'>©2024, All rights reserved.</p>
            </div>
            <div className='footer-socials'>
              <a href="https://www.twitter.com/yourlinemate" target="_blank" rel="noopener noreferrer">
                <img src='assets/twitter.svg' alt="X"/>
              </a>
              <a href="https://www.tiktok.com/@linemate" target="_blank" rel="noopener noreferrer">
                <img src='assets/tiktok.svg' alt="TikTok"/>
              </a>
              <a href="https://www.instagram.com/yourlinemate" target="_blank" rel="noopener noreferrer">
                <img src='assets/instagram.svg' alt="Instagram"/>
              </a>
              <a href="https://www.youtube.com/channel/UCcLsCIwyO2CAzbOynsabLGQ" target="_blank" rel="noopener noreferrer">
                <img src='assets/youtube.svg' alt="Youtube"/>
              </a>
            </div>
        </div>
        {/* Product */}
        <div className='footer-product'>
          <p className='text-style-label-medium'>Product</p>
          <div className='footer-column-row-group'>
            <a className='text-style-label-normal' href='/'>About</a>
            <a className='text-style-label-normal' href='/download'>Download</a>
            <a className='text-style-label-normal' href='/pricing'>Pricing</a>
          </div>
        </div>
        {/* Company */}
        <div className='footer-company'>
          <p className='text-style-label-medium'>Company</p>
          <div className='footer-column-row-group'>
            <a className='text-style-label-normal' href='/privacy'>Privacy</a>
            <a className='text-style-label-normal' href='/terms'>Terms</a>
          </div>
        </div>
        {/* Support */}
        <div className='footer-support'>
          <p className='text-style-label-medium'>Support</p>
          <div className='footer-column-row-group'>
            <a className='text-style-label-normal' href="mailto:support@linemate.io">Contact us <img src="assets/arrow-outward.svg" alt=""/></a>
            <a className='text-style-label-normal' href='/responsible-gambling'>Responsible gambling</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;